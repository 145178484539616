import {
  Container,
  Grid,
  Typography,
  CardContent,
  CardMedia,
  Box,
  MenuItem,
  Select,
  FormControl,
  TextField,
  InputAdornment,
  CardHeader,
  Button,
} from "@mui/material";
import * as React from "react";
import { styled } from "@mui/material/styles";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import { red } from "@mui/material/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import search from "../../../../../../assets/icons/Search-icon.png";
import Card from "@mui/material/Card";
import LawyerImage from "../../../../../../assets/lawyerpic.png";

const LawyerCard = () => {
  const data = [
    {
      title: "1.Lawyers",
      image: LawyerImage,
      content: (
        <div>
          <ul style={{ paddingTop: "8px", listStyleType: "none" }}>
            <li style={{ paddingTop: "8px" }}>
              <span
                style={{
                  color: "#5C5F5E",
                  fontFamily: "Gilroy-Bold",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 400,
                }}
              >
                Legal Expertise:
              </span>{" "}
              A Tap Away With Travel Help Squad's Lawyer Feature
            </li>
            <li style={{ paddingTop: "8px" }}>
              <span
                style={{
                  color: "#5C5F5E",
                  fontFamily: "Gilroy-Bold",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 400,
                }}
              >
                Instant Consultations:{" "}
              </span>{" "}
              Connect with experienced lawyers for quick legal advice and
              guidance.​
            </li>
            <li style={{ paddingTop: "8px" }}>
              <span
                style={{
                  color: "#5C5F5E",
                  fontFamily: "Gilroy-Bold",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 400,
                }}
              >
                ​Document Assistance:{" "}
              </span>
              Get help with legal documentation and contracts, ensuring accuracy
              and compliance.​
            </li>
            <li style={{ paddingTop: "8px" }}>
              <span
                style={{
                  color: "#5C5F5E",
                  fontFamily: "Gilroy-Bold",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 400,
                }}
              >
                Legal Support:
              </span>{" "}
              Travel Help Squad is your trusted ally in navigating the
              complexities of the legal world.​
            </li>
            <li style={{ paddingTop: "8px" }}>
              <span
                style={{
                  color: "#5C5F5E",
                  fontFamily: "Gilroy-Bold",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 400,
                }}
              >
                Confidential & Convenient:
              </span>{" "}
              Protect your rights and interests with the privacy and
              accessibility you deserve.
            </li>
            ​Empower Yourself Legal assistance at your fingertips. Download now
            and have peace of mind with every legal matter.​
          </ul>{" "}
        </div>
      ),
    },
  ];

  return (
    <>
    <Container maxWidth="xl">
      <Grid
        container
        sx={{
          p: { sm: "15px", xs: "0px" },
          borderRadius: "20px",
          background: "#EDF3F1",
          boxShadow: "0px 34px 74px -12px rgba(0, 0, 0, 0.16)",
          position: "relative",
          zIndex: 10,
          mt: -10,
        }}
      >
        <Grid item xs={12} lg={12}>
          {data.map((item, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "left",
                p: { sm: 0, xs: 0 },
                width: "100%",
              }}
            >
              <img src={item.image} alt={`Image ${index}`} width="100%" />
              <Typography
                sx={{
                  fontFamily: "Gilroy-Medium",
                  fontSize: "16px",
                  fontWeight: 400,
                  lineHeight: "30px",
                  // textAlign: "left",
                  color: "#5C5F5E",
                  marginTop: "10px",
                }}
              >
                {item.content}
              </Typography>
            </Box>
          ))}
        </Grid>
      </Grid>
    </Container>
  </>
  );
};

export default LawyerCard;
