import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Grid, Typography } from "@mui/material";
import { useState } from "react";
import { Link } from "react-router-dom";
import PersonalAccount from "./sections/personal-account/personal-account";
import CorporateAccount from "./sections/corporate-account/corporate-account";
import CommercialAccount from "./sections/commercial-account/commercial-account";
import Lawyer from "./sections/lawyer/lawyer";
import authImg from "../../../assets/authIcon.svg";

const tabs = [
  {
    label: "Personal Account",
    component: PersonalAccount,
    path: "personal-account",
  },
  {
    label: "Corporate Account",
    component: CorporateAccount,
    path: "corporate-account",
  },
  {
    label: "Commercial Account",
    component: CommercialAccount,
    path: "commercial-account",
  },
  {
    label: "Lawyer",
    component: Lawyer,
    path: "lawyer",
  },
  // {
  //   label: "Third Party Service",
  //   component: ThirdPartyService,
  //   path: "third-party-service",
  // },
];

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function CreateProfileTabs() {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", minHeight: "100vh", background: "#EDF3F1" }}>
      <Grid container spacing={2}>
        <Grid item xs={10.5} xl={4.5} sx={{ width: "80%", margin: "0 auto" }}>
        <Grid item xl={12} xs={12} mt={5}>
                  <Box
                    display="flex"
                    justifyContent="center"
                    flexDirection="column"
                    flexWrap="wrap"
                  >
                    <img src={authImg} aly="img" />
                    <Typography textAlign="center" sx={styles.logoText}>
                      Where Innovation Meets Mobility
                    </Typography>
                  </Box>
                </Grid>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              margin: "79px 0px 27px 0px",
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              centered
              variant="scrollable"
              scrollButtons="auto"
              aria-label="Tabs scroll"
              allowScrollButtonsMobile
              sx={{
                background: "#fff",
                color: "#000",
                borderRadius: "95px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                "&.MuiTabs-root .Mui-selected": {
                  background: "#21b07d !important",
                  minWidth: "95px",
                  color: "#fff",
                  fontSize: "14px",
                  fontWeight: "600px !important",
                  borderRadius: "160px",
                  textDecoration: "none !important",
                  textTransform: "capitalize !important",
                },
                "&.MuiTabs-root": {
                  minWidth: "270px",
                  maxWidth: "1000px",
                  width:"95%",
                  boxShadow: "0px 0px 12px 0px rgba(65, 204, 184, 0.18)",
                  textTransform: "capitalize !important",
                },
                "& .MuiTabs-indicator": {
                  backgroundColor: "transparent !important",
                },
              }}
            >
              {tabs.map((tab, index) => (
                <Tab
                  label={tab.label}
                  key={index}
                  sx={{ fontSize: "14px", color: "#000" , textTransform: "capitalize!important",
                  fontWeight: "400px !important",
                  borderRadius: "160px",
                  textDecoration: "none !important",}}
               />
              ))}
            </Tabs>
          </Box>

          <Box
            sx={{
              borderRadius: "16px",
              // boxShadow: "0px 34px 74px -12px rgba(0, 0, 0, 0.16)",
              boxShadow: "0px 0px 12px 0px rgba(65, 204, 184, 0.18)",
              marginBottom: "40px",
            }}
          >
            {tabs.map((tab, index) => (
              <Box key={index}>{value === index && <tab.component />}</Box>
            ))}
            <Grid container spacing={2} justifyContent={"center"} display={"flex"}>
              <Grid item xl={10} xs={11} display={"flex"} justifyContent={"center"}>
                <Typography textAlign="center" xs={styles.bottomText}>
                  By submitting your information, you agree to Travel Help
                  Squad’s
                  <span
                    style={{
                      color: "#21B07D",
                      ontSize: "16px",
                      fontWeight: "700",
                      fontFamily: "Open Sans",
                    }}
                  >
                    {" "}
                    Terms of Service
                  </span>{" "}
                  and
                  <span
                    style={{
                      color: "#21B07D",
                      fontSize: "16px",
                      fontWeight: "700",
                      fontFamily: "Open Sans",
                    }}
                  >
                    {" "}
                    Privacy Policy.{" "}
                  </span>
                  You can opt out at anytime.
                </Typography>
              </Grid>
              <Grid item xl={10} xs={11} display={"flex"} justifyContent={"center"}>
                <Typography textAlign="center">
                  Already have an account?
                  <Link style={{ color: "#21B07D" }} to="/sign-in">Sign In</Link>
                </Typography>
              </Grid>
              <Grid item xl={12} xs={11} display={"flex"} justifyContent={"center"}>
                <Typography mt={5} mb={3} textAlign={"center"} sx={styles.copyRightText}>
                  Copyright © 2024 Travel Help Squad, Inc. - All Rights Reserved
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

const styles = {
  bottomText: {
    fontSize: "16px",
    fontWeight: "700",
    color: "#344054",
    fontFamily: "Open Sans",
  },
  alreadyAccountText: {
    fontSize: "16px",
    fontWeight: "600",
    color: "#1D2939",
    fontFamily: "Open Sans",
  },
  copyRightText: {
    fontSize: "16px",
    fontWeight: "400",
    color: "#344054",
    fontFamily: "Open Sans",
  },
};
