import { Container, Grid, Typography, Select, FormControl, TextField, InputAdornment, Box, MenuItem  } from '@mui/material';

import * as React from 'react';
import search from "../../../../../assets/icons/Search-icon.png"
import searchbgmap from "../../../../../assets/offers/searchbgmap.png"



const MotCard = () => {
    const [lawyer, setlawyer] = React.useState("");
    const [openlawyer, setOpenlawyer] = React.useState(false);

    
      const handleChangelawyer = (event) => {
        setlawyer(event.target.value);
      };
    
      const handleCloselawyer = () => {
        setOpenlawyer(false);
      };
    
      const handleOpenlawyer = () => {
        setOpenlawyer(true);
      };
    

      const iframeStyle = {
        width: '100%', 
        maxWidth: {lg:'628px',xs:"100%"},
        height: '582px',
        flexShrink: 0,
        border: '0',
        borderRadius:'20px',
        // border:"1px solid black"
        
      };
      const customStyles = {
        title: {
          fontSize: '20px', 
          color: '#3B4A45',  
          fontWeight:400,
          fontFamily:'Gilroy-SemiBold',
        },
        action: {
            fontSize: '20px', 
            color: '#5C5F5E',  
            fontWeight:700,
            fontFamily:'Gilroy-SemiBold',
           
        },
      };
      

  return (
    <>
    <Container maxWidth="xl">
      <Grid container sx={{ p: {sm:"60px",xs:"5px" },borderRadius: '20px',
  background: '#EDF3F1',
  boxShadow: '0px 34px 74px -12px rgba(0, 0, 0, 0.16)'
  ,position:'relative',
  zIndex:10,
  mt:-10
  }}>
        <Grid item xs={12} sx={{mb:2}}>
            <Box sx={{display:'flex',justifyContent:'space-between',p:{sm:0,xs:2}}}>
     
          <Typography sx={{ color: '#21B070', fontFamily: 'Gilroy-SemiBold', fontSize: "24px", fontWeight: 400,mb:"36px" ,}}>
          Find MOT Centers
          </Typography>  
          <FormControl
            sx={{
              maxWidth: "176px",
            width: "100%",
            height: "60px",
            flexShrink: 0,
            borderRadius: "10px",
            background: "#FFF",
            boxShadow: "0px 4px 12px 0px rgba(0, 0, 0, 0.15)",
            "& fieldset": { border: "none" },
            display: {lg:"flex",xs:"none"},
            justifyContent: "space-between",
            }}
          >
            <Select
              labelId="demo-controlled-open-select-label-lawyer"
              id="demo-controlled-open-select-lawyer"
              open={openlawyer}
              onClose={handleCloselawyer}
              onOpen={handleOpenlawyer}
              value={lawyer}
              onChange={handleChangelawyer}
              displayEmpty
              renderValue={(selected) => (
                <div
                  style={{
                    textAlign: "left",
                    color: "#273B4A",
                    fontFamily: "Montserrat",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "140%",
                  }}
                >
                  {selected || "Mot Centers"}
                </div>
              )}
            >
              <MenuItem value="" disabled>
                <span
                  style={{
                    color: "#273B4A",
                    fontFamily: "Montserrat",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "140%",
                  }}
                >
              Mot Centers
                </span>
              </MenuItem>
                <MenuItem value={2018}>
                  <span
                    style={{
                      color: "#273B4A",
                      fontFamily: "Montserrat",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "140%",
                    }}
                  >
                    2018
                  </span>
                </MenuItem>
                <MenuItem value={2019}>
                  <span
                    style={{
                      color: "#273B4A",
                      fontFamily: "Montserrat",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "140%",
                    }}
                  >
                    2019
                  </span>
                </MenuItem>
                <MenuItem value={2020}>
                  <span
                    style={{
                      color: "#273B4A",
                      fontFamily: "Montserrat",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "140%",
                    }}
                  >
                    2020
                  </span>
                </MenuItem>
              </Select>
            </FormControl>

     </Box>
     
    
        </Grid>
        <Grid container spacing={4} >
        <Grid item lg={6} xs={12}>
        <TextField
            fullWidth
            sx={{
              border: "none",
              "& fieldset": { border: "none" },
            
              width: "100%",
            //   maxWidth: "716px",
              height: "60px",
              flexShrink: 0,
              borderRadius: "10px",
              background: "#FFF",
              boxShadow: "0px 4px 12px 0px rgba(0, 0, 0, 0.15)",
            }}
            placeholder="Search by location or zip code"
            variant="outlined"
            id="field1"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img src={search} alt="Search Icon" />
                </InputAdornment>
              ),
            }}
          />
          <Box sx={{display:'flex',flexDirection:"column",ustifyContent:'center',alignContent:'center',alignItems:'center',mt:"81px"}}>
            <img src={searchbgmap} alt ="searchbgmap">
            </img>
            <Typography sx={{color:'#9FAEBF',fontFamily:"Gilroy-Medium",fontSize:'18px',fontWeight:400,mt:"16px"}}>
            No MOT Centers
            </Typography>
          </Box>
         
        </Grid>
       <Grid item lg={6} xs={12}>
        <Box sx={{justifyContent:'center',alignItems:'center',}}>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2374.3450703933304!2d-2.232368422977093!3d53.48016396470215!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487bb1bdae2e2e2b%3A0xad6a1d1593b09a5d!2sDakota%20Manchester!5e0!3m2!1sen!2s!4v1694615309081!5m2!1sen!2s"
        style={iframeStyle}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe></Box>
    </Grid>
    </Grid>
      </Grid>
    </Container></>
  )
}

export default MotCard;
