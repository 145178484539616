export const termsAndConditionMockData = [
  {
    id: "1",
    title: "Who We Are ",
    content:
      "www.travelhelpsquad.co.uk  is operated by Pay IO Services LTD (We). We are registered in England and Wales under company number 15439659 with our registered office at International House, 36-38 Cornhill, London, England, EC3V 3NG.",
  },
  {
    id: "2",
    title: "Acceptance of Terms   ",
    content:
      "By accessing this website, you accept these terms and conditions. Do not continue to use Travel Help Squad if you do not agree to all the terms and conditions stated on this page.",
  },
  {
    id: "3",
    title: "Licence",
    content:
      "Unless otherwise stated, Travel Help Squad and/or its licensors own the intellectual property rights for all material on Travel Help Squad. All intellectual property rights are reserved. You may access this from Travel Help Squad for your own personal use, subject to restrictions set in these terms and conditions.",
  },
  {
    id: "4",
    title: "User Conduct",
    list: [
      "Republish material from Travel Help Squad.",
      "Sell, rent, or sub-license material from Travel Help Squad.",
      " Reproduce, duplicate, or copy material from Travel Help Squad.",
      "Redistribute content from Travel Help Squad.",
    ],
  },
  {
    id: "5",
    title: "Disclaimer",
    content: `The information on this website is provided "as is" with no warranties, express or implied, including but not limited to implied warranties of merchantability, fitness for a particular purpose, or non-infringement. We do not warrant that the information will be uninterrupted or error-free.`,
  },
  {
    id: "6",
    title: "Limitation of Liability",
    content:
      "In no event shall Travel Help Squad, nor any of its officers, directors, and employees, be liable to you for anything arising out of or in any way connected with your use of this website.",
  },
  {
    id: "7",
    title: "Governing Law",
    content:
      "These terms and conditions are governed by and construed in accordance with the laws of England and Wales, and you submit to the non-exclusive jurisdiction of the state and federal courts of England and Wales for the resolution of any disputes.",
  },
  {
    id: "8",
    title: "Changes to Terms",
    content:
      "We reserve the right to revise these terms and conditions at any time as we see fit, and by using this website, you are expected to review these terms on a regular basis.",
  },
  {
    id: "9",
    title: "Contact Us",
    content:
      "If you have any questions about these Terms and Conditions, please contact us:",
    // list: [
    //   "By email: Info@travelhelpsquad.co.uk",
    //   "By visiting this page on our website: www.travelhelpsquad.co.uk/contact-us ",
    // ],
  },
];
