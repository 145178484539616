import React from "react";
import { Container, Grid,  } from "@mui/material";
import BlogImage from "../../assets/blogsimage.png";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";



const Blogs = () => {
  return (
    <div
      style={{
        backgroundImage: `url(${BlogImage})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        position: "relative",
        width: "100%",
        minHeight: "489px",
        opacity: 1,
      }}
    >
      <div
        style={{
          position: "absolute",
          width: "100%",
          height:"100%", 
          // background: "linear-gradient(262deg, rgba(0, 0, 0, 0.06) 13.81%, #000 106.26%)",
            background:" linear-gradient(262deg, rgba(255,255,255,1) 0%, rgba(0,0,0,0.06) 13.81%, #000 106.26%)",
            opacity: 1,
        }}
      ></div>
      <Container maxWidth="xl">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "center",
                width: "100%",
                mt: "221px",
                paddingLeft: "24px",
              }}
            >
              <Typography
                sx={{
                  color: "#FFFFFF",
                  fontFamily: "Gilroy-Bold",
                  fontSize: "56px",
                  fontStyle: "normal",
                  fontWeight: 700,
                  zIndex: 1,
                }}
              >
                Blogs{" "}
              </Typography>
              <Typography
                sx={{
                  color: "rgba(255, 255, 255, 0.70)",
                  fontFamily: "Gilroy-Medium",
                  fontSize: "20px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "32px",
                  zIndex: 1,
                  mt: 2,
                }}
              >
                Follow our blogs for most latest events, news and Uk wide<br/>
                traffic updates
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Blogs;
