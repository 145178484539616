import BlogsSection from "../BuyandSellCarTyres/blog";
import PicsBlog from "../BuyandSellCarTyres/Picsblogsection";
import ThirdSection from "../BuyandSellCarTyres/categories";


function HomeBlogs() {
  return (
    <div>
      <BlogsSection />
      <PicsBlog/>
      <ThirdSection/>
   
    </div>
  );
}

export default HomeBlogs;
