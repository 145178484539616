// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./fonts/Gilroy-Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./fonts/Gilroy-SemiBold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./fonts/Gilroy-Bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./fonts/Gilroy-Regular.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "Gilroy-Medium";

  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("truetype");
}

@font-face {
  font-family: "Gilroy-SemiBold";

  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("truetype");
}

@font-face {
  font-family: "Gilroy-Bold";

  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("truetype");
}

@font-face {
  font-family: "Gilroy-Regular";

  src: url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format("truetype");
}
`, "",{"version":3,"sources":["webpack://./src/fonts.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;;EAE5B,+DAAwD;AAC1D;;AAEA;EACE,8BAA8B;;EAE9B,+DAA0D;AAC5D;;AAEA;EACE,0BAA0B;;EAE1B,+DAAsD;AACxD;;AAEA;EACE,6BAA6B;;EAE7B,+DAAyD;AAC3D","sourcesContent":["@font-face {\n  font-family: \"Gilroy-Medium\";\n\n  src: url(\"./fonts/Gilroy-Medium.ttf\") format(\"truetype\");\n}\n\n@font-face {\n  font-family: \"Gilroy-SemiBold\";\n\n  src: url(\"./fonts/Gilroy-SemiBold.ttf\") format(\"truetype\");\n}\n\n@font-face {\n  font-family: \"Gilroy-Bold\";\n\n  src: url(\"./fonts/Gilroy-Bold.ttf\") format(\"truetype\");\n}\n\n@font-face {\n  font-family: \"Gilroy-Regular\";\n\n  src: url(\"./fonts/Gilroy-Regular.ttf\") format(\"truetype\");\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
