import React from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Grid,
  Box,
} from "@mui/material";
import Cardone from "../../assets/cardone.png";
import Cardtwo from "../../assets/cardtwo.png";
import Cardthree from "../../assets/cardthree.png";
import { makeStyles } from "@mui/styles";
import LayoutWrapper from "../lay-out/layout-wrapper";

const cardData = [
  {
    id: 1,
    imageUrl: Cardone,
    text: `Discover effortless parking solutions.`,
    textone:
      "Use smart digital tools to make parking enforcement easier. This helps issue tickets, keep an eye on things, and manage parking more efficiently, making everyone's experience better.",
  },
  {
    id: 2,
    imageUrl: Cardtwo,
    text: "Exceptional customer support and Commitment .",
    textone:
      "At our core, we are unwavering in our commitment to provide the highest levels of customer service and support, guaranteeing your satisfaction and a consistently exceptional experience with us.",
  },
  {
    id: 3,
    imageUrl: Cardthree,
    text: "Hassle-free PCN management.",
    textone:
      "From the beginning to the end, our cloud platform makes penalty charge notice management a breeze, ensuring a straightforward and efficient process for seamless operations.",
  },
];

const useStyles = makeStyles(() => ({
  cardContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "stretch",
  },
  card: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    borderRadius: "20px",
    marginBottom: "20px",
    height:"100%"
  },
}));

const CardSection = () => {
  const classes = useStyles();

  return (
    <LayoutWrapper background='#EDF3F1'>
      <Grid
        container
        spacing={3}
        className={classes.cardContainer}
        sx={{ marginTop: "77px" }}
      >
        {cardData.map((card) => (
          <Grid item xs={12} sm={12} md={6} lg={4} key={card.id}>
            <Card
              variant="outlined"
              className={classes.card}
              sx={{ borderRadius: "20px" }}
            >
              <CardMedia
                component="img"
                height="369px"
                width="373px"
                flexShrink="0"
                image={card.imageUrl}
                alt={`Card ${card.id}`}
              />
              <CardContent>
                <Typography
                  variant="h1"
                  component="div"
                  dangerouslySetInnerHTML={{ __html: card.text }}
                  sx={{
                    color: "#3B4A45",
                    fontFamily: "Gilroy-Bold",
                    fontSize: "22px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: 1.2,
                    textAlign: "start",
                  }}
                />
                <hr
                  style={{
                    width: "15%",
                    marginLeft: "0px",
                    border: "none",
                    borderTop: "2px solid #21B07D",
                    borderRadius: "2px",
                  }}
                />
                <Typography
                  variant="body1"
                  color="text.secondary"
                  sx={{
                    color: "#5C5F5E",
                    fontFamily: "Gilroy-Medium",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "30px",
                    textAlign: "start",
                  }}
                >
                  {card.textone.split("<br>").map((line, index) => (
                    <React.Fragment key={index}>{line}</React.Fragment>
                  ))}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </LayoutWrapper>
  );
};

export default CardSection;
