import React from "react";
import FleetOperationHero from "../../components/fleet-operators/fleet-operatorshero/fleet-operatorshero";
import FleetOperationCard from "../../components/fleet-operators/fleet-operators-card/fleet-operators-card";

const FleetOperators = () => {
  return (
    <>
      <FleetOperationHero />
      <FleetOperationCard />
    </>
  );
};

export default FleetOperators;
