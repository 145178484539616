// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  text-align: center;
  background: "#EDF3F1";
}

.App-logo {
  /* height: 40vmin;
  pointer-events: none; */
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    /* animation: App-logo-spin infinite 20s linear; */
  }
}

.App-header {
  /* background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white; */
}

.App-link {
  /* color: #61dafb; */
}

@keyframes App-logo-spin {
  /* from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  } */
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,qBAAqB;AACvB;;AAEA;EACE;yBACuB;AACzB;;AAEA;EACE;IACE,kDAAkD;EACpD;AACF;;AAEA;EACE;;;;;;;iBAOe;AACjB;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE;;;;;KAKG;AACL","sourcesContent":[".App {\n  text-align: center;\n  background: \"#EDF3F1\";\n}\n\n.App-logo {\n  /* height: 40vmin;\n  pointer-events: none; */\n}\n\n@media (prefers-reduced-motion: no-preference) {\n  .App-logo {\n    /* animation: App-logo-spin infinite 20s linear; */\n  }\n}\n\n.App-header {\n  /* background-color: #282c34;\n  min-height: 100vh;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  font-size: calc(10px + 2vmin);\n  color: white; */\n}\n\n.App-link {\n  /* color: #61dafb; */\n}\n\n@keyframes App-logo-spin {\n  /* from {\n    transform: rotate(0deg);\n  }\n  to {\n    transform: rotate(360deg);\n  } */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
