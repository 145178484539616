import {
  Container,
  Grid,
  Typography,
  CardContent,
  CardMedia,
  Box,
  MenuItem,
  Select,
  FormControl,
  TextField,
  InputAdornment,
  CardHeader,
  Button,
} from "@mui/material";
import * as React from "react";
import { styled } from "@mui/material/styles";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import { red } from "@mui/material/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Card from "@mui/material/Card";
import ChargingStationCard from "../../../../../assets/chargingstation.png";

const ChargingStationsCard = () => {
  const data = [
    {
      title: "1.Lawyers",
      image: ChargingStationCard,
      content: (
        <div>
          <ul style={{ paddingTop: "8px", listStyleType: "none" }}>
            <li style={{ paddingTop: "8px" }}>
              <span
                style={{
                  color: "#5C5F5E",
                  fontFamily: "Gilroy-Bold",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 400,
                }}
              >
                Charge on the Go:{" "}
              </span>{" "}
              Never worry about running out of battery again. Travel Help Squad
              locates the nearest charging stations to keep you powered up,
              ensuring you can continue your journey hassle-free.{" "}
            </li>
            <li style={{ paddingTop: "8px" }}>
              <span
                style={{
                  color: "#5C5F5E",
                  fontFamily: "Gilroy-Bold",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 400,
                }}
              >
                Eco-Friendly Travel:{" "}
              </span>{" "}
              Embrace sustainability with ease. Our app helps you locate
              eco-friendly charging stations, reducing your carbon footprint
              while on the road.​​
            </li>
            <li style={{ paddingTop: "8px" }}>
              <span
                style={{
                  color: "#5C5F5E",
                  fontFamily: "Gilroy-Bold",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 400,
                }}
              >
                Quick and Convenient:{" "}
              </span>
              Find charging stations that match your vehicle's compatibility.
              Travel Help Squad ensures you get a fast and efficient charge, so
              you can get back on the road in no time.
            </li>
            <li style={{ paddingTop: "8px" }}>
              <span
                style={{
                  color: "#5C5F5E",
                  fontFamily: "Gilroy-Bold",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 400,
                }}
              >
                User-Friendly Interface:{" "}
              </span>{" "}
              Our intuitive app interface makes locating and accessing charging
              stations a breeze, ensuring a stress-free experience for every
              driver.​
            </li>
            Travel Help Squad's helps to locates the nearest charging Charging
            Stations to have an uninterrupted and eco-conscious travel. Register
            now and stay charged up wherever you go!​
          </ul>{" "}
        </div>
      ),
    },
  ];

  return (
    <>
    <Container maxWidth="xl">
      <Grid
        container
        sx={{
          p: { sm: "15px", xs: "0px" },
          borderRadius: "20px",
          background: "#EDF3F1",
          boxShadow: "0px 34px 74px -12px rgba(0, 0, 0, 0.16)",
          position: "relative",
          zIndex: 10,
          mt: -10,
        }}
      >
        <Grid item xs={12} lg={12}>
          {data.map((item, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "left",
                p: { sm: 0, xs: 2 },
                width: "100%",
              }}
            >
              <img src={item.image} alt={`Image ${index}`} width="100%" />
              <Typography
                sx={{
                  fontFamily: "Gilroy-Medium",
                  fontSize: "16px",
                  fontWeight: 400,
                  lineHeight: "30px",
                  textAlign: "left",
                  color: "#5C5F5E",
                  marginTop: "10px",
                }}
              >
                {item.content}
              </Typography>
            </Box>
          ))}
        </Grid>
      </Grid>
    </Container>
  </>
  );
};

export default ChargingStationsCard;
