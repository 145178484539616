import React from "react";
import { Container, Grid, Paper } from "@mui/material";
import BlogImage from "../../assets/pricingblog.png";
import colorburn from "../../assets/colorburnone.png";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import { Button } from "@mui/material";
import "./contactstyle.css";

const dotStyle = {
  color: "#21B07D",
  position: "relative",
  left: "2px",
};
const Contact = () => {
  return (
    <>
      <div
        style={{
          background: `url(${BlogImage})`,
          backgroundRepeat: "no-repeat",
          // minHeight: "590px",
          width: "100%",
          minHeight: "590px",
          position: "relative",
          zIndex: 2,
          backgroundSize: "cover",
        }}
      >
        <div
          style={{
            position: "absolute",
            width: "100%",
            // minHeight: "489px",
            height: "100%",
            backgroundRepeat: "no-repeat",
            // background: "linear-gradient(262deg, rgba(0, 0, 0, 0.6) 13.81%, #000 106.26%)",
            // background: " linear-gradient(262deg, rgba(0, 0, 0, 0.89), rgba(0, 0, 0, 0.89))",
            // background: "linear-gradient(180deg, rgba(7, 16, 31, 0.00) 13.65%, #293D54 93.29%)",
            // mixBlendMode: "multiply",
            // background:
            //   "linear-gradient(180deg, rgba(7, 16, 31, 1.9) 13.65%, #293D54 93.29%), url(<path-to-image>) lightgray -194.333px 0px / 126.991% 109.153% no-repeat",
            mixBlendMode: "multiply",

            left: 0,
            top: 0,
          }}
        ></div>
        <Container maxWidth="xl">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "Center",
                  justifyContent: "center",
                  // width: '100%',
                  mt: "201px",
                  // paddingLeft:'24px',
                }}
              >
                <Typography
                  sx={{
                    color: "#FFFFFF",
                    fontFamily: "Gilroy-Bold",
                    fontSize: "56px",
                    fontStyle: "normal",
                    fontWeight: 700,
                    zIndex: 1,
                  }}
                >
                  Register Charging Stations{" "}
                </Typography>
                <Typography
                  sx={{
                    color: "rgba(255, 255, 255, 0.70)",
                    fontFamily: "Gilroy-Medium",
                    fontSize: "20px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "32px",
                    zIndex: 1,
                    mt: 2,
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                 Register today and start earning from home
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
};

export default Contact;
