// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.offermain {
    /* border: 1px solid red; */
    /* padding-left: 120px; */
    /* padding-right: 120px; */
  }
  .offer-searchbar-container {
    position: absolute;
    top: 40%;
    left: 0;
    width: 100%;
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2; 
  }
  .offer-custom-class.css-4epqjg-MuiGrid-root {
    margin-top: 790px !important;
}
  @media (max-width: 600px) {
    .offermain {
      padding-left: 12px !important;
      padding-right: 12px !important;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/components/our-offerings/offer-hero/offer-hero.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,yBAAyB;IACzB,0BAA0B;EAC5B;EACA;IACE,kBAAkB;IAClB,QAAQ;IACR,OAAO;IACP,WAAW;IACX,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,UAAU;EACZ;EACA;IACE,4BAA4B;AAChC;EACE;IACE;MACE,6BAA6B;MAC7B,8BAA8B;IAChC;EACF","sourcesContent":[".offermain {\n    /* border: 1px solid red; */\n    /* padding-left: 120px; */\n    /* padding-right: 120px; */\n  }\n  .offer-searchbar-container {\n    position: absolute;\n    top: 40%;\n    left: 0;\n    width: 100%;\n    height: 50%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    z-index: 2; \n  }\n  .offer-custom-class.css-4epqjg-MuiGrid-root {\n    margin-top: 790px !important;\n}\n  @media (max-width: 600px) {\n    .offermain {\n      padding-left: 12px !important;\n      padding-right: 12px !important;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
