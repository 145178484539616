import {
  Container,
  Grid,
  Typography,
  CardContent,
  CardMedia,
  Box,
  MenuItem,
  Select,
  FormControl,
  TextField,
  InputAdornment,
  CardHeader,
  Button,
} from "@mui/material";
import * as React from "react";
import { styled } from "@mui/material/styles";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import { red } from "@mui/material/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Card from "@mui/material/Card";
import InsuranceCardImage from "../../../../../assets/insurancecard.png";
import { Link } from "react-router-dom";

const InsuranceCard = () => {
  const data = [
    {
      image: InsuranceCardImage,
      content: (
        <div>
          <ul style={{ paddingTop: "8px", listStyleType: "none" }}>
            Travel Help Squad doesn't just make your journey easier, it also
            helps protect you on the road. We help you find cheap insurance, you
            can have peace of mind every time you drive.
            <li style={{ paddingTop: "8px" }}>
              <span
                style={{
                  color: "#5C5F5E",
                  fontFamily: "Gilroy-Bold",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 400,
                }}
              >
                Stay Protected:{" "}
              </span>{" "}
              Safeguard your driving experience with our insurance solutions.
              Travel Help Squad offers comprehensive coverage tailored to your
              needs.
            </li>
            <li style={{ paddingTop: "8px" }}>
              <span
                style={{
                  color: "#5C5F5E",
                  fontFamily: "Gilroy-Bold",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 400,
                }}
              >
                ​Hassle-Free Claims:{" "}
              </span>{" "}
              In the event of an accident, our app simplifies the claims
              process. Get back on the road faster, knowing we have your back.​
            </li>
            <li style={{ paddingTop: "8px" }}>
              <span
                style={{
                  color: "#5C5F5E",
                  fontFamily: "Gilroy-Bold",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 400,
                }}
              >
                Affordable Rates:{" "}
              </span>
              Enjoy competitive insurance rates that won't break the bank.
              Travel Help Squad helps you find the best deals on insurance,
              saving you money.​
            </li>
            <li style={{ paddingTop: "8px" }}>
              <span
                style={{
                  color: "#5C5F5E",
                  fontFamily: "Gilroy-Bold",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 400,
                }}
              >
                Policy Management:{" "}
              </span>{" "}
              Easily manage your insurance policies within the app. Renewals,
              updates, and access to important documents are just a tap away.
            </li>
            Travel Help Squad's Insurance feature ensures that you're covered,
            no matter where your journey takes you. Drive confidently –  <Link style={{ color: "#21B07D" }} to="/sign-up">
                        Register  today!​
                      </Link>
           
          </ul>
        </div>
      ),
    },
  ];

  return (
    <>
      <Container maxWidth="xl">
        <Grid
          container
          sx={{
            p: { sm: "15px", xs: "0px" },
            borderRadius: "20px",
            background: "#EDF3F1",
            boxShadow: "0px 34px 74px -12px rgba(0, 0, 0, 0.16)",
            position: "relative",
            zIndex: 10,
            mt: -10,
          }}
        >
          <Grid item xs={12} lg={12}>
            {data.map((item, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "left",
                  p: { sm: 0, xs: 2 },
                  width: "100%",
                }}
              >
                <img src={item.image} alt={`Image ${index}`} width="100%" />
                <Typography
                  sx={{
                    fontFamily: "Gilroy-Medium",
                    fontSize: "16px",
                    fontWeight: 400,
                    lineHeight: "30px",
                    textAlign: "left",
                    color: "#5C5F5E",
                    marginTop: "10px",
                  }}
                >
                  {item.content}
                </Typography>
              </Box>
            ))}
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default InsuranceCard;
