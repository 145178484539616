import SecondSection from "../Home/secondsection";
import TravelHelpSquad from "../Home/travelhelpsquad";
import CardSectionthree from "../Home/cardsection";
import CardInsurance from "../Home/carinsurance";
import DrivingTestSection from "../Home/drivingtestsection";
import CardOffering from "../Home/cardsectionoffering";
import { Box } from "@mui/material";

function Home() {
  return (
    <Box>
      <SecondSection/>
      <Box
        sx={{
          marginBottom: {
            xl: "0px",
            lg: "0px",
            md: "130px",
            sm: "130px",
            xs: "130px",
          },
        }}
      >
        <CardSectionthree />
      </Box>
     
      <CardOffering />
      
      <Box sx={{
          marginTop: {
            xl: "140px",
            lg: "140px",
            md: "130px",
            sm: "130px",
            xs: "130px",
          },
        }}>
      <DrivingTestSection />
      </Box>

     

      <CardInsurance />

      <TravelHelpSquad />
    </Box>
  );
}

export default Home;
