import React from 'react'
import FinanceCompaniesHero from './finance-companies-hero/finance-companies-hero'
import FinanceCompaniesCard from './finance-companies-card/finance-companies-card'

const FinanceCompanies = () => {
  return (
   
    <>
    <FinanceCompaniesHero/>
    <FinanceCompaniesCard/></>
  )
}

export default FinanceCompanies
