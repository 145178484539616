import BlogsSection from "../Blog/blog";
import PicsBlog from "../Blog/Picsblogsection";


function HomeBlogs() {
  return (
    <div>
      <BlogsSection />
      <PicsBlog/>
   
    </div>
  );
}

export default HomeBlogs;
