import * as React from "react";
import Box from "@mui/material/Box";
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import { CustomTextField } from "../../../hook-form";
import { useForm, FormProvider } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useEffect } from "react";
import authImg from "../../../assets/authIcon.svg";

const validationSchema = Yup.object().shape({
  email: Yup.string().email().required("Field is required"),
  password: Yup.string().required("Field is required"),
});

const defaultValues = {
  email: "",
  password: "",
  companyShortCode:"THS",
};
export default function SignIn() {
  const [showPassword, setShowPassword] = useState(false);
  const [isErrorMsg, setIsErrorMsg] = useState("");
  const navigate = useNavigate();
  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });
  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };
  const passwordEndAdornment = {
    endAdornment: (
      <InputAdornment position="end">
        <IconButton onClick={handleShowPassword} edge="end">
          {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
        </IconButton>
      </InputAdornment>
    ),
  };
  const { handleSubmit } = methods;
  // const business_name = "Travel Help Squad";
  const onSubmit = async (formValues) => {
    // formValues.business_name = business_name;
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_KEY}auth/signin`,
        {
          method: "PUT",
          headers: {
            "Content-type": "application/json",
            accept: "application/json",
          },
          body: JSON.stringify(formValues),
        }
      );

      if (response.ok) {
        const responseData = await response.json();
        console.log("responseData", responseData.data.token);
        const token = responseData.data.token || null;
        localStorage.setItem("loginToken", token);
        if (responseData.status) {
          navigate("/under-construction");
        } else {
          setIsErrorMsg(responseData.msg);
          console.log(responseData.msg);
        }
      } else if (response.status === 452) {
        const responseData = await response.json();
        setIsErrorMsg(responseData.msg);
      }
    } catch (error) {
      setIsErrorMsg("Something went wrong. Please try again!");
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsErrorMsg("");
    }, 3000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [isErrorMsg]);
  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        background: "#EDF3F1",
        // position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {isErrorMsg && (
            <p
              style={{
                color: "red",
                fontSize: "16px",
                fontWeight: "600",
                textAlign: "center",
              }}
            >
              {isErrorMsg}
            </p>
          )}
            <Box  
              sx={{
                borderRadius: "16px",
                boxShadow: "0px 0px 12px 0px rgba(65, 204, 184, 0.18)",
                marginBottom: "40px",
                margin: "0 auto",
                maxWidth: "600px",
                width: "100%",
                // height: "100%",
                padding: "22px",
              }}
            >
              <Grid
                container
                spacing={2}
                justifyContent={"center"}
                display={"flex"}
    
              >
                <Grid item xl={12} xs={12} mt={5}>
                  <Box
                    display="flex"
                    justifyContent="center"
                    flexDirection="column"
                    flexWrap="wrap"
                  >
                    <img src={authImg} aly="img" />
                    <Typography textAlign="center" sx={styles.logoText}>
                      Where Innovation Meets Mobility
                    </Typography>
                    <Typography mt={3} textAlign="center" sx={styles.loginText}>
                      Login
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <CustomTextField
                    name="email"
                    label="Email"
                    placeholder="Enter  email"
                    fullWidth
                    disabled={false}
                    multiline={false}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomTextField
                    name="password"
                    label="Password"
                    placeholder="Enter password"
                    type={showPassword ? "text" : "password"}
                    InputProps={passwordEndAdornment}
                    fullWidth
                    disabled={false}
                    multiline={false}
                  />
                </Grid>
                <Grid
                  mt={2}
                  mb={2}
                  item
                  xl={12}
                  xs={12}
                  display={"flex"}
                  justifyContent={"center"}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{
                      background: "#21B07D ",
                      padding: "11px 18px",
                      borderRadius: "8px",
                      textTransform: "capitalize",
                      color: "#FFF",
                      textAlign: "center",
                      fontFamily: "Gilroy-SemiBold",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "normal",
                      width: "100%",
                      height: "49px",
                      letterSpacing: "0.8px",
                      "&:hover": {
                        background: "#21B07D",
                      },
                    }}
                  >
                    Login
                  </Button>
                </Grid>
                <Grid mb={2}
                  item
                  xl={10}
                  xs={11}
                  display={"flex"}
                  justifyContent={"center"}
                >
                  <Typography textAlign="center">
                    Don’t have an account?{" "}
                    <span>
                      <Link style={{ color: "#21B07D" }} to="/sign-up">
                        Register Yourself
                      </Link>
                    </span>
                  </Typography>
                </Grid>
                <Box
                  sx={{ position: "absolute", bottom: 10 }}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Typography
                    mt={5}
                    textAlign={"center"}
                    sx={styles.copyRightText}
                  >
                    Copyright © 2024 Travel Help Squad, Inc. - All Rights
                    Reserved
                  </Typography>
                </Box>
              </Grid>
            </Box>
        </form>
      </FormProvider>
    </Box>
  );
}

const styles = {
  bottomText: {
    fontSize: "16px",
    fontWeight: "700",
    color: "#344054",
    fontFamily: "Open Sans",
  },
  logoText: {
    fontSize: "14px",
    fontWeight: "400",
    color: "#667085",
    fontFamily: "Open Sans",
  },
  loginText: {
    fontSize: "32px",
    fontWeight: "500",
    color: "#434242",
    fontFamily: "Roboto",
  },
  alreadyAccountText: {
    fontSize: "16px",
    fontWeight: "600",
    color: "#1D2939",
    fontFamily: "Open Sans",
  },
  copyRightText: {
    fontSize: "16px",
    fontWeight: "400",
    color: "#344054",
    fontFamily: "Open Sans",
  },
};
