import React from "react";
import { Grid, Box, Typography, Container, Paper } from "@mui/material";
import BlogImageCent from "../../assets/blogimagecent.png";
import DrivingImage from "../../assets/drivingblogimage.png";
import DrivingOffenceImage from "../../assets/drivingoffenceimage.png";
import PenaltyChargeImage from "../../assets/penaltychargeimage.png";
import ProhabitedVehicle from "../../assets/ProbitedVehacle.png";
import NewPortAvenue from "../../assets/xyz.jpg";
import TrafficMonday from "../../assets/trafficmonday.png";
import BusLane from "../../assets/buysellcartyres.png";
// import Dartford from "../../assets/dartford.png";
import { textAlign } from "@mui/system";
import "./blogs.css";

const styles = {
  imageContainer: {
    position: "relative",
  },
  textOverlay: {
    position: "absolute",
    top: "68%",
    left: "17%",
    transform: "translate(-50%, -50%)",
    zIndex: 1,
    color: "rgba(255, 255, 255, 1)",
    padding: "10px",
  },
  textOverlayone: {
    position: "absolute",
    top: "73%",
    left: "18%",
    transform: "translate(-50%, -50%)",
    zIndex: 1,
    color: "rgba(255, 255, 255, 1)",
    padding: "10px",
  },
  textOverlaydart: {
    position: "absolute",
    left: "28%",
    marginTop: "-7%",
    transform: "translate(-50%, -50%)",
    zIndex: 1,
    color: "rgba(255, 255, 255, 1)",
    padding: "10px",
  },
  textOverlayonedart: {
    position: "absolute",
    left: "31%",
    marginTop: "-4%",
    transform: "translate(-50%, -50%)",
    zIndex: 1,
    color: "rgba(255, 255, 255, 1)",
    padding: "10px",
  },
  textOverlaydarttwo: {
    position: "absolute",
    left: "28%",
    marginTop: "-7%",
    transform: "translate(-50%, -50%)",
    zIndex: 1,
    color: "rgba(255, 255, 255, 1)",
    padding: "10px",
  },
  textOverlayonedarttwo: {
    position: "absolute",
    left: "31%",
    marginTop: "-4%",
    transform: "translate(-50%, -50%)",
    zIndex: 1,
    color: "rgba(255, 255, 255, 1)",
    padding: "10px",
  },
};

const images = [
  {
    image: ProhabitedVehicle,
    maxWidth: "683px",
  },

  { image: NewPortAvenue, maxWidth: "378px", height: "254px" },
];

const imagesone = [
  { image: TrafficMonday, maxWidth: "683px" },
  { image: BusLane, maxWidth: "348px", height: "254px" },
];
// const imagesthree = [{ image: Dartford, maxWidth: "1200px", height: "302px" }];

const data = [
  {
    title: "More Traffic Fines on Its Way",
    image: BlogImageCent,
    content: (
      <div>
        <Typography
          sx={{
            fontFamily: "Gilroy-Medium",
            fontSize: "16px",
            py: "30px",
            lineHeight: "30px",
            textAlign: "start",
            width: "100%",
          }}
        >
          Councils will be able to fine drivers for moving traffic offenses from
          later this year – Confirmed by The Department for Transport. This
          includes blocking box junction, driving in cycle lanes and neglecting
          no-entry signs. Though this increases the probability that councils
          might use this power to raise their revenue from traffic offenses,
          however, the guidelines how to enforce this power is still in
          discussion. For example, authorities may issue a warning letter at
          first instant rather than fines.
        </Typography>
      </div>
    ),
  },
  {
    title: " Driving in London",
    image: DrivingImage,
    content: (
      <div>
        <Typography
          variant="body1"
          align="left"
          sx={{
            fontFamily: "Gilroy-Medium",
            fontSize: "16px",
            py: "30px",
          }}
        >
          There are number of things one need to consider while driving in
          London that differs from other parts of the UK. First time drivers are
          advised to plan their trip and best route possible before they start.
          This includes avoiding busy junctions at peak hours, making sure that
          all necessary chares like congestion charge (if applies) is paid to
          avoid any fine. Look out for most important things like
          <ul style={{ paddingTop: "30px" }}>
            <li style={{ paddingTop: "8px" }}>Yellow Box junctions</li>
            <li style={{ paddingTop: "8px" }}>Bus lanes</li>
            <li style={{ paddingTop: "8px" }}>Red routes</li>
            <li style={{ paddingTop: "8px" }}>Parking</li>
            <li style={{ paddingTop: "8px" }}>Rules of the road</li>
          </ul>
        </Typography>
      </div>
    ),
  },
  {
    title:
      "A Glimpse of the Difference between Minor and Major Driving Offences",
    image: DrivingOffenceImage,
    content: (
      <div>
        <Typography
          variant="h1"
          align="left"
          sx={{
            fontFamily: "Gilroy-Medium",
            fontSize: "16px",
            textDecoration: "underline",
            py: "30px",
          }}
        >
          <u>Minor Offences:</u>{" "}
        </Typography>{" "}
        <Typography
          variant="body"
          sx={{
            fontFamily: "Gilroy-Medium",
            fontSize: "16px",
          }}
        >
          <ul style={{ paddingTop: "8px" }}>
            <li style={{ paddingTop: "8px" }}>
              A low-level misconduct, with the usual treatment as a fixed
              penalty notice (FPN). An FPN is an administrative alternative to
              prosecution in a Magistrates Court and usually involves the issue
              of a fine/ penalty points on one’s driving licence. Either you pay
              and avoid a summons, or you challenge the FPN and appear in court.{" "}
            </li>
            <li style={{ paddingTop: "8px" }}>
            These include low-level speeding, driving without or an expired MOT and using a handheld mobile phone behind the wheel.
            </li>
            <li style={{ paddingTop: "8px" }}>
            Minor offences result in higher premiums to the car insurance. 
            </li>
          </ul>{" "}
        </Typography>
        <Typography
          variant="h1"
          align="left"
          sx={{
            fontFamily: "Gilroy-Medium",
            fontSize: "16px",
            textDecoration: "underline",
            py: "30px",
          }}
        >
          Major Offences:
        </Typography>{" "}
        <Typography
          variant="body"
          sx={{
            fontFamily: "Gilroy-Medium",
            fontSize: "16px",
          }}
        >
          <ul style={{ paddingTop: "8px" }}>
            <li style={{ paddingTop: "8px" }}>
            Major offences are serious violations that are dealt with at a Magistrates Court.
            </li>
            <li style={{ paddingTop: "8px" }}>
            They include offences like driving under the influence of alcohol and dangerous driving.
            </li>
            <li style={{ paddingTop: "8px" }}>
            The punishment includes a driving ban and/or prison sentence depending on the severity of the offence. 
            </li>
          </ul>
        </Typography>
      </div>
    ),
  },
  {
    title: " Five facts about UK Roads and traffic",
    image: PenaltyChargeImage,
    content: (
      <div>
        <Typography
          variant="body"
          sx={{
            fontFamily: "Gilroy-Medium",
            fontSize: "16px",
            py: "8px",
          }}
        >
          <ul style={{ paddingTop: "8px" }}>
            <li style={{ paddingTop: "8px" }}>
              <span style={{ color: "rgba(33, 176, 125, 1)" }}>
                The first motorway -
              </span>{" "}
              The first Motorway was opened on 5th Dec 1958, with the name M6
              Preston Bypass. This motorway was only 8 miles long stretching
              from Bamber Bridge to Broughton. 
            </li>
            <li style={{ paddingTop: "8px" }}>
              <span style={{ color: "rgba(33, 176, 125, 1)" }}>
                The Very First Traffic Light –
              </span>{" "}
              The first gas powered, and hand operated traffic signal was
              invented by JP Knight in 1868, which was installed outside the
              house of Parliament in London. 
            </li>
            <li style={{ paddingTop: "8px" }}>
              <span style={{ color: "rgba(33, 176, 125, 1)" }}>
                The city with the most traffic lights –
              </span>
              The city of Leicester is thought to have the highest number of
              traffic lights in one UK city. 
            </li>
            <li style={{ paddingTop: "8px" }}>
              <span style={{ color: "rgba(33, 176, 125, 1)" }}>
                Parking Charges -
              </span>{" "}
              From 6 April 2015, user gets a 10-minute grace period before a PCN
              is issued if you overstay your permitted time. This applies to on
              or off-road parking in England only.{" "}
            </li>
            <li style={{ paddingTop: "8px" }}>
              <span style={{ color: "rgba(33, 176, 125, 1)" }}>
                Penalty Charges -
              </span>{" "}
              If you pay the charge within 14 days of getting it, you get a 50%
              discount. If the penalty charge notice was sent by post on the
              basis of camera evidence, the charge will be reduced by 50%if it
              is paid within 21 days.
            </li>
          </ul>{" "}
        </Typography>
      </div>
    ),
  },
];

const PicsBlog = () => {
  return (
    <Container maxWidth="xxl">
      <Box sx={{ paddingTop: "80px", px: { lg: "88px", xs: "0px" } }}>
        <Grid container justifyContent="center">
          {data.map((item, index) => (
            <Grid
              item
              key={index}
              md={10.5}
              xs={12}
              sx={{ px: { xs: "20px" } }}
            >
              <Typography
                variant="h4"
                align="left"
                sx={{
                  fontFamily: "Gilroy-Bold",
                  fontSize: "32px",
                  py: "30px",
                }}
              >
                {item.title}
              </Typography>
              <img src={item.image} alt={`Image ${index}`} width="100%" />
              <Typography>{item.content}</Typography>
            </Grid>
          ))}
        </Grid>
        {/* <Grid container justifyContent="center">
          <Grid item md={10.5} xs={12}>
            <Typography
              variant="h4"
              align="left"
              sx={{
                fontFamily: "Gilroy-Bold",
                fontSize: "32px",
                py: "8px",
                px: "50px",
              }}
            >
              5. Top 5 London’s PCN Hotspots for Fines
            </Typography>
            <div className="blog-container">
              {images.map((imageObject, index) => (
                <div key={index} className="blog-image">
                  <img
                    src={imageObject.image}
                    alt={`Image ${index + 1}`}
                    style={{
                      width: "100%",
                      maxWidth: imageObject.maxWidth,
                      height: imageObject.height,
                    }}
                  />
                </div>
              ))}
            </div>
            <div className="blog-container">
              {imagesone.map((imageObject, index) => (
                <div key={index} className="blog-image">
                  <img
                    src={imageObject.image}
                    alt={`Image ${index + 1}`}
                    style={{
                      width: "100%",
                      maxWidth: imageObject.maxWidth,
                      height: imageObject.height,
                    }}
                  />
                </div>
              ))}
            </div>
            <div className="blog-containerthree">
              {imagesthree.map((imageObject, index) => (
                <div key={index} className="blog-image">
                  <img
                    src={imageObject.image}
                    alt={`Image ${index + 1}`}
                    style={{
                      width: "100%",
                      height: imageObject.height,
                    }}
                  />
                </div>
              ))}
            </div>
          </Grid> */}
        {/* <Grid
            container
            justifyContent="center"
            sx={{ marginLeft: "9%", py: "60px" }}
            spacing={0}
          >
            {images.map((imageObject, index) => (
              <Grid item key={index} xs={12} md={6}>
                <img
                  src={imageObject.image}
                  alt={`Image ${index + 1}`}
                  style={{
                    width: "100%",
                    height: imageObject.height,
                    maxWidth: imageObject.maxWidth,
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent="center"
          sx={{ marginLeft: "9%" }}
          spacing={0}
        >
          {imagesone.map((imageObject, index) => (
            <Grid item key={index} xs={12} md={6}>
              <>
                <img
                  src={imageObject.image}
                  alt={`Image ${index + 1}`}
                  style={{
                    width: "100%",
                    height: imageObject.height,
                    maxWidth: imageObject.maxWidth,
                  
                  }}
                />
              </>
            </Grid>
          ))}
        </Grid>

        <Grid container justifyContent="center" sx={{ py: "40px" }}>
          <Grid item xs={12} md={6} sx={{ ml: "-10%" }}>
            <>
              <img
                src={Dartford}
                alt="Dartford"
                style={{ width: "100%", maxWidth: "1200px" }}
              />
            </>
          </Grid>
        </Grid> */}
      </Box>
    </Container>
  );
};

export default PicsBlog;
