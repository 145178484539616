import React from 'react'
import CommercialAccountHero from './commercial-account-hero/commercial-account-hero'
import CommercialAccountCards from './commercial-account-cards/commercial-account-cards'

const CommercialAccount = () => {
  console.log("CommercialAccount component is rendering"); 
  return (
    <>  <CommercialAccountHero/>
  <CommercialAccountCards/>
  </>

  )
}

export default CommercialAccount