export const cookiesPolicyMockData = [
  {
    id: "1",
    title: "Introduction",
    content:
      "At Travel Help Squad, we use cookies to enhance your experience on our website. This Cookies Policy explains what cookies are, how we use them, and how you can manage them. ",
  },
  {
    id: "2",
    title: "What Are Cookies?",
    content:
      "Cookies are small text files placed on your device when you visit a website. They help the website function properly, provide a better user experience, and collect analytical data.",
  },
  {
    id: "3",
    title: "How We Use Cookies",
    content:"We use cookies for various purposes:",
    list: [
      `<strong>Essential Cookies:</strong> These cookies are necessary for the website to function and cannot be switched off in our systems.`,
      `<strong>Performance Cookies:</strong> These cookies help us understand how visitors interact with our website, providing information about areas visited, time spent on the site, and any issues encountered.`,
      `<strong>Functionality Cookie:</strong> These cookies remember choices you make to improve your experience, such as your username, language, or region.`,
      `<strong>Targeting Cookies:</strong> These cookies collect information about your browsing habits to make advertising relevant to you and your interests.`,
    ],
  },
  
  {
    id: "4",
    title: "Managing Cookies",
    content:
      "We may also use third-party cookies from service providers to help us analyse website usage and improve our services. These cookies are governed by the third-party providers’ privacy policies.",
  },
  {
    id: "5",
    title: "Third-Party Cookies",
    content:
      "We may also use third-party cookies from service providers to help us analyse website usage and improve our services. These cookies are governed by the third-party providers’ privacy policies.",
  },
  {
    id: "5",
    title: "Changes to This Cookies Policy ",
    content: `We may update our Cookies Policy from time to time. We will notify you of any changes by posting the new Cookies Policy on this page. Please review this policy periodically for any updates.`,
  },
 
];
