import { Grid } from "@mui/material";
import React from "react";
import ContactUsForm from "./contact-us-form/contact-us-form";
import ContactUsHero from "./contact-us-hero/contact-us-hero";

const ContactUs = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xl={12} xs={12}>
        <ContactUsHero/>
      </Grid>
      <Grid item xl={12} xs={12}>
        <ContactUsForm/>
      </Grid>
    </Grid>
  );
};

export default ContactUs;
