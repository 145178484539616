import React from "react";
import {
  Grid,
  Typography,
  Card,
  CardContent,
  Button,
  Box,
} from "@mui/material";
import CircleImage from "../../assets/dollarprice.png";
import InstantFixed from "../../assets/instantfixedimage.png";
import RepairWarrantly from "../../assets/repairwarrantly.png";
import FullVetted from "../../assets/fullyvetted.png";
import { useNavigate } from 'react-router-dom';
import LayoutWrapper from "../lay-out/layout-wrapper";
const cardData = [
  {
    image: CircleImage,
    content: (
      <>
        Up to 50% cheaper
        <br /> than franchise
        <br />
        dealers.
      </>
    ),
  },
  {
    image: InstantFixed,
    content: (
      <>
        Instant fixed
        <br /> price quotes​
        <br />
      </>
    ),
  },
  {
    image: RepairWarrantly,
    content: (
      <>
        1 year parts & <br />
        repairs warranty
      </>
    ),
  },
  {
    image: FullVetted,
    content: (
      <>
        Fully vetted and <br /> qualified mechanics
        <br />
      </>
    ),
  },
];

const TravelHelpSquad = () => {
  const navigate = useNavigate(); 

  const handleClick = () => {
    navigate('/carservices'); 
  };
  return (
    <LayoutWrapper background='#EDF3F1'>
          <Typography
            variant="h1"
            sx={{
              color: "rgba(33, 176, 125, 1)",
              fontFamily: "Gilroy-Bold",
              fontSize: "40px",
              fontStyle: "normal",
              fontWeight: 600,
              textAlign: "center",
              marginTop: "105px",
              marginBottom: "105px",
            }}
          >
            Book your service now!
          </Typography>
          <Grid container spacing={3}>
            {cardData.map((card, index) => (
              <Grid item xs={12} sm={6} md={6} lg={3} xl={3} key={index} sx={{position: "relative",marginBottom:{xl:"0px", lg:"0px",md:"40px", sm:"40px", xs:"40px"},
              marginLeft:{xl:"0px", lg:"0px",md:"0px", sm:"0px", xs:"15px"}}}>
                <Card 
                  sx={{
                    borderRadius: "10px",
                    background: "#FFF",
                    boxShadow: "0px 14px 44px -5px rgba(4, 51, 34, 0.12)",
                    height: "100%",
                    display:'flex' ,
                    alignItems:"center" ,
                    justifyContent:'space-between', 
                    flexDirection:'column' ,
                    gap:"10px"
                  }}
                >
                  <Box  
                    sx={{
                      position: "absolute",
                      top: "-14px",
                      left: "10",
                      zIndex: "1",
                    }}
                  >
                    <Box
                      sx={{
                        width: "72px",
                        height: "72px",
                        borderRadius: "50%",
                        backgroundColor: "#FFFFFF",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        overflow: "hidden",
                        color: "red",
                        // position: "absolute",
                        border: "2px solid #21AF7C",
                      }}
                    >
                      <img
                        src={card.image}
                        alt="Your Image"
                        style={{
                          maxWidth: "100%",
                          maxHeight: "100%",
                          borderRadius: "50%",
                        }}
                      />
                    </Box>
                  </Box>
                  <CardContent
                    sx={{
                      display: "flex",
                      justifyContent: {lg:"center",xs:"left"},
                      alignItems: {lg:"center",xs:"left"},
                      py: "58px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Gilroy-SemiBold",
                        color: "#3F4B47",
                        fontSize: "24px",
                        fontStyle: "normal",
                        fontWeight: 400,
                        textAlign: "center",
                      }}
                    >
                      {card.content}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
            <div style={{ marginLeft: "46%", marginTop: "5%" }}>
              <Button
                variant="contained"
                onClick={handleClick}
                sx={{
                  display: "flex",
                  padding: "0 16px",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                  background: "#21B07D",
                  width: "100%",
                  maxWidth: "206px",
                  height: "49px",
                  borderRadius: "6px",
                  alignItems: "center",
                  transition: "color 0.3s",
                  "&:hover": {
                    background: "#21B07D",
                  },
                  "@media (max-width: 600px)": {
                    maxWidth: "100%",
                    padding: "10px",
                  },
                }}
              >
                <Typography
                  sx={{
                    color: "#FFF",
                    textAlign: "center",
                    fontFamily: "Gilroy-SemiBold",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "normal",
                    letterSpacing: "0.8px",
                    textTransform: "capitalize",
                    "@media (max-width: 600px)": {
                      fontSize: "14px",
                    },
                  }}
                >
                  Know More
                </Typography>
              </Button>
            </div>
          </Grid>
    </LayoutWrapper>
  );
};

export default TravelHelpSquad;
