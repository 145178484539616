import React, { useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import LayoutWrapper from "../../lay-out/layout-wrapper";
import { faqsMockData } from "./faqs-mock-data";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

const FaqsContent = () => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <LayoutWrapper background="#EDF3F1">
      <Box flexGrow={1}>
        <Grid
          container
          sx={{
            p: { xl: "60px", lg: "60px", md: "60px", sm: "40px", xs: "30px" },
            borderRadius: "20px",
            background: "#EDF3F1",
            boxShadow: "0px 34px 74px -12px rgba(0, 0, 0, 0.16)",
            position: "relative",
            zIndex: 10,
            mt: -10,
          }}
        >
          {faqsMockData?.map((item) => {
            const isExpanded = expanded === item.id;
            return (
              <Grid xs={12} xl={12} key={item?.id} mb={2}>
                <Accordion
                  expanded={isExpanded}
                  onChange={handleChange(item.id)}
                  key={item?.id}
                  sx={{
                    m: 0,
                    padding: '18px',
                    borderRadius: "16px",
                    boxShadow: 'none',
                    '&.Mui-expanded': { margin: 0 },
                    '&:first-of-type, &:last-of-type': {
                      borderRadius: "16px",
                    },
                  }}
                >
                  <AccordionSummary
                    expandIcon={isExpanded ? <RemoveIcon /> : <AddIcon />}
                    aria-controls={`panel${item.id}-content`}
                    id={`panel${item.id}-header`}
                    sx={{
                      p: '0px 18px',
                      '& .MuiAccordionSummary-content': {
                        margin: '0 !important',
                        minHeight: 'unset',
                        gap: '16px',
                        fontSize: "16px"
                      },
                      '&.Mui-expanded': {
                        margin: 0,
                        minHeight: '48px',
                        fontWeight: 600,
                      },
                    }}
                  >
                    <Typography sx={styles.headingStyles} mb={1}>
                      {item?.title}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography sx={styles.descriptionStyle} mt={1}>
                      {item?.description}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            );
          })}
          <Typography sx={styles.descriptionStyle}>Feel free to reach out if you have any other questions or need further assistance! </Typography>
        </Grid>
      </Box>
    </LayoutWrapper>
  );
};

export default FaqsContent;

const styles = {
  headingStyles: {
    color: "#5C5F5E",
    fontFamily: "Gilroy-Bold",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    LineHeight: "30px",
  },
  descriptionStyle: {
    color: "#5C5F5E",
    fontFamily: "Gilroy-Medium",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    LineHeight: "30px",
  },
};
