import React from "react";
import TextField from "@mui/material/TextField";
import { Container, Grid, InputAdornment } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import searchicon from "../../../assets/icons/Search-icon.png";
import { Box } from "@mui/system";


const OfferSearchbar = ({className}) => {

  const [type, setType] = React.useState("");
  const [year, setYear] = React.useState("");
  const [openType, setOpenType] = React.useState(false);
  const [openYear, setOpenYear] = React.useState(false);

  const handleChangeType = (event) => {
    setType(event.target.value);
  };

  const handleCloseType = () => {
    setOpenType(false);
  };

  const handleOpenType = () => {
    setOpenType(true);
  };

  const handleChangeYear = (event) => {
    setYear(event.target.value);
  };

  const handleCloseYear = () => {
    setOpenYear(false);
  };

  const handleOpenYear = () => {
    setOpenYear(true);
  };

  return (
    <>
    <Container maxWidth="xl" >
      <Grid container >
    <Grid
    item
    xs={12}
    sx={{
      display: "flex",
      flexDirection: { lg: "row", xs: "column" },
      gap: "20px",
      alignItems: {
        xs: "center", 
        // lg: "flex-start", 
      },
      justifyContent: "center",
   
    }}

    className={className}
  >
  
  
   <TextField
            fullWidth
            sx={{
              border: "none",
              "& fieldset": { border: "none" },
            
              width: "100%",
              maxWidth: "716px",
              height: "60px",
              flexShrink: 0,
              borderRadius: "10px",
              background: "#FFF",
              boxShadow: "0px 4px 12px 0px rgba(0, 0, 0, 0.15)",
            }}
            placeholder="Search model or type"
            variant="outlined"
            id="field1"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img src={searchicon} alt="Search Icon" />
                </InputAdornment>
              ),
            }}
          />
       
            <FormControl
           sx={{
            maxWidth: "222px",
            width: "100%",
            height: "60px",
            flexShrink: 0,
            borderRadius: "10px",
            background: "#FFF",
            boxShadow: "0px 4px 12px 0px rgba(0, 0, 0, 0.15)",
            "& fieldset": { border: "none" },
            display: {lg:"flex",xs:"none"},
            justifyContent: "space-between",
          }}
          >
            <Select
              labelId="demo-controlled-open-select-label-type"
              id="demo-controlled-open-select-type"
              open={openType}
              onClose={handleCloseType}
              onOpen={handleOpenType}
              value={type}
              label="Type"
              onChange={handleChangeType}
              displayEmpty
              renderValue={(selected) => (
                <div
                  style={{
                    textAlign: "left",
                    color: "#273B4A",
                    fontFamily: "Montserrat",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "140%",
                  }}
                >
                  {selected || "Type"}
                </div>
              )}
            >
              <MenuItem value="" disabled>
                <span
                  style={{
                    color: "#273B4A",
                    fontFamily: "Montserrat",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "140%",
                    
                  }}
                >
                  Type
                </span>
              </MenuItem>
              <MenuItem value={"A"}>
                <span
                  style={{
                    color: "#273B4A",
                    fontFamily: "Montserrat",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "140%",
                  }}
                >
                  A
                </span>
              </MenuItem>
              <MenuItem value={"B"}>
                <span
                  style={{
                    color: "#273B4A",
                    fontFamily: "Montserrat",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "140%",
                  }}
                >
               B
                </span>
              </MenuItem>
              <MenuItem value={"C"}>
                <span
                  style={{
                    color: "#273B4A",
                    fontFamily: "Montserrat",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "140%",
                  }}
                >
                 C
                </span>
              </MenuItem>
            </Select>
          </FormControl>
          <FormControl
            sx={{
              maxWidth: "222px",
            width: "100%",
            height: "60px",
            flexShrink: 0,
            borderRadius: "10px",
            background: "#FFF",
            boxShadow: "0px 4px 12px 0px rgba(0, 0, 0, 0.15)",
            "& fieldset": { border: "none" },
            display: {lg:"flex",xs:"none"},
            justifyContent: "space-between",
            }}
          >
            <Select
              labelId="demo-controlled-open-select-label-year"
              id="demo-controlled-open-select-year"
              open={openYear}
              onClose={handleCloseYear}
              onOpen={handleOpenYear}
              value={year}
              onChange={handleChangeYear}
              displayEmpty
              renderValue={(selected) => (
                <div
                  style={{
                    textAlign: "left",
                    color: "#273B4A",
                    fontFamily: "Montserrat",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "140%",
                  }}
                >
                  {selected || "Year"}
                </div>
              )}
            >
              <MenuItem value="" disabled>
                <span
                  style={{
                    color: "#273B4A",
                    fontFamily: "Montserrat",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "140%",
                  }}
                >
                  Year
                </span>
              </MenuItem>
                <MenuItem value={2018}>
                  <span
                    style={{
                      color: "#273B4A",
                      fontFamily: "Montserrat",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "140%",
                    }}
                  >
                    2018
                  </span>
                </MenuItem>
                <MenuItem value={2019}>
                  <span
                    style={{
                      color: "#273B4A",
                      fontFamily: "Montserrat",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "140%",
                    }}
                  >
                    2019
                  </span>
                </MenuItem>
                <MenuItem value={2020}>
                  <span
                    style={{
                      color: "#273B4A",
                      fontFamily: "Montserrat",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "140%",
                    }}
                  >
                    2020
                  </span>
                </MenuItem>
              </Select>
            </FormControl>
        </Grid>

        </Grid>
       
        </Container></>
   
  );
};

export default OfferSearchbar;



