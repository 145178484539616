import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Avatar, Box, Card, Stack } from "@mui/material";
import "./SecondSection.css";
import LayoutWrapper from "../lay-out/layout-wrapper";
import sophieIcon from "../../assets/home/sophieAvtar.svg";
import codyIcon from "../../assets/home/codyAvtar.svg";
import marvinIcon from "../../assets/home/marvinAvtar.svg";

const DrivingTestSection = () => {
  return (
    <>
      <LayoutWrapper background="#EDF3F1">
        <Box flexGrow={1}>
        <Typography mb={1} sx={styles.testimonialsHeading}>
                Hear What Our Clients Say
              </Typography>
          <Grid container spacing={4}>
            <Grid item xl={6} lg={6} xs={12} sm={6} md={6}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="start"
                flexDirection="column"
                flexWrap="wrap"
                gap={2}
              >
                <Box>
                  <Typography mb={2} sx={styles.testimonialParagraph}>
                    Discover why our clients love working with us! Read their
                    testimonials to see how we've helped them achieve their
                    goals, making their journeys smoother and more efficient.
                  </Typography>
                </Box>
                <Card sx={styles.cardStyle}>
                  <Typography mb={4} sx={styles.testimonialCardText}>
                    “Travel Help Squad has revolutionised how I handle Penalty
                    Charge Notices. The platform is intuitive and efficient,
                    saving me time and stress. Highly recommend it!”
                  </Typography>
                  <Stack direction="row" spacing={2}>
                    <Avatar alt="Remy Sharp" src={sophieIcon} />
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="start"
                      flexDirection="column"
                      flexWrap="wrap"
                      gap={1}
                    >
                      <Typography sx={styles.testimonialCardHeading}>
                        Sophie Moore
                      </Typography>
                      <Typography sx={styles.testimonialCardSubHeading}>
                        Product Developer at Webflow
                      </Typography>
                    </Box>
                  </Stack>
                </Card>
              </Box>
            </Grid>
            <Grid item xl={6} lg={6} xs={12} sm={6} md={6}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="start"
                flexDirection="column"
                flexWrap="wrap"
                gap={4}
              >
                <Card sx={styles.cardStyle}>
                  <Typography mb={4} sx={styles.testimonialCardText}>
                    "Finding parking in the city used to be a nightmare. With
                    Travel Help Squad, I can reserve spots in advance and
                    navigate stress-free. It's a lifesaver!"
                  </Typography>
                  <Stack direction="row" spacing={2}>
                    <Avatar alt="Remy Sharp" src={codyIcon} />
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="start"
                      flexDirection="column"
                      flexWrap="wrap"
                      gap={1}
                    >
                      <Typography sx={styles.testimonialCardHeading}>
                        Cody Fisher
                      </Typography>
                      <Typography sx={styles.testimonialCardSubHeading}>
                        Interior Designer
                      </Typography>
                    </Box>
                  </Stack>
                </Card>
                <Card sx={styles.cardStyle}>
                  <Typography mb={4} sx={styles.testimonialCardText}>
                    "Support team at Travel Help Squad is exceptional. They are
                    always ready to assist and resolve issues promptly. Their
                    dedication to customer satisfaction is impressive."
                  </Typography>
                  <Stack direction="row" spacing={2}>
                    <Avatar alt="Remy Sharp" src={marvinIcon} />
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="start"
                      flexDirection="column"
                      flexWrap="wrap"
                      gap={1}
                    >
                      <Typography sx={styles.testimonialCardHeading}>
                        Marvin McKinney
                      </Typography>
                      <Typography sx={styles.testimonialCardSubHeading}>
                        Data Engineer
                      </Typography>
                    </Box>
                  </Stack>
                </Card>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </LayoutWrapper>
    </>
  );
};

export default DrivingTestSection;

const styles = {
  testimonialsHeading: {
    color: "#21B07D",
    fontFamily: "Gilroy-Bold",
    fontSize: "40px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "49.52px",
  },
  testimonialParagraph: {
    color: "#5C5F5E",
    fontFamily: "Gilroy-Medium",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "32px",
  },
  testimonialCardText: {
    color: "#5C5F5E",
    fontFamily: "Gilroy-Medium",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "30px",
  },
  testimonialCardHeading: {
    color: "#3B4A45",
    fontFamily: "Gilroy-Bold",
    fontSize: "22px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "27px",
  },
  testimonialCardSubHeading: {
    color: "#5C5F5E",
    fontFamily: "Gilroy-Medium",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "30px",
  },
  cardStyle: {
    borderRadius: "20px",
    border: "1px solid #EFF0F6",
    padding: "40px",
  },
};
