import React from "react";
import { Container, Grid } from "@mui/material";
import BlogImage from "../../../assets/pricingblog.png";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";



const CookiesPolicyHero = () => {
  return (
    <>
    <div
      style={{
        background: `url(${BlogImage})`,
        backgroundRepeat: "no-repeat",
        width: "100%",
        minHeight: "626px",
        position: "relative",
        zIndex: 2,
        backgroundSize: "cover",
      }}
    >
      <div
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          backgroundRepeat: "no-repeat",
          mixBlendMode: "multiply",
          left: 0,
          top: 0,
        }}
      ></div>
      <Container maxWidth="xl">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "Center",
                justifyContent: "center",
                marginTop: {xl:"201px",lg:"201px",md:"207px",sm:"180px",xs:"190px"},
              }}
            >
              <Typography
                sx={{
                  color: "#FFFFFF",
                  fontFamily: "Gilroy-Bold",
                  fontSize: "56px",
                  fontStyle: "normal",
                  fontWeight: 700,
                  zIndex: 1,
                }}
              >
               Cookies Policy 
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  </>
  );
};

export default CookiesPolicyHero;
