import React from "react";
import { Grid, Typography, } from "@mui/material";
import CarModel from "../../assets/carmodel.png";
import LayoutWrapper from "../lay-out/layout-wrapper";
import { Box } from "@mui/system";

const CardInsurance = () => {
 

  const overlayTextStyledes = {
    zIndex: 1,
    
  };

 

  return (
    <Grid mt={15} mb={5}
      container
      spacing={2}
    >
      <Grid item xs={12}>
          <Box
            sx={{
              background: `url(${CarModel})`,
              width: "100%",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              minHeight: "1000px",
              display: "flex",
              justifyContent: "center",
              alignItems: "end",
              opacity:1
              
            }}
          >
            <Grid container sx={{borderTop:"none" ,background: "linear-gradient(rgba(0, 0, 0, 0.06) 10.81%, #000 106.26%)"}}>
            <LayoutWrapper>
              <Grid item xs={12} xl={12}>
                <Typography
                  variant="h1"
                  sx={{
                    fontFamily: "Gilroy-Bold",
                    fontSize: "40px",
                    color: "#FFF",
                    textAlign: "left",
                    fontWeight: 400,
                    lineHeight: "46.88px",
                  }}
                >
                  Explore various car insurance quotes
                  <br /> with Travel Help Squad and discover
                  <br /> potential savings
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  ...overlayTextStyledes,
                }}
              >
              <Box sx={{width:"68%"}}>
                <Typography mb={3}
                  variant="body1"
                  sx={{
                    fontFamily: "Gilroy-Medium",
                    fontSize: { lg: "16px", md: "14px", sm: "12px", xs: "9px" },
                    textAlign: { lg: "left", xs: "center" },
                    p: {
                      xl: "50px",
                      lg: "50px",
                      md: "10px",
                      sm: "6px",
                      xs: "2px",
                    },
                    lineHeight: { lg: "32px" },
                    color: "#FFFFFFB2",
                    fontWeight: "500",
                  }}
                >
                  Travel Help Squad doesn't just make your journey easier, it
                  also helps protect you on the road. We help you find cheap
                  insurance, you can have peace of mind every time you drive.​
                  <span
                    style={{
                      color: "#21B07D",
                    }}
                  >
                    &nbsp;Travel Help Squad's Insurance feature&nbsp;
                  </span>
                  ensures that you're covered, no matter where your journey
                  takes you. Drive confidently – Register today!
                </Typography>
                </Box>
              </Grid>
              </LayoutWrapper>
            </Grid>
          </Box>
       
      </Grid>
    </Grid>
  );
};

export default CardInsurance;
