import React from "react";
import { Grid, Box, Container } from "@mui/material";
import sol_hero_bg_img from "../../../../assets/solutions/solbg.png";
import { Typography } from "@mui/material";
import './personal-hero.css'


// import solutionSearchbar from "../solution-searchbar/solution-searchbar";
const dotStyle = {
  color: "#21B07D",
  position: "relative",
  left: "2px",
};
const PersonalHero = () => {
  return (
    <>
      <div
        style={{
          background: `url(${sol_hero_bg_img})`,
          backgroundRepeat: "no-repeat",
          // minHeight: "590px",
          width:"100%",
          minHeight:"590px",
          position: "relative",
          zIndex: 2,
          backgroundSize: "cover",
        }}
      >
        <div
          style={{
            position: "absolute",
            width: "100%",
            // minHeight: "489px",
            height:"100%",
            backgroundRepeat:"no-repeat",
            // background: "linear-gradient(262deg, rgba(0, 0, 0, 0.6) 13.81%, #000 106.26%)",
            // background: " linear-gradient(262deg, rgba(0, 0, 0, 0.89), rgba(0, 0, 0, 0.89))",

            // background: "linear-gradient(180deg, rgba(7, 16, 31, 0.00) 13.65%, #293D54 93.29%)",
            // mixBlendMode: "multiply",
            background: "linear-gradient(180deg, rgba(7, 16, 31, 1.9) 13.65%, #293D54 93.29%), url(<path-to-image>) lightgray -194.333px 0px / 126.991% 109.153% no-repeat",
             mixBlendMode: "multiply",

  
            left:0,
            top:0,
          }}
        ></div>

        <Container  maxWidth="xl">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "Center",
                  justifyContent: "center",
                  // width: '100%',
                  mt: '201px',
                  // paddingLeft:'24px',
                }}
              >
                <Typography
                  sx={{
                    color: "#FFFFFF",
                    fontFamily: "Gilroy-Bold",
                    fontSize: "56px",
                    fontStyle: "normal",
                    fontWeight: 700,
                    zIndex: 1,
                  }}
                >
                 Solutions<span style={dotStyle}>.</span>
                </Typography>
                <Typography
                  sx={{
                    color: "rgba(255, 255, 255, 0.70)",
                    fontFamily: "Gilroy-Medium",
                    fontSize: "20px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "32px",
                    zIndex: 1,
                    mt: 2,
                    alignItems:'center',
                    textAlign:'center'
                  }}
                >
                  Unlocking Success: Our Custom Solutions for Your Every Need.
                </Typography>
              </Box>
            </Grid>
         
          </Grid>
        </Container>
      </div>
    </>
  );
};

export default PersonalHero;
