import { Box } from '@mui/material'
import React from 'react'
import AboutUsContent from './about-us-content/about-us-content'
import AboutUsHero from './about-us-hero/about-us-hero'

const AboutUs = () => {
  return (
    <Box>
      <AboutUsHero/>
      <AboutUsContent/>
    </Box>
  )
}

export default AboutUs