import { Box } from '@mui/material'
import React from 'react'
import TermsAndConditionHero from './terms-and-condition-hero/terms-and-conditions-hero'
import TermsAndConditionContent from './terms-and-condition-content/terms-and-condition-content'

const TermsAndConditions = () => {
  return (
    <Box>
      <TermsAndConditionHero/>
      <TermsAndConditionContent/>
    </Box>
  )
}

export default TermsAndConditions