import BlogsSection from "../CarServices/carservices";
import PicsBlog from "../CarServices/Picsblogsection";


function HomeCarServices() {
  return (
    <div>
      <BlogsSection />
      <PicsBlog/>
   
    </div>
  );
}

export default HomeCarServices;
