import React from 'react'
import ChargingStationsHero from './charging-stations-hero/charging-stations-hero'
import ChargingStationsCard from './charging-station.card/charging-station.card'

const ChargingStations = () => {
  return (
    <>
    
   <ChargingStationsHero/>
   <ChargingStationsCard/>
    </>
  )
}

export default ChargingStations 
