import React from "react";
import { Container, Grid, Paper } from "@mui/material";
import backgroundImage from "../../assets/groupsection.png";
import { Box, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const overlayStyle = {
  position: "absolute",
  top: "222px",
  paddingLeft: "24px",
  zIndex: 1,
};

const dotStyle = {
  color: "#21B07D",
  position: "relative",
  left: "2px",
};

const SecondSection = () => {
  const navigate = useNavigate()
  const handleSignUpClick = () => {
    navigate("/sign-up");
  };
  const handleLoginClick = () => {
    navigate("/sign-in");
  };
  
  return (
    <Paper
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        minHeight: "842px",
        position: "relative",
        // opacity: "0.9"
      }}
    >
      <Paper
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          mixBlendMode: "multiply",
          opacity: 0.1,
        }}
      />
      <Container maxWidth="xl">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Box sx={overlayStyle}>
              <Typography
                sx={{
                  color: "#FFFFFF",
                  fontFamily: "Gilroy-Bold",
                  fontSize: "56px",
                  fontStyle: "normal",
                  fontWeight: 700,
                  textAlign: "left",
                  "@media (max-width: 600px)": {
                    textAlign: "center",
                    justifyContent: "center",
                    fontSize: "36px",
                  },
                }}
              >
                Streamline Your Journey
              </Typography>
              <Typography
                sx={{
                  color: "#FFFFFF",
                  fontFamily: "Gilroy-Bold",
                  fontSize: "56px",
                  fontStyle: "normal",
                  fontWeight: 700,
                  textAlign: "left",
                  "@media (max-width: 600px)": {
                    textAlign: "center",
                    justifyContent: "center",
                    fontSize: "36px",
                  },
                }}
              >
                with Travel Help Squad
              </Typography>
              <br />
              <Typography
                sx={{
                  color: "rgba(255, 255, 255, 0.70)",
                  fontFamily: "Gilroy-Medium",
                  fontSize: "20px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  textAlign: { lg: "left", xs: "center" },
                  lineHeight: "32px",
                  "@media (max-width: 600px)": {
                    textAlign: "center",
                    justifyContent: "center",
                  },
                }}
              >
               A digital solution for seamless vehicle management
                <br />  through one single platform 
              </Typography>
              <br />
              <Box sx={{ p: { sm: 0, xs: 2 }, mt: "30px" }} display="flex" alignItems="center" flexWrap="wrap" gap={4}>
                <Button
                  variant="contained"
                  onClick={handleSignUpClick}
                  sx={{
                    display: "flex",
                    padding: "0 16px",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                    background: "#21B07D",
                    width: "100%",
                    maxWidth: "206px",
                    height: "49px",
                    borderRadius: "6px",
                    transition: "color 0.3s",
                    textTransform:"capitalize !important",
                    "&:hover": {
                      background: "#21B07D",
                    },
                    "@media (max-width: 600px)": {
                      maxWidth: "100%",
                      padding: "10px",
                    },
                  }}
                >
                  <Typography
                    sx={{
                      color: "#FFF",
                      textAlign: "center",
                      fontFamily: "Gilroy-SemiBold",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "normal",
                      textTransform:"capitalize !important",
                      letterSpacing: "0.8px",
                      "@media (max-width: 600px)": {
                        fontSize: "14px",
                      },
                    }}
                  >
                    Register
                  </Typography>
                </Button>
                <Button
                  variant="contained"
                  onClick={handleLoginClick}
                  sx={{
                    display: "flex",
                    padding: "0 16px",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                    background: "#21B07D",
                    width: "100%",
                    maxWidth: "206px",
                    height: "49px",
                    borderRadius: "6px",
                    transition: "color 0.3s",
                    textTransform:"capitalize !important",
                    "&:hover": {
                      background: "#21B07D",
                    },
                    "@media (max-width: 600px)": {
                      maxWidth: "100%",
                      padding: "10px",
                    },
                  }}
                >
                  <Typography
                    sx={{
                      color: "#FFF",
                      textAlign: "center",
                      fontFamily: "Gilroy-SemiBold",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "normal",
                      textTransform:"capitalize !important",
                      letterSpacing: "0.8px",
                      "@media (max-width: 600px)": {
                        fontSize: "14px",
                      },
                    }}
                  >
                    Login
                  </Typography>
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Paper>
  );
};

export default SecondSection;
