import React from "react";
import { Box, Container, Grid } from "@mui/material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import law from "../../../assets/offers/lawyeres.png";
import car from "../../../assets/offers/car.png";
import keyboard from "../../../assets/offers/keyboard.png";
import moto from "../../../assets/offers/moto.png";
import ganja from "../../../assets/offers/ganja.png";

import salvage from "../../../assets/offers/salvage.png";
import finace from "../../../assets/offers/finace.png";
import fleet from "../../../assets/offers/fleet.png";

import buycar from "../../../assets/offers/buycar.png";
import register from "../../../assets/offers/register.png";
import rent from "../../../assets/offers/rent.png";
import test from "../../../assets/offers/test.png";
import { useNavigate } from "react-router-dom";
import LayoutWrapper from "../../lay-out/layout-wrapper";

const cardData = [
  {
    title: "Lawyers",
    description:
      "Legal Expertise, a Tap Away with Travel Help Squad's Lawyer Feature",
    content:
      "Empower yourself with legal assistance at your fingertips. Register now and have peace of mind with every legal matter.",
    img: law,
    number: "01",
    path: "/lawyer",
  },
  {
    title: "Charging Stations",
    description:
      "Discover the Perfect Ride with Travel Help Squad's Motor Dealers Feature",
    content:
      "Travel Help Squad's helps to locate the nearest Charging Stations to have an uninterrupted and eco-conscious travel. Register now and stay charged up wherever you go!",

    img: car,
    number: "02",
    path: "/charging-stations",
  },

  {
    title: "Insurance",
    description:
      "We help you find cheap insurance, you can have peace of mind every time you drive",
    content:
      "Travel Help Squad's Insurance feature ensures that you're covered, no matter where your journey takes you. Drive confidently – Register today!",

    img: keyboard,
    number: "03",
    path: "/Insurance",
  },
  {
    title: "Motor Dealers",
    description:
      "Discover the Perfect Ride with Travel Help Squad's Motor Dealers Feature",
    content:
      "Travel Help Squad's puts you in the driver's seat. Get started and discover your next car today!",

    img: moto,
    number: "04",
    path: "/motor-dealer",
  },
  {
    title: "Motor Auctioneers",
    description:
      "Rev Up Your Savings with Travel Help Squad's Motor Auctioneers Feature",
    content:
      "Don't miss out on incredible opportunities. Register now with Travel Help Squad and start bidding your way to automotive savings!",

    img: ganja,
    number: "05",
    path: "/motor-auctioneers",
  },

  {
    title: "Salvage Dealers",
    description:
      "Rebuild Your Ride with Travel Help Squad's Salvage Dealers Feature",
    content:
      "Uncover hidden treasures and start your restoration journey today. Register now with Travel Help Squad and dive into the world of salvage vehicles!",

    img: salvage,
    number: "06",
    path: "/salvage-dealers",
  },
  {
    title: "Finance Companies",
    description:
      "Financial Freedom, Your Way with Travel Help Squad's Finance Feature",
    content:
      "Unlock the power of financial flexibility. Register now with Travel Help Squad today and take control of your automotive aspirations!",

    img: finace,
    number: "07",
    path: "/finance-companies",
  },
  {
    title: "Fleet Operators",
    description:
      "Efficiency at your Fingertips with Travel Help Squad's Fleet Operations Feature",
    content:
      "Take charge of your fleet's success. Register with Travel Help Squad today and experience seamless fleet management like never before!",

    img: fleet,
    number: "08",
    path: "/fleet-operators",
  },
  {
    title: "Car  Services",
    description: "Simplify Your Car Services with Travel Help Squad",
    content:
      "Start Your Journey, Let Travel Help Squad lead you to your dream ride today!",

    img: buycar,
    number: "09",
    path: "/car-services",
  },
  {
    title: "Register Charging Stations",
    description: "Empower EV Owners with Travel Help Squad",
    content:
      "Empower yourself with legal assistance at your fingertips. Register now and have peace of mind with every legal matter.",
    img: register,
    number: "10",
    path: "/register-charging-stations",
  },
  {
    title: "Rent Your Space",
    description: "Unlock Earnings by Renting Your Space with Ease",
    content:
      "Turn your space into a valuable asset. Get started with Travel Help Squad today!",

    img: rent,
    number: "11",
    path: "/rent-your-space",
  },
  {
    title: "Driving Test Kit",
    description: "Drive to Success with Travel Help Squad",
    img: test,
    content: "Your driver's license is just a step away with Travel Help Squad",

    number: "12",
    path: "/driving-test-kit",
  },
];

const OffersCards = () => {
  const navigate = useNavigate();

  const handleViewOfferCard = (path) => {
    navigate(`/offer${path}`);
  };

  return (
    <LayoutWrapper background="#EDF3F1">
      <Grid container spacing={4} gutter={2} mt={10}>
        {cardData.map((card, index) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={4}
            key={index}
            sx={{
              mt: 2,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              pb: 2,
            }}
          >
            <Card
              sx={{
                width: "100%",
                height: "100%",
                borderRadius: "20px",
                background: "#FFF",
                boxShadow: "0px 44px 54px -20px rgba(39, 44, 42, 0.12)",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "18px",
              }}
            >
              <Box sx={{ position: "relative", width: "100%",}}>
                <Box sx={{ borderRadius: "20px" }}>
                  <Box>
                    <img
                      src={card.img}
                      alt="cardImg"
                      style={{
                        width: "100%",
                        height: "100%",
                        display: "block",
                        borderRadius: "20px",
                        "&:hover": {
                          opacity: 1,
                          borderRadius: "20px",
                        },
                      }}
                    />
                  </Box>
                </Box>
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: 400,
                    zIndex: 1,
                    position: "absolute",
                    bottom: "8px",
                    left: "16px",
                    color: "rgba(255, 255, 255, 0.80)",
                    fontSize: "36px",
                    fontFamily: "Gilroy-Regular",
                  }}
                >
                  {card.number}
                </Typography>
                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    height: " 100%",
                    width: " 100%",
                    opacity: 0,
                    backgroundColor: "rgba(0,0,0,.5)",
                    "&:hover": {
                      opacity: 1,
                      borderRadius: "20px",
                    },
                  }}
                >
                  <Box
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: " translate(-50%, -50%)",
                      msTransform: "translate(-50%, -50%)",
                      textAlign: "center",
                      width: "85%",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#EEF3F1",
                        fontFamily: "Gilroy-Medium",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: 400,
                        textAlign: "center",
                      }}
                    >
                      {card.content}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              {/* <Box
                    style={{
                      position: "relative",
                      width: "100%",
                      maxWidth: "341px",
                      borderRadius: "20px",
                      margin: "16px",
                    }}
                  >
                    <CardMedia
                      component="img"
                      sx={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        borderRadius: "20px",
                      }}
                      image={card.img}
                      title="green iguana"
                    />
                    <div
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        background:
                          "linear-gradient(15deg, rgba(0, 0, 0, 0.91) 13.26%, rgba(0, 0, 0, 0.00) 59.95%)",
                        borderRadius: "20px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: 400,
                          zIndex: 1,
                          position: "absolute",
                          bottom: "8px",
                          left: "16px",
                          color: "rgba(255, 255, 255, 0.80)",
                          fontSize: "36px",
                          fontFamily: "Gilroy-Regular",
                        }}
                      >
                        {card.number}
                      </Typography>
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        backgroundColor: "#044F34B2",
                        borderRadius: "20px",
                        opacity: 0,
                        transition: "opacity 0.3s",
                      }}
                      onMouseEnter={(e) => (e.target.style.opacity = "1")}
                      onMouseLeave={(e) => (e.target.style.opacity = "0")}
                    >
                      <Typography
                        sx={{
                          color: "#EEF3F1",
                          fontFamily: "Gilroy-Medium",
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: 400,
                          textAlign:"center",
                          py:"60px"
                        }}
                      >
                        {card.content}
                      </Typography>
                    </div>
                  </Box> */}
              <CardContent sx={{ textAlign: "center", flex: "1  auto" }}>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  sx={{
                    color: "#3B4A45",
                    fontFamily: "Gilroy-Regular",
                    fontSize: "24px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "normal",
                  }}
                >
                  {card.title}
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{
                    color: "#9FAEBF",
                    fontFamily: "Gilroy-Medium",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "32px",
                  }}
                >
                  {card.description}
                </Typography>
              </CardContent>
              <CardActions  sx={{ justifyContent: "center" }}>
                <Button
                  size="small"
                  sx={{
                    display: "inline-flex",
                    padding: "16px 32px",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "6px",
                    background: "#21B07D",
                    color: "#ffffff",
                    transition: "background-color 0.3s",
                    textTransform: "capitalize ",
                    marginBottom: "16px",
                    "&:hover": {
                      backgroundColor: "#1A8B60",
                    },
                  }}
                  onClick={() => handleViewOfferCard(card.path)}
                >
                  Enquire Now
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </LayoutWrapper>
  );
};

export default OffersCards;
