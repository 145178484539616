import React from 'react'
import CareerHero from './career-hero/career-hero'
import CareerFrame from './career-frame/career-frame'

const Career = () => {
  return (
  <>
  
  <CareerHero/>
  <CareerFrame/>
  </>
  )
}

export default Career
