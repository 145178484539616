import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { privacyPolicyMockData } from "./privacy-policy-mock-data";
import LayoutWrapper from "../../lay-out/layout-wrapper";
import { Link } from 'react-router-dom';


const PrivacyPolicyContent = () => {
  return (
    <LayoutWrapper background="#EDF3F1">
      <Box flexGrow={1} sx={{ width: "90%", margin:"0 auto"}}>
       <Grid
          container
          sx={{
            p: {xl:"60px",lg:"60px",md:"60px",sm:"40px",xs:"30px"},
            borderRadius: "20px",
            background: "#EDF3F1",
            boxShadow: "0px 34px 74px -12px rgba(0, 0, 0, 0.16)",
            position: "relative",
            zIndex: 10,
            mt: -10,
          }}
        >
        {privacyPolicyMockData?.map((item) => {
          return (
            <Grid xs={12} xl={12} key={item?.id} sx={styles.contentWrap}>
              <Typography sx={styles.headingStyles} mb={1}>{item?.title}</Typography>
              <Typography sx={styles.descriptionStyle} mt={1}>{item?.content}</Typography>
              <ul>
                {item?.list?.map((item, index) => {
                  return <li key={index} style={{...styles.descriptionStyle, marginBottom:"16px"}}>{item}</li>
                })}
              </ul>
            </Grid>
          )
        })}
        <Box>
        <Typography sx={styles.descriptionStyle}>If you have any questions about this Privacy Policy, please contact us:</Typography>
          <ul>
          <li style={{...styles.descriptionStyle, marginBottom:"10px"}}>By email:<Link to="mailto:Info@travelhelpsquad.co.uk" style={{color:"#147CC8"}}>Info@travelhelpsquad.co.uk</Link> </li>
          <li style={{...styles.descriptionStyle, marginBottom:"10px"}}>By visiting this page on our website: <Link to="/www.travelhelpsquad.co.uk/contact-us"  style={{color:"#147CC8"}}>www.travelhelpsquad.co.uk/contact-us</Link></li>
          <li style={{...styles.descriptionStyle, marginBottom:"10px"}}>By mail: Pay IO Services LTD, International House, 36-38 Cornhill, London, England, EC3V 3NG </li>
          </ul>
        </Box>
        </Grid>
      </Box>
    </LayoutWrapper>
  );
};

export default PrivacyPolicyContent;

const styles = {
  contentWrap: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "start",
    flexDirection: "column",
    flexWrap: "wrap",
  },
  headingStyles: {
    color: "#5C5F5E",
    fontFamily: "Gilroy-Bold",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    LineHeight: "30px",
  },
  descriptionStyle: {
    color: "#5C5F5E",
    fontFamily: "Gilroy-Medium",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    LineHeight: "30px",
   
  },
  
};
