import { Container, Grid, Typography, Card, CardContent, CardMedia, Box } from '@mui/material';
import React from 'react';
import solbtn from "../../../../assets/solutions/solbtn.png";
import solplate from "../../../../assets/solutions/solplate.png";
import solcard from "../../../../assets/solutions/solcard.png";
import notifymail from "../../../../assets/solutions/notifymail.png";
import car from "../../../../assets/solutions/simple.png";
import simple from "../../../../assets/solutions/solcard.png";
import mobile from "../../../../assets/solutions/mobile.png";
import yellowcar from "../../../../assets/solutions/yellowcar.png";
import criditmach from "../../../../assets/solutions/criditmach.png";
import steel from "../../../../assets/solutions/steel.png";
import mot from "../../../../assets/solutions/mot.png";
import gdpr from "../../../../assets/solutions/gdpr.png";

const personalcardData = [
  {
    description: "Ultimate car insights in one place.",
    solimg: solbtn,
  },
  {
    description: "Personalised dashboard​",
    solimg: solplate,
  },
  {
    description: "Easy and guided Appeal processing​",
    solimg: solcard,
   
  },
  {
    description: "Notifications and Reminders on the move​",
    solimg: notifymail,
  },
  {
    description: "Effortless management of parking and traffic tickets.",
    solimg: car,
   
  },
  {
    description: "Extremely easy to understand, use and navigate​",
    solimg: simple,
   
  },
  {
    description: "In-Built User Guides for Every process​",
    solimg: mobile,
  },
  {
    description: "Find and Book Parking in seconds",
    solimg: yellowcar,
   
  },
  {
    description: "Pay to park in just a few clicks.​",
    solimg: criditmach,
   
  },
  {
    description: "Know about your car in seconds​",
    solimg: steel,
   
  },
  {
    description: "Find Nearest MOT centers and more",
    solimg: mot,
  },
  {
    description: "GDPR Compliant",
    solimg: gdpr,
   
  },
  
];

const PersonalCard = () => {
  return (
    <Container maxWidth="xl">
      <Grid container sx={{ p: "60px" ,borderRadius: '20px',
  background: '#EDF3F1',
  boxShadow: '0px 34px 74px -12px rgba(0, 0, 0, 0.16)'
  ,position:'relative',
  zIndex:10,
  mt:-10
  }}>
        <Grid item xs={12}>
          <Typography sx={{ color: '#21B070', fontFamily: 'Gilroy-SemiBold', fontSize: "24px", fontWeight: 400,mb:"36px" }}>
            Personal Account
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {/* Card Section */}
          <Grid container spacing={3}>
            {personalcardData.map((item, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Card sx={{display:'flex',flexDirection:'column',justifyContent:'center',borderRadius:"20px",background:"#fff",}}>
                    <Box sx={{p:"16px"}}>

                   
                  <CardMedia
                    component="img"
                    // height="140"
                    image={item.solimg}
                    alt="Card Image"
                         
                  /> </Box>
                  <CardContent>
                    <Typography sx={{textAlign:'center',color:'#5C5F5E',fontSize:"18px",fontWeight:400,fontFamily: 'Gilroy-SemiBold'}}>
                      {item.description}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}

export default PersonalCard;
