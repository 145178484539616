import CarServiceContent from "./car-service-content/car-service-content";
import CarServicesHero from "./car-service-hero/car-service-hero";


function HomeCarServices() {
  return (
    <div>
      <CarServicesHero />
      <CarServiceContent/>
   
    </div>
  );
}

export default HomeCarServices;
