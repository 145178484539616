import React from 'react'
import MotorDealersHero from './motor-dealers-hero/motor-dealers-hero'
import MotorDealersCards from './motor-dealers-cards/motor-dealers-cards'

export default function MotorDealers() {
  return (
<>
<MotorDealersHero/>
<MotorDealersCards/></>
  )
}
