import React from 'react'
import MotorAuctioneersHero from './motor-auctioneers-hero/motor-auctioneers-hero'
import MotorAuctioneersCard from './motor-auctioneers-card/motor-auctioneers-card'

const MotorAuctioneers = () => {
  return (
    <div>
      <>
      <MotorAuctioneersHero/>
      <MotorAuctioneersCard/>
      </>
    </div>
  )
}

export default MotorAuctioneers
