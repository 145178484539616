import {
  Container,
  Grid,
  Typography,
  CardContent,
  CardMedia,
  Box,
  MenuItem,
  Select,
  FormControl,
  TextField,
  InputAdornment,
  CardHeader,
  Button,
} from "@mui/material";
import * as React from "react";
import { styled } from "@mui/material/styles";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import { red } from "@mui/material/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Card from "@mui/material/Card";
import RegisterCompanyImage from "../../assets/registerchargingstation.png";

const ContactFormHome = () => {
  const data = [
    {
      image: RegisterCompanyImage,
      content: (
        <div>
          <ul style={{ paddingTop: "8px", listStyleType: "none" }}>
            Empower EV Owners with Travel help squad​​{" "}
            <li style={{ paddingTop: "8px" }}>
              <span
                style={{
                  color: "#5C5F5E",
                  fontFamily: "Gilroy-Bold",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 400,
                }}
              >
                Expand EV Infrastructure:{" "}
              </span>{" "}
              Add your charging station to our network and contribute to a
              greener future & earn more.
            </li>
            <li style={{ paddingTop: "8px" }}>
              <span
                style={{
                  color: "#5C5F5E",
                  fontFamily: "Gilroy-Bold",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 400,
                }}
              >
                ​Attract EV Drivers:{" "}
              </span>{" "}
              Connect with environmentally-conscious customers who rely on your
              charging services
            </li>
            <li style={{ paddingTop: "8px" }}>
              <span
                style={{
                  color: "#5C5F5E",
                  fontFamily: "Gilroy-Bold",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 400,
                }}
              >
                ​Business Growth:{" "}
              </span>
              Boost your revenue and visibility by becoming part of the EV
              charging ecosystem.
            </li>
            <li style={{ paddingTop: "8px" }}>
              <span
                style={{
                  color: "#5C5F5E",
                  fontFamily: "Gilroy-Bold",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 400,
                }}
              >
                ​Seamless Integration:{" "}
              </span>
              Registering your charging station is simple and quick, with
              user-friendly tools at your disposal.
            </li>
            ​Join us in revolutionising the electric vehicle experience. Register your charging station now!​
          </ul>{" "}
        </div>
      ),
    },
  ];

  return (
    <>
      <Container maxWidth="xl">
        <Grid
          container
          sx={{
            p: { sm: "15px", xs: "0px" },
            borderRadius: "20px",
            background: "#EDF3F1",
            boxShadow: "0px 34px 74px -12px rgba(0, 0, 0, 0.16)",
            position: "relative",
            zIndex: 10,
            mt: -10,
          }}
        >
          <Grid item xs={12} lg={12}>
            {data.map((item, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "left",
                  p: { sm: 0, xs: 2 },
                  width: "100%",
                }}
              >
                <img src={item.image} alt={`Image ${index}`} width="100%" />
                <Typography
                  sx={{
                    fontFamily: "Gilroy-Medium",
                    fontSize: "16px",
                    fontWeight: 400,
                    lineHeight: "30px",
                    textAlign: "left",
                    color: "#5C5F5E",
                    marginTop: "10px",
                  }}
                >
                  {item.content}
                </Typography>
              </Box>
            ))}
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default ContactFormHome;
