import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

const LayoutWrapper = ({ children, background }) => {
    return (
        <Box sx={{ background:background, padding: { xs: "0px 16px", sm: "0px 33px", md: "0 66px", lg: "0px 80px", xl: "0px 200px" } }}>
            {children}
        </Box>
    );
};

LayoutWrapper.propTypes = {
    children: PropTypes.node.isRequired,
    background: PropTypes.string
};

LayoutWrapper.defaultProps = {
    background: 'transparent' 
};

export default LayoutWrapper;
