import {
  Container,
  Grid,
  Typography,
  CardContent,
  CardMedia,
  Box,
  MenuItem,
  Select,
  FormControl,
  TextField,
  InputAdornment,
  CardHeader,
  Button,
} from "@mui/material";
import * as React from "react";
import { styled } from "@mui/material/styles";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import { red } from "@mui/material/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Card from "@mui/material/Card";
import DriverCompanyImage from "../../../../../assets/drivingtestkit.png";
import { useNavigate } from "react-router-dom";

const RentYourSpace = () => {
  const data = [
    {
      image: DriverCompanyImage,
      content: (
        <div>
          <ul style={{ paddingTop: "8px", listStyleType: "none" }}>
            Drive to Success with Travel Help Squad​​{" "}
            <li style={{ paddingTop: "8px" }}>
              <span
                style={{
                  color: "#5C5F5E",
                  fontFamily: "Gilroy-Bold",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 400,
                }}
              >
                Comprehensive Prep:{" "}
              </span>{" "}
              Master the road rules and nail your driving test with ease.{" "}
            </li>
            <li style={{ paddingTop: "8px" }}>
              <span
                style={{
                  color: "#5C5F5E",
                  fontFamily: "Gilroy-Bold",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 400,
                }}
              >
                Interactive Learning:{" "}
              </span>{" "}
              Access engaging lessons, quizzes, and mock exams at your
              fingertips.​{" "}
            </li>
            <li style={{ paddingTop: "8px" }}>
              <span
                style={{
                  color: "#5C5F5E",
                  fontFamily: "Gilroy-Bold",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 400,
                }}
              >
                ​Expert Guidance:{" "}
              </span>
              Learn from experienced instructors and boost your confidence
              behind the wheel.
            </li>
            ​Your driver's license is just a step away with Travel Help Squad​
          </ul>{" "}
        </div>
      ),
    },
  ];
  const navigate = useNavigate()
  const handleClick = () => {
    navigate("/sign-up");
  };
  return (
    <>
      <Container maxWidth="xl">
        <Grid
          container
          sx={{
            p: { sm: "15px", xs: "0px" },
            borderRadius: "20px",
            background: "#EDF3F1",
            boxShadow: "0px 34px 74px -12px rgba(0, 0, 0, 0.16)",
            position: "relative",
            zIndex: 10,
            mt: -10,
          }}
        >
          <Grid item xs={12} lg={12}>
            {data.map((item, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  alignItems: "start",
                  flexDirection: "column",
                  alignItems: "left",
                  p: { sm: 0, xs: 2 },
                  width: "100%",
                }}
              >
                <img src={item.image} alt={`Image ${index}`} width="100%" />
                <Typography
                  sx={{
                    fontFamily: "Gilroy-Medium",
                    fontSize: "16px",
                    fontWeight: 400,
                    lineHeight: "30px",
                    textAlign: "left",
                    color: "#5C5F5E",
                    marginTop: "10px",
                  }}
                >
                  {item.content}
                </Typography>
                <Button 
                  onClick={handleClick}
                  sx={{
                    display: "flex",
                    padding: "0 16px",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                    background: "#21B07D",
                    width: "100%",
                    maxWidth: "206px",
                    height: "49px",
                    borderRadius: "6px",
                    alignItems: "center",
                    transition: "color 0.3s",
                    marginLeft:"40px",
                    "&:hover": {
                      background: "#21B07D",
                    },
                    "@media (max-width: 600px)": {
                      maxWidth: "100%",
                      padding: "10px",
                    },
                  }}
                >
                  <Typography
                    sx={{
                      color: "#FFF",
                      textAlign: "center",
                      fontFamily: "Gilroy-SemiBold",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "normal",
                      letterSpacing: "0.8px",
                      textTransform:"capitalize",
                      "@media (max-width: 600px)": {
                        fontSize: "14px",
                      },
                    }}
                  >
                    Register Now
                  </Typography>
                </Button>
              </Box>
            ))}
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default RentYourSpace;
