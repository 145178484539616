import { Container, Grid, Typography, Box } from "@mui/material";
import React from "react";

const CareerFrame = () => {
  return (
    <Container maxWidth="lg">
      <Grid
        container
        sx={{
          p: "60px",
          borderRadius: "20px",
          background: "#EDF3F1",
          boxShadow: "0px 34px 74px -12px rgba(0, 0, 0, 0.16)",
          position: "relative",
          zIndex: 10,
          mt: -10,
        }}
       
      >
        <Grid item xs={12}>
          <Typography
            sx={{
              color: "#21B070",
              fontFamily: "Gilroy-SemiBold",
              fontSize: "24px",
              fontWeight: 400,
              mb: "36px",
            }}
          >
            Job Portal
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {/* <Box
            sx={{
              borderRadius: 6,
              px: 7,
              pt: 1,
              pb: 4,
              border: "1px solid #EDEDED",
              backgroundColor: "#FFF",
              boxShadow:
                "0px -1px 1px 1px rgba(13, 34, 71, 0.10) inset, 0px 2px 5px 0px rgba(13, 34, 71, 0.08)",
              minHeight: "950px",
            }}
          > */}
          <iframe
            src="https://recruiting-stg.personnellibrary.co.uk//job-boards?boardName=travelHelpSquaad-1716267975374"
            width="100%"
            height="100%"
            allowfullscreen="false"
            no-border
            title="Jobs Widget"
            style={{ border: "none", minHeight: "950px" }}
            scrolling="no"
          />
          {/* </Box> */}
        </Grid>
      </Grid>
    </Container>
  );
};

export default CareerFrame;
