import React from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Box, Typography, Container, Button } from "@mui/material";
import FitingService from "../../../../../assets/fittingservice.png";
import MobileFitting from "../../../../../assets/mobilefitting.png";
import BuyandSell from "../../../../../assets/buyandsell.png";

import "../blogs.css";

const data = [
  {
    title: "Fitting Centre",
    image: FitingService,
    path: "/carservicesfittingcenter",
    content: (
      <div>
        <Typography
          sx={{
            fontFamily: "Gilroy-Medium",
            fontSize: "16px",
            py: "30px",
            lineHeight: "30px",
            textAlign: "start",
            width: "100%",
          }}
        >
          Choose Your Repairs 50% Cheaper Than Franchise Dealers
        </Typography>
      </div>
    ),
  },
  {
    title: "Mobile Fitting Unit",
    image: MobileFitting,
    path: "/mobilefittingunit",

    content: (
      <div>
        <Typography
          variant="body1"
          align="left"
          sx={{
            fontFamily: "Gilroy-Medium",
            fontSize: "16px",
            py: "30px",
          }}
        >
          24 Hour Mobile Service
        </Typography>
      </div>
    ),
  },
  {
    title: "Buy And Sell Car Tyres Online With Local Fitting",
    image: BuyandSell,
    path: "/buyandsell",

    content: (
      <div>
        <Typography
          variant="body1"
          align="left"
          sx={{
            fontFamily: "Gilroy-Medium",
            fontSize: "16px",
            py: "30px",
          }}
        >
          Revamp Your Ride with Travel Help Squad By Buying & Selling Tyres
          Online With Local Fitting Centre Upgrade Your Wheels Effortlessly.
          Register Now And Experience A Tire Shopping Revolution!{" "}
        </Typography>
      </div>
    ),
  },
];

const CarServiceContent = () => {
  const navigate = useNavigate();

  const handleViewOfferCard = (path) => {
    navigate(path);
  };
 

  return (
    <Container maxWidth="xxl">
      <Box sx={{ paddingTop: "80px", px: { lg: "88px", xs: "0px" } }}>
        <Grid container justifyContent="center">
          {data.map((item, index) => (
            <Grid
              item
              key={index}
              md={10.5}
              xs={12}
              sx={{ px: { xs: "20px" } }}
            >
              <Typography
                variant="h4"
                align="left"
                sx={{
                  fontFamily: "Gilroy-Bold",
                  fontSize: "32px",
                  py: "30px",
                  textAlign: "center",
                }}
              >
                {item.title}
              </Typography>
              <img src={item.image} alt={`Image ${index}`} width="100%" />
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Typography>{item.content}</Typography>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  variant="contained"
                  onClick={() => handleViewOfferCard(item.path)}
                  sx={{
                    display: "flex",
                    padding: "0 16px",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                    background: "#21B07D",
                    width: "100%",
                    maxWidth: "206px",
                    height: "49px",
                    borderRadius: "6px",
                    alignItems: "center",
                    transition: "color 0.3s",
                    "&:hover": {
                      background: "#21B07D",
                    },
                    "@media (max-width: 600px)": {
                      maxWidth: "100%",
                      padding: "10px",
                    },
                  }}
                >
                  <Typography
                    sx={{
                      color: "#FFF",
                      textAlign: "center",
                      fontFamily: "Gilroy-SemiBold",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "normal",
                      letterSpacing: "0.8px",
                      "@media (max-width: 600px)": {
                        fontSize: "14px",
                      },
                    }}
                  >
                    Know More{" "}
                  </Typography>
                </Button>
              </div>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default CarServiceContent;
