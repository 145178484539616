// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.image-with-overlay {

    width: 100%;

    height: auto;

    display: block;

    mix-blend-mode: dark;

  }
  

  



 
`, "",{"version":3,"sources":["webpack://./src/components/Home/SecondSection.css"],"names":[],"mappings":";AACA;;IAEI,WAAW;;IAEX,YAAY;;IAEZ,cAAc;;IAEd,oBAAoB;;EAEtB","sourcesContent":["\n.image-with-overlay {\n\n    width: 100%;\n\n    height: auto;\n\n    display: block;\n\n    mix-blend-mode: dark;\n\n  }\n  \n\n  \n\n\n\n \n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
