import React from "react";
import { Grid, Box, Typography, Container, Paper } from "@mui/material";
import BlogImageCent from "../../assets/breakdownrecovery.png";
import DrivingImage from "../../assets/accidentrecovery.png";
import DrivingOffenceImage from "../../assets/refueling.png";
import PenaltyChargeImage from "../../assets/motorbikebreakdown.png";
import ProhabitedVehicle from "../../assets/ProbitedVehacle.png";
import JumpPicStart from "../../assets/jumppicstart.png";
import WrongFuel from "../../assets/wrongfuel.png";
import LostCarKey from "../../assets/lostcarkey.png";
import StuckVehicle from "../../assets/stuckvehicle.png";
import ExportImportVehicle from "../../assets/exportingandimportingvehicle.png";
import { textAlign } from "@mui/system";
import "./blogs.css";

const styles = {
  imageContainer: {
    position: "relative",
  },
  textOverlay: {
    position: "absolute",
    top: "68%",
    left: "17%",
    transform: "translate(-50%, -50%)",
    zIndex: 1,
    color: "rgba(255, 255, 255, 1)",
    padding: "10px",
  },
  textOverlayone: {
    position: "absolute",
    top: "73%",
    left: "18%",
    transform: "translate(-50%, -50%)",
    zIndex: 1,
    color: "rgba(255, 255, 255, 1)",
    padding: "10px",
  },
  textOverlaydart: {
    position: "absolute",
    left: "28%",
    marginTop: "-7%",
    transform: "translate(-50%, -50%)",
    zIndex: 1,
    color: "rgba(255, 255, 255, 1)",
    padding: "10px",
  },
  textOverlayonedart: {
    position: "absolute",
    left: "31%",
    marginTop: "-4%",
    transform: "translate(-50%, -50%)",
    zIndex: 1,
    color: "rgba(255, 255, 255, 1)",
    padding: "10px",
  },
  textOverlaydarttwo: {
    position: "absolute",
    left: "28%",
    marginTop: "-7%",
    transform: "translate(-50%, -50%)",
    zIndex: 1,
    color: "rgba(255, 255, 255, 1)",
    padding: "10px",
  },
  textOverlayonedarttwo: {
    position: "absolute",
    left: "31%",
    marginTop: "-4%",
    transform: "translate(-50%, -50%)",
    zIndex: 1,
    color: "rgba(255, 255, 255, 1)",
    padding: "10px",
  },
};


const data = [
  {
    title: "1.Breakdown Recovery",
    image: BlogImageCent,
    content: (
      <div>
        <Typography
          sx={{
            fontFamily: "Gilroy-Medium",
            fontSize: "16px",
            py: "8px",
            lineHeight: "30px",
            textAlign: "start",
            width: "100%",
          }}
        >
          Anywhere in the UK, if your car breaks down, we'll come get it and
          bring it to your house, a nearby garage, or your local garage within
          24 hours.​
        </Typography>
      </div>
    ),
  },
  {
    title: "2.Accident Recovery​",
    image: DrivingImage,
    content: (
      <div>
        <Typography
          variant="body1"
          align="left"
          sx={{
            fontFamily: "Gilroy-Medium",
            fontSize: "16px",
            py: "8px",
          }}
        >
          In the event of an accident, we will deliver your vehicle to any body
          shop or residential address in the UK.​
        </Typography>
      </div>
    ),
  },
  {
    title: "3.Refueling​",
    image: DrivingOffenceImage,
    content: (
      <div>
        <Typography
          variant="body"
          sx={{
            fontFamily: "Gilroy-Medium",
            fontSize: "16px",
            py: "8px",
          }}
        >
        If you run out of fuel, our professional staff will supply you with fuel and refuel your car for you.​
        </Typography>
      </div>
    ),
  },
  {
    title: "4.Motorbike Breakdown Recovery​",
    image: PenaltyChargeImage,
    content: (
      <div>
        <Typography
          variant="body"
          sx={{
            fontFamily: "Gilroy-Medium",
            fontSize: "16px",
            py: "8px",
          }}
        >
          Anywhere in the UK, if your motorbike breaks down, we will come and
          get it within 24 hours and transfer it to your home, your
          neighbourhood garage or the closest garage to you.​
        </Typography>
      </div>
    ),
  },
  {
    title: "5.Jump Start​​",
    image: JumpPicStart,
    content: (
      <div>
        <Typography
          variant="body"
          sx={{
            fontFamily: "Gilroy-Medium",
            fontSize: "16px",
            py: "8px",
          }}
        >
          If your car won't start, we can carry it to the closest mechanic or
          battery store or jumpstart it.​{" "}
        </Typography>
      </div>
    ),
  },
  {
    title: "6.Wrong Fuel​​",
    image: WrongFuel,
    content: (
      <div>
        <Typography
          variant="body"
          sx={{
            fontFamily: "Gilroy-Medium",
            fontSize: "16px",
            py: "8px",
          }}
        >
          Under no circumstances should you drive your car after filling it up
          with the incorrect fuel. Instead, call us right away, and we'll empty
          the tank, reload it, or transport it to the closest garage to finish
          the process.​{" "}
        </Typography>
      </div>
    ),
  },
  {
    title: "7.Lost Car Keys​​",
    image: LostCarKey,
    content: (
      <div>
        <Typography
          variant="body"
          sx={{
            fontFamily: "Gilroy-Medium",
            fontSize: "16px",
            py: "8px",
          }}
        >
          Our skilled crew can assist you open your car door and can even
          produce and programme a new key for you right away if you've lost your
          car keys or the keys are locked inside the car.​{" "}
        </Typography>
      </div>
    ),
  },
  {
    title: "8.Stuck Vehicle​​​",
    image: StuckVehicle,
    content: (
      <div>
        <Typography
          variant="body"
          sx={{
            fontFamily: "Gilroy-Medium",
            fontSize: "16px",
            py: "8px",
          }}
        >
          Call us if your car is in the mud or grass, and we will quickly send
          out our 25-meter-long winch to pull it out.​{" "}
        </Typography>
      </div>
    ),
  },
  {
    title: "9.Exporting and Importing Vehicles​​​",
    image: ExportImportVehicle,
    content: (
      <div>
        <Typography
          variant="body"
          sx={{
            fontFamily: "Gilroy-Medium",
            fontSize: "16px",
            py: "8px",
          }}
        >
          If you are importing or exporting a vehicle we will pick it up or drop
          it off to your desired port.​{" "}
        </Typography>
      </div>
    ),
  },
];

const PicsBlog = () => {
  return (
    <Container maxWidth="xxl">
      <Box sx={{ paddingTop: "80px", px: { lg: "88px", xs: "0px" } }}>
        <Grid container justifyContent="center">
          {data.map((item, index) => (
            <Grid
              item
              key={index}
              md={10.5}
              xs={12}
              sx={{ px: { xs: "20px" } }}
            >
              <Typography
                variant="h4"
                align="left"
                sx={{
                  fontFamily: "Gilroy-Bold",
                  fontSize: "32px",
                  py: "30px",
                }}
              >
                {item.title}
              </Typography>
              <img src={item.image} alt={`Image ${index}`} width="100%" />
              <Typography>{item.content}</Typography>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default PicsBlog;
