import Contact from "./registerchargingstation";
import ContactFormHome from "./contactformhome";


function HomeContactus() {
  return (
    <div>
      <Contact />
      <ContactFormHome/>
   
    </div>
  );
}

export default HomeContactus;
