import React from 'react'
import OfferHero from './offer-hero/offer-hero'
import OffersCards from './offers-cards/offers-cards'

const OurOfferings = () => {
  return (
    <div>
        <OfferHero/>
        <OffersCards/>
       
    </div>
  )
}

export default OurOfferings