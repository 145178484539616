import "./App.css";
import "./index.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "../src/components/Home/home.jsx";
import BlogPage from "../src/components/Blog/homeblog.jsx";
import PricingPage from "../src/components/Pricing/homepricing";
import ContactPage from "../src/components/CarServices/homeblog";
import "./fonts.css";
import OurOfferings from "./components/our-offerings/our-offerings";
import Layout from "./components/lay-out/lay-out";
import PersonalAcccount from "./components/solutions/personal-acccount/personal-acccount";
import CorporateAccount from "./components/solutions/corporate-account/corporate-account";
import Solution from "./components/solutions/solution";
import CommercialAccount from "./components/solutions/commercial-account/commercial-account";
import DriverKit from "./components/our-offerings/components/driver-kit/driver-kit.jsx";
import Lawyers from "./components/our-offerings/components/lawyers/lawyers";
import Career from "./components/career/career";
import ChargingStations from "./components/our-offerings/components/charging-stations/charging-stations";
import Insurance from "./components/our-offerings/components/insurance/insurance";
import RegisterChargingStaion from "./components/RegisterChargingStations/homeregisterchargingstation.jsx";
import RentYourSpace from "./components/Rentyourspace/homerentyourspace";
import Mot from "./components/our-offerings/components/mot/mot";
import MotorDealers from "./components/our-offerings/components/motor-dealers/motor-dealers";
import MotorAuctioneers from "./components/our-offerings/components/motor-auctioneers/motor-auctioneers";
import SalvageDealers from "./components/our-offerings/components/salvage-dealers/salvage-dealers";
import FinanceCompanies from "./components/our-offerings/components/finance-companies/finance-companies";
import FleetOperators from "./components/our-offerings/components/fleet-operators/fleet-operators";
import CarServicesFittingCenter from "./components/CarServicesFittingCenter/homeblog";
import MobileFittingUnit from "./components/Mobilefitting/homeblog";
import BuyandSell from "./components/BuyandSellCarTyres/homeblog";
import ContactUs from "./components/contact-us/contactUs.jsx";
import HomeCarServices from "./components/our-offerings/components/CarServices/homeblog.jsx";
import { Box } from "@mui/material";
import TermsAndConditions from "./components/terms-and-conditions/terms-and-conditions.jsx";
import PrivacyPolicy from "./components/privacy-policy/privacy-policy.jsx";
import CookiesPolicy from "./components/cookies-policy/cookies-policy.jsx";
import Faqs from "./components/faqs/faqs.jsx";
import AboutUs from "./components/about-us/about-us.jsx";
import SignUpPage from "./components/auth/sign-up/sign-up-page.jsx";
import SignIn from "./components/auth/sign-in/sign-in.jsx";
import Verification from "./components/auth/sign-up/verification/verification.jsx";
import UnderConstruction from "./components/under-construction/UnderConstruction.jsx";


function App() {
  return (
    <Router>
      <Box>
        <Routes>
          <Route
            path="/"
            element={
              <Layout>
                <HomePage />
              </Layout>
            }
          />
          <Route
            path="/blogs"
            element={
              <Layout>
                <BlogPage />
              </Layout>
            }
          />
          <Route
            path="/offer"
            element={
              <Layout>
                <OurOfferings />
              </Layout>
            }
          />
          <Route
            path="/Solutions"
            element={
              <Layout>
                <Solution />
              </Layout>
            }
          />
          <Route
            path="/career"
            element={
              <Layout>
                <Career />
              </Layout>
            }
          />
          <Route
            path="/Solutions/personal"
            element={
              <Layout>
                <PersonalAcccount />
              </Layout>
            }
          />
          <Route
            path="/Solutions/commercial"
            element={
              <Layout>
                <CommercialAccount />
              </Layout>
            }
          />
          <Route
            path="/offer/driving-test-kit"
            element={
              <Layout>
                <DriverKit />
              </Layout>
            }
          />
          <Route
            path="/offer/lawyer"
            element={
              <Layout>
                <Lawyers />
              </Layout>
            }
          />
          <Route
            path="/offer/charging-stations"
            element={
              <Layout>
                <ChargingStations />
              </Layout>
            }
          />
          <Route
            path="/offer/insurance"
            element={
              <Layout>
                <Insurance />
              </Layout>
            }
          />
          <Route
            path="/offer/register-charging-stations"
            element={
              <Layout>
                <RegisterChargingStaion />
              </Layout>
            }
          />
          <Route
            path="/carservices"
            element={
              <Layout>
                <ContactPage />
              </Layout>
            }
          />
          <Route
            path="/pricing"
            element={
              <Layout>
                <PricingPage />
              </Layout>
            }
          />
          <Route
            path="/offer/rent-your-space"
            element={
              <Layout>
                <RentYourSpace />
              </Layout>
            }
          />

          <Route
            path="/"
            element={
              <Layout>
                <HomePage />
              </Layout>
            }
          />
          <Route
            path="/blogs"
            element={
              <Layout>
                <BlogPage />
              </Layout>
            }
          />
          <Route
            path="/offer"
            element={
              <Layout>
                <OurOfferings />
              </Layout>
            }
          />
          
          <Route
            path="/career"
            element={
              <Layout>
                <Career />
              </Layout>
            }
          />
           <Route
            path="/get-in-touch/contact-us"
            element={
              <Layout>
                <ContactUs/>
              </Layout>
            }
          />
          <Route
            path="/Solutions/personal"
            element={
              <Layout>
                <PersonalAcccount />
              </Layout>
            }
          />
             <Route
            path="/Solutions/corporate"
            element={
              <Layout>
                <CorporateAccount />
              </Layout>
            }
          />
          <Route
            path="/Solutions/commercial"
            element={
              <Layout>
                <CommercialAccount />
              </Layout>
            }
          />
          <Route
            path="/offer/driving-test-kit"
            element={
              <Layout>
                <DriverKit />
              </Layout>
            }
          />
          <Route
            path="/offer/lawyer"
            element={
              <Layout>
                <Lawyers />
              </Layout>
            }
          />
          <Route
            path="/offer/charging-stations"
            element={
              <Layout>
                <ChargingStations />
              </Layout>
            }
          />
          <Route
            path="/offer/insurance"
            element={
              <Layout>
                <Insurance />
              </Layout>
            }
          />
          <Route
            path="/offer/mot"
            element={
              <Layout>
                <Mot />
              </Layout>
            }
          />
          <Route
            path="/offer/motor-dealer"
            element={
              <Layout>
                <MotorDealers />
              </Layout>
            }
          />
          <Route
            path="/offer/motor-auctioneers"
            element={
              <Layout>
                <MotorAuctioneers />
              </Layout>
            }
          />
          <Route
            path="/offer/salvage-dealers"
            element={
              <Layout>
                <SalvageDealers />
              </Layout>
            }
          />
          <Route
            path="/offer/finance-companies"
            element={
              <Layout>
                <FinanceCompanies />
              </Layout>
            }
          />
          <Route
            path="/offer/fleet-operators"
            element={
              <Layout>
                <FleetOperators />
              </Layout>
            }
          />
          <Route
            path="/offer/car-services"
            element={
              <Layout>
                <HomeCarServices />
              </Layout>
            }
          />
            <Route
            path="/carservicesfittingcenter"
            element={
              <Layout>
                <CarServicesFittingCenter />
              </Layout>
            }
          />
           <Route
            path="/mobilefittingunit"
            element={
              <Layout>
                <MobileFittingUnit />
              </Layout>
            }
          />
           <Route
            path="/buyandsell"
            element={
              <Layout>
                <BuyandSell />
              </Layout>
            }
          />
            <Route
            path="/terms-and-condition"
            element={
              <Layout>
                <TermsAndConditions/>
              </Layout>
            }
          />
           <Route
            path="/privacy-policy"
            element={
              <Layout>
                <PrivacyPolicy/>
              </Layout>
            }
          />
           <Route
            path="/cookies-policy"
            element={
              <Layout>
                <CookiesPolicy/>
              </Layout>
            }
          />
           <Route
            path="/get-in-touch-faqs"
            element={
              <Layout>
                <Faqs/>
              </Layout>
            }
          />
           <Route
            path="/about-us"
            element={
              <Layout>
                <AboutUs/>
              </Layout>
            }
          />
          <Route path="/sign-up" element={<SignUpPage />} />
          <Route path="/sign-in" element={<SignIn />} />
          <Route path='/verification' element={<Verification />} />
          <Route path='/verification/:userId' element={<Verification />} />
          <Route
            path="/under-construction"
            element={
              <Layout>
                <UnderConstruction/>
              </Layout>
            }
          />
        </Routes>
      </Box>
    </Router>
  );
}

export default App;
