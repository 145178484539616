import React from "react";
import { Box, Divider, Grid, Typography } from "@mui/material";
import LayoutWrapper from "../lay-out/layout-wrapper";
import { plansMockData } from "./pricing-mock-data";


const PricingContent = () => {
  return (
    <LayoutWrapper background="#EDF3F1">
      <Box flexGrow={1} sx={{ width: "90%", margin:"0 auto"}}>
        <Grid
          container
          spacing={2}
          sx={{
            p: "60px",
            borderRadius: "20px",
            background: "#EDF3F1",
            boxShadow: "0px 34px 74px -12px rgba(0, 0, 0, 0.16)",
            position: "relative",
            zIndex: 10,
            mt: -10,
            
          }}
        >
          {plansMockData?.map((planType, index) => (
            <Grid item xl={4} lg={4} md={6} sm={12} xs={12} key={index}>
              <Box
                mb={5}
                sx={{
                  background: planType?.planBtnBackground,
                  width: "119px",
                  height: "27px",
                  padding: "6px 31px",
                  borderRadius: "0px 10px",
                }}
              >
                <Typography sx={styles.pricingPlanType}>
                  {planType.planType}
                </Typography>
              </Box>
              {planType?.plans.map((plan, id) => (
                <Box key={id}>
                  <Typography sx={styles.pricingTrial} mb={1}>
                    {plan?.trial}
                  </Typography>
                  <Typography sx={styles.pricingTitle} mb={4}>
                    {plan?.title}
                  </Typography>
                  <Divider
                    sx={{ marginBottom: "35px", borderColor: "#454545" }}
                  />
                  {plan?.features?.map((detail, detailId) => (
                    <Box key={detailId}>
                      <Grid item xl={12} xs={12} mb={3}>
                        <Typography sx={styles.pricingContentStyles}>
                          {detail?.feature}
                        </Typography>
                      </Grid>
                    </Box>
                  ))}
                  {plan?.descriptions?.map((description, descriptionId) => (
                    <Grid item xl={12} xs={12} mb={3} key={descriptionId}>
                      <Box sx={styles.wrapDescription}>
                        <img src={description?.icon} alt="icon" />
                        <Typography sx={styles.pricingContentStyles}>
                          {description?.description}
                        </Typography>
                      </Box>
                    </Grid>
                  ))}
                </Box>
              ))}
            </Grid>
          ))}
        </Grid>
      </Box>
    </LayoutWrapper>
  );
};

export default PricingContent;

const styles = {
  pricingContentStyles: {
    color: "#3B4A45",
    fontFamily: "Gilroy-Medium",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
  },
  pricingTitle: {
    color: "#21B07D",
    fontFamily: "Gilroy-Bold",
    fontSize: "26px",
    fontStyle: "normal",
    fontWeight: 400,
    LineHeight: "32.19px",
  },
  pricingTrial: {
    color: "#3D4341",
    fontFamily: "Gilroy-Bold",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 400,
    LineHeight: "22.05px",
  },
  pricingPlanType: {
    color: "#fff",
    fontFamily: "Gilroy-Bold",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 600,
    LineHeight: "14.52px",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  wrapDescription: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
};
