import React from 'react'
import SalvageDealersHero from './salvage-dealers-hero/salvage-dealers-hero'
import SalvageDealersCard from './salvage-dealers-card/salvage-dealers-card'

const SalvageDealers = () => {
  return (
<>
<SalvageDealersHero/>
<SalvageDealersCard/>
</>
  )
}

export default SalvageDealers
