import React from 'react'
import InsuranceHero from './insurance-hero/insurance-hero'
import InsuranceCard from './insurance-card/insurance-card'

const Insurance = () => {
  return (
   <>
   <InsuranceHero/>
   <InsuranceCard/>
    </>
  )
}

export default Insurance