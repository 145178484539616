import React from 'react'
import MotHero from './mot-hero/mot-hero'
import MotCard from './mot-cards/mot-cards'

const Mot = () => {
  return (
    <>
    <MotHero/>
    <MotCard/>
     </>
  )
}

export default Mot
