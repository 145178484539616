import { Box } from '@mui/material'
import React from 'react'
import CookiesPolicyHero from './cookies-hero/cookies-hero'
import CookiesPolicyContent from './cookies-content/cookies-content'

const CookiesPolicy = () => {
  return (
    <Box>
      <CookiesPolicyHero/>
      <CookiesPolicyContent/>
    </Box>
  )
}

export default CookiesPolicy