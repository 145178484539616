export const privacyPolicyMockData = [
  {
    id: "1",
    title: "Introduction",
    content:
      "Welcome to Travel Help Squad, operated by Pay IO Services LTD. We are committed to protecting your privacy and ensuring that your personal information is handled safely and responsibly. This Privacy Policy explains how we collect, use, and protect your information. ",
  },
  {
    id: "2",
    title: "Information We Collect",
    list: [
      "Personal Information: Name, email address, phone number, address, and any other information you provide.",
      "Usage Data: IP address, browser type, pages visited, time spent on pages, and other similar information.",
    ],
  },
  {
    id: "3",
    title: "How We Use Your Information",
    list: [
      "To provide and maintain our service",
      "To notify you about changes to our service.",
      "To provide customer support.",
      "To gather analysis or valuable information so that we can improve our service.",
      'To monitor the usage of our service.'
    ],  
  },
  {
    id: "4",
    title: "Sharing Your Information",
    content:"We do not sell, trade, or otherwise transfer your personal information to outside parties except:",
    list: [
      "With service providers to perform functions on our behalf.",
      "To comply with legal obligations.",
    ],
  },
  {
    id: "5",
    title: "Security of Your Information",
    content: `We use administrative, technical, and physical security measures to protect your personal information. Despite these efforts, no system is completely secure, and we cannot guarantee the absolute security of your information.`,
  },
  {
    id: "6",
    title: "Your Rights",
    content:
      "You have the right to access, update, or delete your personal information. You may also object to the processing of your information or request that we restrict the processing of your information.",
  },
  {
    id: "7",
    title: "Changes to This Privacy Policy",
    content:
      "These terms and conditions are governed by and construed in accordance with the laws of England and Wales, and you submit to the non-exclusive jurisdiction of the state and federal courts of England and Wales for the resolution of any disputes.",
  },
  {
    id: "8",
    title: "Changes to Terms",
    content:
      "We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.",
  },
  // {
  //   id: "9",
  //   title: "Contact Us",
  //   content:
  //     "If you have any questions about this Privacy Policy, please contact us: ",
  //   list: [
  //     "By email: Info@travelhelpsquad.co.uk",
  //     "By visiting this page on our website: www.travelhelpsquad.co.uk/contact-us ",
  //   ],
  // },
];
