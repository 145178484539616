// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/pricingblog.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#header {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-size: cover;
    width: 100%;
    height: 489px;
    position: relative;
  }
  
  #header::before {
    content: "";
    background: linear-gradient(180deg, rgba(7, 16, 31, 0) 13.65%, #293D54 93.29%);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    mix-blend-mode: multiply; 
    
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Rentyourspace/pricingstyle.css"],"names":[],"mappings":"AAAA;IACI,yDAAmD;IACnD,sBAAsB;IACtB,WAAW;IACX,aAAa;IACb,kBAAkB;EACpB;;EAEA;IACE,WAAW;IACX,8EAA8E;IAC9E,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,wBAAwB;;EAE1B","sourcesContent":["#header {\n    background-image: url(../../assets/pricingblog.png);\n    background-size: cover;\n    width: 100%;\n    height: 489px;\n    position: relative;\n  }\n  \n  #header::before {\n    content: \"\";\n    background: linear-gradient(180deg, rgba(7, 16, 31, 0) 13.65%, #293D54 93.29%);\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    mix-blend-mode: multiply; \n    \n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
