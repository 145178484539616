import React from "react";
import { Container, Grid } from "@mui/material";
import BlogImage from "../../../../../assets/carservices.png";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";


const CarServicesHero = () => {
  return (
    <div
      style={{
        backgroundImage: `url(${BlogImage})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        position: "relative",
        width: "100%",
        minHeight: "489px",
      }}
    >
      <div
        style={{
          position: "absolute",
          width: "100%",
          // minHeight: "489px",
          height: "100%",
          background:
            "linear-gradient(262deg, rgba(0, 0, 0, 0.06) 13.81%, #000 106.26%)",
        }}
      ></div>
      <Container maxWidth="xl">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "center",
                width: "100%",
                mt: "221px",
                paddingLeft: "24px",
              }}
            >
              <Typography
                sx={{
                  color: "#FFFFFF",
                  fontFamily: "Gilroy-Bold",
                  fontSize: "56px",
                  fontStyle: "normal",
                  fontWeight: 700,
                  zIndex: 1,
                }}
              >
                Car Services{" "}
              </Typography>
              <Typography
                sx={{
                  color: "rgba(255, 255, 255, 0.70)",
                  fontFamily: "Gilroy-Medium",
                  fontSize: "20px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "32px",
                  zIndex: 1,
                  mt: 2,
                }}
              >
                We try to get the best and most convenient options and deals for
                you
                <br /> through our one single comprehensive platform
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default CarServicesHero;
