// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.blog-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  max-width: 1200px;
  padding: 20px;
  margin-left: 4%;
}

.blog-containerthree {
  display: flex;
  margin-left: 6%;
  margin-top: 2%;

}

.blog-image {
}

@media (max-width: 768px) {
  .blog-container {
    grid-template-columns: 1fr;
  }
  .blog-image{
    width:"59%"
  }
}
@media (max-width: 1900px) {
  .blog-container {
    grid-template-columns: 1fr;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/CarServices/blogs.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,cAAc;EACd,iBAAiB;EACjB,aAAa;EACb,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,eAAe;EACf,cAAc;;AAEhB;;AAEA;AACA;;AAEA;EACE;IACE,0BAA0B;EAC5B;EACA;IACE;EACF;AACF;AACA;EACE;IACE,0BAA0B;EAC5B;AACF","sourcesContent":[".blog-container {\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  grid-gap: 20px;\n  max-width: 1200px;\n  padding: 20px;\n  margin-left: 4%;\n}\n\n.blog-containerthree {\n  display: flex;\n  margin-left: 6%;\n  margin-top: 2%;\n\n}\n\n.blog-image {\n}\n\n@media (max-width: 768px) {\n  .blog-container {\n    grid-template-columns: 1fr;\n  }\n  .blog-image{\n    width:\"59%\"\n  }\n}\n@media (max-width: 1900px) {\n  .blog-container {\n    grid-template-columns: 1fr;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
