import PricingHero from "./pricing-hero";
import PricingContent from "./pricing-content";


function HomePricing() {
  return (
    <div>
      <PricingHero />
      <PricingContent/>
   
    </div>
  );
}

export default HomePricing;
