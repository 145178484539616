import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import LayoutWrapper from "../../lay-out/layout-wrapper";
import aboutUsImg from "../../../assets/about-us.png";
import aboutUsServiceImg from "../../../assets/about-us-service-img.png";

import {
  aboutUSMockData,
  aboutUsBottomSectionMockData,
} from "./about-us-mock-data";

const AboutUsContent = () => {
  return (
    <LayoutWrapper background="#EDF3F1">
      <Box flexGrow={1} sx={{ width: "90%", margin:"0 auto"}}>
        <Grid
          container
          sx={{
            p: { xl: "40px", lg: "40px", md: "40px", sm: "20px", xs: "10px" },
            borderRadius: "20px",
            background: "#EDF3F1",
            boxShadow: "0px 34px 74px -12px rgba(0, 0, 0, 0.16)",
            position: "relative",
            zIndex: 10,
            mt: -10,
          }}
        >
          <Grid item xl={12} xs={12}>
            <Box
              sx={{
                background: `url(${aboutUsImg})`,
                backgroundRepeat: "no-repeat",
                width: "100%",
                height: {xl:"195px",lg:"195px",md:"220px",sm:"280px",xs:"550"},
                position: "relative",
                zIndex: 2,
                backgroundSize: "100% 120%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "Center",
                  justifyContent: "center",
                  margin: "0 auto",
                  width: {xl:"60%",lg:"80%",md:"80%",sm:"80%",xs:"80%"},
                  flexWrap: "wrap",
                }}
              >
                <Typography pt={5} pb={3}
                  sx={{
                    color: "rgba(255, 255, 255, 0.70)",
                    fontFamily: "Gilroy-SemiBold",
                    fontSize: "24px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "32px",
                    zIndex: 1,
                    mt: 2,
                    textAlign: "center",
                    
                  }}
                >
                  We're not just another service, We're your partner in
                  transforming travel experiences and simplifying vehicle
                  management.
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Box
            sx={{
              p: { xl: "30px", lg: "30px", md: "30px", sm: "20px", xs: "10px" },
            }}
          >
            <Grid item xl={12} xs={12}>
              <Typography sx={styles.headingStyles} mt={2}>Our Journey</Typography>

              <Typography sx={styles.topParagraph} mt={2} mb={3}>
                Travel Help Squad was founded with the goal of transforming the
                way people manage their travel and vehicle needs. Recognising
                the challenges faced by travellers and vehicle owners, we set
                out to create a platform that addresses these issues head-on.
                Over the years, we have continuously evolved and expanded our
                services to meet the growing demands of our customers. From PCN
                management to comprehensive vehicle services, our journey has
                been driven by a commitment to innovation, customer
                satisfaction, and excellence.
              </Typography>
            </Grid>
            <Typography sx={styles.listDescription}>
              At Travel Help Squad, we offer a wide range of services to meet
              your needs:
            </Typography>
            <Grid container spacing={2}>
              <Grid item xl={7.5} lg={7.5} xs={12} mt={1}  mb={5}>
                <ul>
                  {aboutUSMockData?.map((item, index) => {
                    return (
                      <li
                        key={index}
                        style={{
                          ...styles.listStyle,
                          marginBottom: "28px",
                        }}
                        dangerouslySetInnerHTML={{ __html: item }}
                      />
                    );
                  })}
                </ul>
              </Grid>
              <Grid xl={4.5} lg={4.5} xs={12}  mb={5} display="flex" justifyContent="end">
                <img className="listImgStyle"
                  src={aboutUsServiceImg}
                  alt="service Img"
                  style={{width:"65%"}}
                />
              </Grid>
            </Grid>

            <Box mt={10}>
              <Typography mb={10} textAlign="center" sx={styles.apartUsHeadingStyle}>What Sets Us Apart</Typography>
              {aboutUsBottomSectionMockData?.map((item) => {
                return (
                  <Grid container spacing={2} key={item?.id} mt={5}>
                    {item?.id === "1" && (
                      <>
                        <Grid item xl={6} xs={12}>
                          <img
                            src={item?.img}
                            alt="apart Img"
                            style={{ width: "84%" }}
                          />
                        </Grid>
                        <Grid item xl={6} xs={12}  display="flex" justifyContent="start" alignItems="center">
                        <Box sx={{width:"80%",minHeight:"240px"}}>
                          <Typography sx={styles.apartParagraphStyle}>{item?.content}</Typography>
                          </Box>
                        </Grid>
                      </>
                    )}
                    {item?.id === "2" && (
                      <>
                        <Grid item xl={6} xs={12} display="flex" justifyContent="start" alignItems="center">
                        <Box sx={{width:"80%",minHeight:"240px"}}>
                          <Typography sx={styles.apartParagraphStyle}>{item?.content}</Typography>
                          </Box>
                        </Grid>
                        <Grid item xl={6} xs={12}>
                          <img
                            src={item?.img}
                            alt="apart Img"
                            style={{ width: "84%" }}
                          />
                        </Grid>
                      </>
                    )}
                  </Grid>
                );
              })}
            </Box>
          </Box>
        </Grid>
      </Box>
    </LayoutWrapper>
  );
};

export default AboutUsContent;

const styles = {
  headingStyles: {
    color: "#21B07D",
    fontFamily: "Gilroy-Bold",
    fontSize: "32px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "32px",
  },
  topParagraph: {
    color: "#5C5F5E",
    fontFamily: "Gilroy-SemiBoldItalic",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    LineHeight: "30px",
  },
  listStyle:{
    color: "#5C5F5E",
    fontFamily: "Gilroy-Medium",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    LineHeight: "30px",
  },
  listDescription:{
    color: "#3B4A45",
    fontFamily: "Gilroy-SemiBold",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 400,
    LineHeight: "30px",
  },
  apartUsHeadingStyle: {
    color: "#21B07D",
    fontFamily: "Gilroy-Bold",
    fontSize: "40px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "32px",
  },
  apartParagraphStyle: {
    color: "#5C5F5E",
    fontFamily: "Gilroy-SemiBoldItalic",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 400,
    LineHeight: "40px",
  },
};
