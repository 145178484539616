import React from "react";
import { Grid, Box, Typography } from "@mui/material";
import repairImg from "../../assets/carfitting/repairImg.svg";
import repairAbImg from "../../assets/carfitting/repairAbImg.svg";
import diagnosticsImg from "../../assets/carfitting/diagnosticImg.svg";
import diagnosticsAbImg from "../../assets/carfitting/diagnosticAbImg.svg";
import serviceImg from "../../assets/carfitting/genServiceImg.svg";
import serviceAbImg from "../../assets/carfitting/genServiceAbImg.svg";
import motImg from "../../assets/carfitting/motImg.svg";
import motAbImg from "../../assets/carfitting/motAbImg.svg";
import inspectionImg from "../../assets/carfitting/inspectionImg.svg";
import inspectionAbImg from "../../assets/carfitting/inspectionAbImg.svg";
import tyreImg from "../../assets/carfitting/tyrImg.svg";
import tyreAbImg from "../../assets/carfitting/tyrAbImg.svg";
import "./blogs.css";
import LayoutWrapper from "../lay-out/layout-wrapper";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const carFittingData = [
  {
    id: "1",
    title: "1. Repair",
    mainBgImg: repairImg,
    positionAbImg: repairAbImg,
    listTitle:
      "Swift Repairs, Seamless Solutions: Travel Help Squad's Repair Feature",
    list: [
      `<strong>Rapid Response:</strong> Get your vehicle back on the road quickly with our network of skilled repair professionals.`,
      `<strong>Comprehensive Services:</strong> From minor fixes to major overhauls, we've got all your repair needs covered.`,
      `<strong>​Budget-Friendly:</strong> Affordable rates and transparent pricing ensure you get quality repairs without breaking the bank.`,
      `<strong>Drive Worry-Free:</strong> Travel Help Squad's Repair feature keeps you moving, no matter the roadblocks.`,
    ],
    listBottomText:
      "Stay ahead of repairs with ease. Register now and keep your journey smooth and stress-free!",
  },
  {
    id: "2",
    title: "2. Diagnostics",
    mainBgImg: diagnosticsImg,
    positionAbImg: diagnosticsAbImg,
    listTitle:
      "Precision Diagnostics, Instant Insights with Travel Help Squad's Diagnostics Feature​",
    list: [
      `<strong>Smart Diagnosis:</strong> Pinpoint vehicle issues quickly with our advanced diagnostic tools and technology.`,
      `<strong>Real-Time Analysis:</strong> Receive instant insights into your vehicle's health, ensuring safe and efficient driving.​`,
      `<strong>​​Effortless Repairs:</strong> Seamless integration with our Repair feature for a hassle-free solution to identified problems.​​`,
      `<strong>Peace of Mind:</strong> Drive confidently with the knowledge that your vehicle's performance is optimised.​​`,
    ],
    listBottomText:
      "​Keep your ride in top shape. Download Travel Help Squad now for a smarter, smoother driving experience!​​",
  },
  {
    id: "3",
    title: "3. General Servicing",
    mainBgImg: serviceImg,
    positionAbImg: serviceAbImg,
    listTitle:
      "Elevate Your Vehicle's Health: Travel Help Squad's Servicing Feature​​",
    list: [
      `<strong>Effortless Scheduling:</strong>  Easily book servicing appointments, ensuring your vehicle is always in top-notch condition.​`,
      `<strong>​Timely Reminders:</strong> Receive service alerts, so you never miss a maintenance milestone or oil change.`,
      `<strong>Expert Care:</strong>Our network of trusted service providers ensures your vehicle gets the attention it deserves.`,
      `<strong>Drive Worry-Free:</strong> With Travel Help Squad's Servicing feature, you're one step closer to a smoother, safer ride.​​`,
    ],
    listBottomText:
      "Keep your vehicle in prime condition hassle-free. Register now and enjoy stress-free servicing!​​",
  },
  {
    id: "4",
    title: "4. MOT",
    mainBgImg: motImg,
    positionAbImg: motAbImg,
    listTitle:
      "The MOT test service differs from getting a regular service in that it does not examine the general mechanical condition of your car, therefore getting a regular service is still necessary once a year. However, if you book your service and MOT test simultaneously with Travel Help Squad, you can save time and money.​ ",
    list: [
      `<strong>Certified Technicians:</strong> Our team of certified technicians provide reliable and accurate MOT inspections.`,
      `<strong>Convenient Booking:</strong> Easily schedule your MOT test online at your preferred date and time.`,
      `<strong>Transparent Pricing:</strong> Competitive and transparent pricing with no hidden fees.`,
      `<strong>Fast and Efficient Service:</strong> Quick turnaround times to get you back on the road as soon as possible.`
    ],
    listBottomText:
      "Mechanics can perform the service ahead of the MOT to identify any problems that could cause your MOT to fail.​ Save time and avoid scheduling separate servicing and MOT appointments by completing all necessary maintenance work in one session.​",
  },
  {
    id: "5",
    title: "5. Pre-Purchase Inspection",
    mainBgImg: inspectionImg,
    positionAbImg: inspectionAbImg,
    listTitle:
      "Smart Buying Starts Here with Travel Help Squad Pre-purchase Inspection.​​",
    list: [
      `<strong>Thorough Checks:</strong> Get a comprehensive vehicle inspection before purchase, uncovering hidden issues and potential pitfalls.​`,
      `<strong>Transparent Reports:</strong> Receive detailed reports with expert insights, helping you make informed buying decisions.​`,
      `<strong>Confident Choices:</strong> With Travel Help Squad's Pre-purchase Inspection feature, you can shop for your next vehicle with complete confidence.​`,
      `<strong>Smarter Buys:</strong> Ensure your purchase is a smart one, backed by professional assessments and peace of mind.​`,
    ],
    listBottomText:
      "Make your next vehicle purchase a wise one. Register now with Travel Help Squad",
  },
  {
    id: "6",
    title: "6. Tyre Fitting With Competitive Rates",
    mainBgImg: tyreImg,
    positionAbImg: tyreAbImg,
    listTitle:
      "  Get Rolling with Confidence with Competitive Rates for Tyre Fitting",
    list: [
      `<strong>Affordable Tyres:</strong>  Quality tyres at prices that won't break the bank, keeping you safe on the road without the hefty costs.​`,
      `<strong>Expert Fitting: </strong> Skilled professionals ensure precise tyre installation, extending your tire life and improving performance.​`,
      `<strong>Quick Service: </strong> Efficient tyre fitting gets you back on the road swiftly, minimising downtime and inconvenience.​`,
      `<strong>Competitive Advantage:</strong>  Travel confidently with top-notch tyres without sacrificing your budget. Experience exceptional tyre fitting without the hefty price tag. 
      Register now and roll on with peace of mind!`,
    ],
    listBottomText:
      "Stay ahead of repairs with ease. Register now and keep your journey smooth and stress-free!",
  },
];

// const data = [
//   {
//     title: "1.Repair",
//     image: RepairPic,
//     smallpic: SmallPic,
//     content1:
//       "Swift Repairs, Seamless Solutions: Travel Help Squad's Repair Feature​",
//     content2: "Rapid Response:",
//     content3:
//       "Get your vehicle back on the road quickly with our network of skilled repair professionals.",
//     content4: "Comprehensive Services:",
//     content5:
//       "From minor fixes to major overhauls, we've got all your repair needs covered.",
//     content6: "​Budget-Friendly:",
//     content7:
//       "Affordable rates and transparent pricing ensure you get quality repairs without breaking the bank.",
//     content8: "Drive Worry-Free:",
//     content9:
//       "Travel Help Squad's Repair feature keeps you moving, no matter the roadblocks.​​",
//     content10:
//       " Stay ahead of repairs with ease. Register now and keep your journey smooth and stress-free!​",
//   },
//   {
//     title: "2.Diagnostics",
//     image: DiagonosticPic,
//     smallpic: SmallDiagonosticPic,
//     textAlign: "right",
//     transform: "scaleX(-1)",
//     content1:
//       "Precision Diagnostics, Instant Insights with Travel Help Squad's Diagnostics Feature​",
//     content2: "Smart Diagnosis:",
//     content3:
//       "Pinpoint vehicle issues quickly with our advanced diagnostic tools and technology.",
//     content4: "Real-Time Analysis:",
//     content5:
//       "Receive instant insights into your vehicle's health, ensuring safe and efficient driving.​",
//     content6: "​​Effortless Repairs: ",
//     content7:
//       "Seamless integration with our Repair feature for a hassle-free solution to identified problems.​​",
//     content8: "Peace of Mind:",
//     content9:
//       "Drive confidently with the knowledge that your vehicle's performance is optimised.​​",
//     content10:
//       "​Keep your ride in top shape. Download Travel Help Squad now for a smarter, smoother driving experience!​​",
//   },
//   {
//     title: "3.General Servicing",
//     image: GeneralServingPic,
//     smallpic: SmallGeneralServing,

//     content1:
//       "Elevate Your Vehicle's Health: Travel Help Squad's Servicing Feature​​",
//     content2: "Effortless Scheduling:",
//     content3:
//       "Easily book servicing appointments, ensuring your vehicle is always in top-notch condition.​",
//     content4: "​Timely Reminders:",
//     content5:
//       "Receive service alerts, so you never miss a maintenance milestone or oil change.",
//     content6: "​Expert Care:",
//     content7:
//       "Our network of trusted service providers ensures your vehicle gets the attention it deserves.",
//     content8: "Drive Worry-Free:",
//     content9:
//       "With Travel Help Squad's Servicing feature, you're one step closer to a smoother, safer ride.​​",
//     content10:
//       "Keep your vehicle in prime condition hassle-free. Register now and enjoy stress-free servicing!​​",
//   },
//   {
//     title: "4.MOT",
//     image: MotlargePic,
//     smallpic: SmallMot,
//     textAlign: "right",
//     transform: "scaleX(-1)",
//     content1:
//       "The MOT test service differs from getting a regular service in that it does not examine the general mechanical condition of your car.​​Therefore getting a regular service is still necessary once a year. However, if you book your service and MOT test simultaneously with Travel Help Squad​",
//     content2:
//       "​Mechanics can perform the service ahead of the MOT to identify any problems that could cause your MOT to fail. ",
//     content3:
//       "You can save time and money​ .",
//     content4:
//       "​Save time and avoid scheduling separate servicing and MOT appointments by completing all necessary maintenance work in one session.​​​",
//       content6:"During an MOT test, various components of a vehicle are thoroughly inspected for any faults.",
//       content8:"It is important that all vehicles over three years old receive this test annually to meet the environmental and safety standards set by the government."
//   },

//   {
//     title: "5.Pre-Purchase Inspection",
//     image: RepairlargePic,
//     smallpic: SmallRepair,

//     content1:
//       "Smart Buying Starts Here with Travel Help Squad Pre-purchase Inspection.​​",
//     content2: "Thorough Checks:",
//     content3:
//       "Get your vehicle back on the road quickly with our network of skilled repair professionals.",
//     content4: "Transparent Reports:",
//     content5:
//       " Receive detailed reports with expert insights, helping you make informed buying decisions.​",
//     content6: "​​Confident Choices:",
//     content7:
//       "With Travel Help Squad's Pre-purchase Inspection feature, you can shop for your next vehicle with complete confidence.",
//     content8: "Smarter Buys: ",
//     content9:
//       "Ensure your purchase is a smart one, backed by professional assessments and peace of mind.​​",
//     content10:
//       " Make your next vehicle purchase a wise one. Register now with Travel Help Squad​",
//   },
//   {
//     title: "6.Tyre Fitting With Competitive Rates",
//     image: TyreFittinglargePic,
//     smallpic: SmallTyreFitting,
//     textAlign: "right",
//     transform: "scaleX(-1)",
//     content1:
//       "Get Rolling with Confidence with Competitive Rates for Tyre Fitting​",
//     content2: "Affordable Tyres:",
//     content3:
//       "Quality tyres at prices that won't break the bank, keeping you safe on the road without the hefty costs.​",
//     content4: "Expert Fitting:",
//     content5:
//       "Skilled professionals ensure precise tyre installation, extending your tire life and improving performance.",
//     content6: "​Quick Service:",
//     content7:
//       "Efficient tyre fitting gets you back on the road swiftly, minimizing downtime and inconvenience.",
//     content8: "​Competitive Advantage:",
//     content9:
//       "Travel confidently with top-notch tyres without sacrificing your budget.​​​",
//     content10:
//       " Experience exceptional tyre fitting without the hefty price tag. Register now and roll on with peace of mind!​​",
//   },
// ];

const PicsCarServices = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <LayoutWrapper background="#EDF3F1">
      <Box flexGrow={1} mt={15}>
        {carFittingData?.map((item) => {
          return (
            <Grid container spacing={4} key={item?.id}>
              {(item?.id === "1" || item?.id === "3" || item?.id === "5") && (
                <>
                  <Grid item xs={12} sm={12} md={12} xl={6} lg={6} mb={5}>
                    <Typography
                      variant="h4"
                      align="left"
                      mb={1}
                      sx={{
                        fontFamily: "Gilroy-Bold",
                        fontSize: "32px",
                      }}
                    >
                      {item.title}
                    </Typography>
                    <Box sx={{ position: "relative" }}>
                      <Box>
                        <img
                          src={item.mainBgImg}
                          width="100%"
                          height="100%"
                          alt="img"
                        />
                      </Box>
                      {!isSmallScreen && (
                      <Box
                        sx={{
                          position: "absolute",
                          bottom: -20,
                          right: -30,
                          // width: isSmallScreen ? "100%" : "383px",
                          height: "297px",
                          opacity: 1,
                          pointerEvents: "none",
                        }}
                      >
                        <img
                          src={item.positionAbImg}
                          width="100%"
                          height="100%"
                          alt="img"
                        />
                      </Box>
                    )}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} xl={6} lg={6} mb={5}>
                    <Box>
                      <Typography mb={2} sx={styles.listHeading}>
                        {item.listTitle}
                      </Typography>
                      <ul>
                        {item?.list.map((listData, index) => {
                          return (
                            <li
                              key={index}
                              style={{
                                ...styles.listStyle,
                                marginBottom: "28px",
                              }}
                              dangerouslySetInnerHTML={{ __html: listData }}
                            />
                          );
                        })}
                      </ul>
                      <Typography sx={styles.listBottomText}>
                        {item.listBottomText}
                      </Typography>
                    </Box>
                  </Grid>
                </>
              )}
              {(item?.id === "2" || item?.id === "4" || item?.id === "6") && (
                <>
                  <Grid item xs={12} sm={12} md={12} xl={6} lg={6} mb={5}>
                    <Box>
                      <Typography mb={2} sx={styles.listHeading}>
                        {item.listTitle}
                      </Typography>
                      <ul>
                        {item?.list.map((listData, index) => {
                          return (
                            <li
                              key={index}
                              style={{
                                ...styles.listStyle,
                                marginBottom: "28px",
                              }}
                              dangerouslySetInnerHTML={{ __html: listData }}
                            />
                          );
                        })}
                      </ul>
                      <Typography sx={styles.listBottomText}>
                        {item.listBottomText}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} xl={6} lg={6} mb={5}>
                    <Typography
                      mb={1}
                      variant="h4"
                      align="left"
                      sx={{
                        fontFamily: "Gilroy-Bold",
                        fontSize: "32px",
                      }}
                    >
                      {item.title}
                    </Typography>
                    <Box sx={{ position: "relative" }}>
                      <Box>
                        <img
                          src={item.mainBgImg}
                          width="100%"
                          height="100%"
                          alt="img"
                        />
                      </Box>
                      {!isSmallScreen && (
                      <Box
                        sx={{
                          position: "absolute",
                          bottom: -20,
                          left: -30,
                          // width: isSmallScreen ? "100%" : "383px",
                          height: "297px",
                          opacity: 1,
                          pointerEvents: "none",
                        }}
                      >
                        <img
                          src={item.positionAbImg}
                          width="100%"
                          height="100%"
                          alt="img"
                        />
                      </Box>
                      )}
                    </Box>
                  </Grid>
                </>
              )}
            </Grid>
          );
        })}
      </Box>
    </LayoutWrapper>
  );
};
export default PicsCarServices;

const styles = {
  listHeading: {
    fontFamily: "Gilroy-Medium",
    fontSize: "16px",
    color: "#5C5F5E",
    lineHeight: "30px",
    fontWeight: 400,
  },
  listStyle: {
    fontFamily: "Gilroy-Medium",
    fontSize: "16px",
    color: "#5C5F5E",
    lineHeight: "30px",
    fontWeight: 400,
  },
  listBottomText: {
    fontFamily: "Gilroy-Medium",
    fontSize: "16px",
    color: "#5C5F5E",
    lineHeight: "30px",
    fontWeight: 400,
  },
};
{
  /* <Container maxWidth="xl">
        {carFittingData?.map((item, index) => (
          <Grid
            container
            justifyContent="center"
            sx={{ transform: item.transform }}>
            <Grid item key={index} xs={12} sm={12} md={6} xl={12}>
              
              <Typography
                variant="h4"
                align="left"
                sx={{
                  fontFamily: "Gilroy-Bold",
                  fontSize: "32px",
                  py: "30px",
                  transform: item.transform,
                  textAlign: item.textAlign,
                }}
              >
                {item.title}
              </Typography>

              <div style={{ position: "relative" }}>
                <img
                  src={item.smallpic}
                  alt={`Image ${index}`}
                  width="100%"
                  height="446px"
                />
                {!isSmallScreen && (
                  <img
                    src={SmallPic}
                    alt="Small Pic"
                    style={{
                      position: "absolute",
                      top: 180,
                      right: -25,
                      width: isSmallScreen ? "100%" : "383px",
                      height: "297px",
                      opacity: 1,
                      pointerEvents: "none",
                    }}
                  />
                )}
              </div>

            </Grid>

            <Grid item sx={{
              py: { xs: 0, sm: 0, md: "80px" },
            }} xs={12} sm={12} md={6}>
              <Typography
                variant="body1"
                align="left"
                sx={{
                  fontFamily: "Gilroy-Medium",
                  fontSize: "16px",
                  py: "30px",
                  color: "black",
                  transform: item.transform,
                }}
              >
                <ul style={{ paddingTop: "8px" }}>
                  {item.content1}
                  <li style={{ paddingTop: "8px" }}>
                    <span
                      style={{
                        color: "#5C5F5E",
                        fontFamily: "Gilroy-Bold",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: 400,
                      }}
                    >
                      {item.content2}
                    </span>{" "}
                    {item.content3}
                  </li>
                  <li style={{ paddingTop: "8px" }}>
                    <span
                      style={{
                        color: "#5C5F5E",
                        fontFamily: "Gilroy-Bold",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: 400,
                      }}
                    >
                      {item.content4}
                    </span>{" "}
                    {item.content5}
                  </li>
                  <li style={{ paddingTop: "8px" }}>
                    <span
                      style={{
                        color: "#5C5F5E",
                        fontFamily: "Gilroy-Bold",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: 400,
                      }}
                    >
                      {item.content6}
                    </span>
                    {item.content7}
                  </li>
                  <li style={{ paddingTop: "8px" }}>
                    <span
                      style={{
                        color: "#5C5F5E",
                        fontFamily: "Gilroy-Bold",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: 400,
                      }}
                    >
                      {item.content8}
                    </span>{" "}
                    {item.content9}
                  </li>
                  {item.content10}
                </ul>{" "}
              </Typography>
            </Grid>

          </Grid>
        ))}
      </Container> */
}
