import React from 'react'
import PersonalHero from './personal-hero/personal-hero'
import PersonalCard from './personal-card/personal-card'

const PersonalAcccount = () => {
  return (
    <>
   <PersonalHero/>
   <PersonalCard/></>
  )
}

export default PersonalAcccount