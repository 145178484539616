import checkIcon from "../../assets/icons/checkIcon.svg";
import barIcon from "../../assets/icons/barIcon.svg";
import crossIcon from "../../assets/icons/crossIcon.svg";
import { Box, Typography } from "@mui/material";

export const plansMockData = [
  {
    planType: "",
    planBtnBackground: "#EDF3F1",
    plans: [
      {
        trial: <Box sx={{height:"27px"}} ><Typography></Typography></Box>,
        title: "Features",
        features: [
          { feature: "Duration" },
          { feature: "Modules Included/Available" },
          { feature: "User Limit" },
          { feature: "PCN Management" },
          { feature: "Parking Services" },
          { feature: "Charging Stations" },
          { feature: "Insurance Services" },
          { feature: "Motor Dealer Services" },
          { feature: "Full access to all functionalities" },
          { feature: "Email and chat support" },
          { feature: "Knowledge base and user guides" },
          { feature: "Performance reports and analytics" },
          { feature: "Integration with third-party tools" },
          { feature: "Customisable user roles and permissions" },
          { feature: "Regular feature updates and enhancements" },
          { feature: "Data migration assistance and training sessions" },
          { feature: "Credit card required" },
        ],
      },
    ],
  },
  {
    planType: "Free Plan",
    planBtnBackground: "#21B07D",
    plans: [
      {
        trial: "Free 30-Days Trial",
        title: "£0",
        descriptions: [
          { icon: barIcon, description: "30 days" },
          { icon: barIcon, description: "All modules included" },
          { icon: barIcon, description: "2 Users per module" },
          { icon: barIcon, description: "Manage up to 15 PCNs" },
          { icon: barIcon, description: "Reserve up to 5 parking spots" },
          { icon: barIcon, description: "Full access" },
          { icon: barIcon, description: "Full access" },
          { icon: barIcon, description: "Full access" },
          { icon: checkIcon, description: "Yes" },
          { icon: checkIcon, description: "Yes" },
          { icon: checkIcon, description: "Yes" },
          { icon: checkIcon, description: "Yes" },
          { icon: crossIcon, description: "No" },
          { icon: crossIcon, description: "No" },
          { icon: crossIcon, description: "No" },
          { icon: crossIcon, description: "No" },
          { icon: crossIcon, description: "No" },
        ],
      },
    ],
  },
  {
    planType: "Enterprise Plan",
    planBtnBackground: "#21B07D",
    plans: [
      {
        trial: "For Large team & Corporations",
        title: "£30/Per Month",
        descriptions: [
          { icon: barIcon, description: "Monthly" },
          {
            icon: barIcon,
            description: "All modules included",
          },
          {
            icon: barIcon,
            description: "Based on the organisation's needs",
          },
          {
            icon: barIcon,
            description: "Unlimited PCNs tracking & Management",
          },
          {
            icon: barIcon,
            description: "Unlimited parking spots",
          },
          { icon: barIcon, description: "Advanced access" },
          {
            icon: barIcon,
            description: "Integration with top providers",
          },
          {
            icon: barIcon,
            description: "Enhanced vehicle Buying, Selling",
          },
          { icon: checkIcon, description: "Yes" },
          { icon: checkIcon, description: "Yes" },
          { icon: checkIcon, description: "Yes" },
          {
            icon: checkIcon,
            description: "Advanced Reporting & Analytics",
          },
          { icon: checkIcon, description: "Yes" },
          {
            icon: checkIcon,
            description: "Yes",
          },
          {
            icon: checkIcon,
            description: "Yes",
          },
          {
            icon: checkIcon,
            description: "Yes",
          },
          { icon: checkIcon, description: "Not Specific" },
        ],
      },
    ],
  },
];
