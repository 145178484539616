import React from "react";
import Footer from "./footer/footer";
import Header from "./header/header";
import { Container } from "@mui/system";

function Layout({ children }) {
  return (
    <div style={{ background: "#EDF3F1" }}>
      {/* <header> */}
      <Header />
      {/* </heder>a */}
      <main>{children}</main>
      <footer>
        <Footer />
      </footer>
    </div>
  );
}

export default Layout;
