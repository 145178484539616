import React from "react";
import { Grid, Box, Container } from "@mui/material";
import sol_hero_bg_img from "../../../assets/pricingblog.png";
import { Typography } from "@mui/material";

const dotStyle = {
  color: "#21B07D",
  position: "relative",
  left: "2px",
};
const CareerHero = () => {
  return (
    <>
      <div
        style={{
          background: `url(${sol_hero_bg_img})`,
          backgroundRepeat: "no-repeat",
          width: "100%",
          minHeight: "590px",
          position: "relative",
          zIndex: 2,
          backgroundSize: "cover",
        }}
      >
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            backgroundRepeat: "no-repeat",
            mixBlendMode: "multiply",

            left: 0,
            top: 0,
          }}
        ></div>

        <Container maxWidth="xl">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "Center",
                  justifyContent: "center",
                  marginTop: {xl:"201px",lg:"201px",md:"207px",sm:"180px",xs:"190px"},
                }}
              >
                <Typography
                  sx={{
                    color: "#FFFFFF",
                    fontFamily: "Gilroy-Bold",
                    fontSize: { sm: "56px", xs: "36px" },
                    fontStyle: "normal",
                    fontWeight: 700,
                    zIndex: 1,
                  }}
                >
                  Career Openings
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Gilroy-Bold",
                    fontSize: { lg: "56px", xs: "36px" },
                    fontStyle: "normal",
                    fontWeight: 700,
                    color: "#21B07D",
                    zIndex: 10,
                    position: "relative",
                  }}
                >
                  Join Us
                </Typography>
                <Typography
                  sx={{
                    color: "rgba(255, 255, 255, 0.70)",
                    fontFamily: "Gilroy-Medium",
                    fontSize: "20px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "32px",
                    zIndex: 1,
                    mt: 2,
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  We’re always looking for creative, talented self-starters to
                  join the Travel Help Squad family.
                  <br /> Check out our open roles below and fill out an
                  application
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
};

export default CareerHero;
