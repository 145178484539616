import { Box } from '@mui/material'
import React from 'react'
import PrivacyPolicyHero from './privacy-policy-hero/privacy-policy-hero'
import PrivacyPolicyContent from './privacy-policy-content/privacy-policy-content'

const PrivacyPolicy = () => {
  return (
    <Box>
      <PrivacyPolicyHero/>
      <PrivacyPolicyContent/>
    </Box>
  )
}

export default PrivacyPolicy