// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
}

code {
}

::-webkit-scrollbar {
  width: 3px;

  background-color: #f5f5f5;

  border-radius: 10px;

  height: 4px;

  cursor: pointer;
}

::-webkit-scrollbar-thumb {
  background-color: gray;

  border-radius: 10px;

  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;AACX;;AAEA;AACA;;AAEA;EACE,UAAU;;EAEV,yBAAyB;;EAEzB,mBAAmB;;EAEnB,WAAW;;EAEX,eAAe;AACjB;;AAEA;EACE,sBAAsB;;EAEtB,mBAAmB;;EAEnB,eAAe;AACjB","sourcesContent":["body {\n  margin: 0;\n}\n\ncode {\n}\n\n::-webkit-scrollbar {\n  width: 3px;\n\n  background-color: #f5f5f5;\n\n  border-radius: 10px;\n\n  height: 4px;\n\n  cursor: pointer;\n}\n\n::-webkit-scrollbar-thumb {\n  background-color: gray;\n\n  border-radius: 10px;\n\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
