import { makeStyles } from "@mui/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import React, { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import Link from "@mui/material/Link";
import MenuItem from "@mui/material/MenuItem";
import "./header.style.css";
import logoimg from "../../../assets/logo.png";
import Container from "@mui/material/Container";
import Menu from "@mui/material/Menu";
import ListItemText from "@mui/material/ListItemText";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Grid } from "@mui/material";
import { useLocation } from "react-router-dom";

const headersData = [
  {
    label: "Home",
    href: "/",
  },
  {
    label: "About Us",
    href: "/about-us",
  },
  {
    label: "Our Offerings",
    href: "/offer",
  },
 
  {
    label: "Pricing",
    href: "/Pricing",
  },
  {
    label: "Career",
    href: "/career",
  },
  {
    label: "Blogs",
    href: "/blogs",
  },
 
  {
    label: "Get In Touch",
    href: "/get-in-touch",
    subMenu: [
      { label: "Contact Us", href: "/get-in-touch/contact-us" },
      { label: "FAQs", href: "/get-in-touch-faqs" },
    ],
  },
  
];

const useStyles = makeStyles(() => ({
  header: {
    backgroundColor: "rgba(0, 0, 0, 0)",
    height: "80px",
    zIndex: 1000,
  },
  logo: {
    textAlign: "left",
  },
  menuButton: {
    fontFamily: "Open Sans, sans-serif",
    fontWeight: 700,
    size: "18px",
    marginLeft: "auto",
    color: "#ffffff",
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: '26px'
  },
  drawerContainer: {},
  label: {
    color: "#BEC2C1",
    fontFamily: "Gilroy-Medium",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    transition: "all 0.3s",
    textDecoration: "none",
    textTransform: "capitalize",
    display: "inline-block",
  },
  labelHover: {
    color: "green",
  },
}));

export default function Header() {
  const location = useLocation();
  const [isButtonActive, setIsButtonActive] = useState(false);
  const { header, logo, menuButton, toolbar, drawerContainer } = useStyles();
  const classes = useStyles();
  const [isHovered, setIsHovered] = useState(false);

  const buttonStyle = {
    color: isHovered ? "green" : "#BEC2C1",
    backgroundColor: isHovered ? "transparent" : "",
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const [solutionsAnchorEl, setSolutionsAnchorEl] = useState(null);
  const [activeSolution, setActiveSolution] = useState(""); // Track active solution

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSolutionsMenuOpen = (event) => {
    setSolutionsAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSolutionsMenuClose = () => {
    setSolutionsAnchorEl(null);
  };

  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  const { mobileView, drawerOpen } = state;

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  const displayDesktop = () => {
    return (
      <Container maxWidth="xl">
        <Grid container>
          <Grid item xs={12}>
            <Toolbar className={toolbar}>
              {femmecubatorLogo}
              <div>{getMenuButtons()}</div>
            </Toolbar>
          </Grid>
        </Grid>
      </Container>
    );
  };

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));

    return (
      <Container maxWidth="xl">
        <Toolbar className={toolbar}>
          <div>{femmecubatorLogo}</div>
          <IconButton
            {...{
              edge: "end",
              color: "#ffffff",
              "aria-label": "menu",
              "aria-haspopup": "true",
              onClick: handleDrawerOpen,
              className: `${menuButton} hamburger-icon ${
                drawerOpen ? "close-icon" : ""
              }`,
            }}
          >
            {drawerOpen ? <CloseIcon /> : <MenuIcon />}
          </IconButton>
          <Drawer
            {...{
              anchor: "left",
              open: drawerOpen,
              onClose: handleDrawerClose,
            }}
          >
            <div className={drawerContainer}>{getDrawerChoices()}</div>
          </Drawer>
        </Toolbar>
      </Container>
    );
  };

  const getDrawerChoices = () => {
    return headersData.map(({ label, href }) => {
      return (
        <Link
          {...{
            component: RouterLink,
            to: href,
            color: "inherit",
            style: { textDecoration: "none" },
            key: label,
          }}
        >
          <MenuItem sx={{ color: "#BEC2C1" }}>{label}</MenuItem>
        </Link>
      );
    });
  };

  const femmecubatorLogo = (
    <img src={logoimg} className="logo" alt="logo"></img>
  );

  const getMenuButtons = () => {
    return headersData.map(({ label, href, subMenu }) => {
      const isActive = location.pathname === href;

      if (subMenu) {
        return (
          <div
            key={label}
            className={`${menuButton} ${classes.label} custom-link ${
              isActive || activeSolution ? "active-route" : ""
            }`}
          >
            <Button
              aria-controls="solutions-menu"
              aria-haspopup="true"
              onClick={(event) => {
                handleSolutionsMenuOpen(event);
                setIsButtonActive(!isButtonActive);
              }}
              color="inherit"
              style={{
                backgroundColor: isButtonActive ? "transparent" : "",
                color: isActive ? "#21b07d" : "#BEC2C1",
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center',textTransform:"capitalize" }}>
                <span className={classes.label}>{label}</span>
                <ExpandMoreIcon />
              </div>
            </Button>
            <Menu
              id="solutions-menu"
              anchorEl={solutionsAnchorEl}
              open={Boolean(solutionsAnchorEl)}
              onClose={handleSolutionsMenuClose}
            >
              {subMenu.map((subItem) => (
                <MenuItem
                  key={subItem.label}
                  component={RouterLink}
                  to={subItem.href}
                  onClick={() => handleSubItemClick(subItem.id)}
                >
                  <ListItemText primary={subItem.label} />
                </MenuItem>
              ))}
            </Menu>
          </div>
        );
      } else {
        return (
          <Button
            key={label}
            color="inherit"
            to={href}
            component={RouterLink}
            className={`${menuButton} ${classes.label} custom-link ${
              isActive ? "active-route" : ""
            }`}
            style={{
              color: isActive ? "#21b07d" : "#BEC2C1",
            }}
          >
            {label}
          </Button>
        );
      }
    });
  };

  // Function to handle sub-item click and set active solution
  const handleSubItemClick = (id) => {
    setActiveSolution(id);
    handleSolutionsMenuClose();
  };

  return (
    <header>
      <AppBar
        className={`${header} transparent-header`}
        style={{ position: "absolute" }}
      >
        {mobileView ? displayMobile() : displayDesktop()}
      </AppBar>
    </header>
  );
}
