import PricingSection from "./rentyourspace";
import Pricingpaper from "./PricingbackgroundImage";


function HomePricing() {
  return (
    <div>
      <PricingSection />
      <Pricingpaper/>
    </div>
  );
} 

export default HomePricing;
