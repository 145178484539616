import React from "react";
import {
  Card,
  Typography,
  Container,
  Button,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import law from "../../assets/offers/lawyeres.png";
import car from "../../assets/offers/car.png";
import keyboard from "../../assets/offers/keyboard.png";
import moto from "../../assets/offers/moto.png";
import ganja from "../../assets/offers/ganja.png";
import salvage from "../../assets/offers/salvage.png";
import finace from "../../assets/offers/finace.png";
import fleet from "../../assets/offers/fleet.png";
import buycar from "../../assets/offers/buycar.png";
import register from "../../assets/offers/register.png";
import rent from "../../assets/offers/rent.png";
import { useNavigate } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import { EffectCoverflow,Autoplay } from 'swiper/modules';
import LayoutWrapper from "../lay-out/layout-wrapper";


const cardData = [
  {
    id: 1,
    imageUrl: law,
    title: "Lawyers",

    text: `Making Parking Enforcement Easier with Smart Digital Solutions.`,
    textone:
      "Use smart digital tools to make parking enforcement easier. This helps issue tickets, keep an eye on things, and manage parking more efficiently, making everyone's experience better.",
    path: "/lawyer",
  },

  {
    id: 2,
    imageUrl: car,
    title: "Charging Stations",

    text: "We are committed to delivering the highest customer  and support levels.",
    textone:
      "At our core, we are unwavering in our commitment to provide the highest levels of customer service and support, guaranteeing your satisfaction and a consistently exceptional experience with us.",
    path: "/charging-stations",
  },
  {
    id: 3,
    imageUrl: keyboard,
    title: "Insurance",

    text: "From start to finish, our cloud platform simplifies penalty charge notice management.",
    textone:
      "From the beginning to the end, our cloud platform makes penalty charge notice management a breeze, ensuring a straightforward and efficient process for seamless operations.",
    path: "/Insurance",
  },


  {
    id: 5,
    imageUrl: moto,
    title: "Motor Dealers",

    text: "We are committed to delivering the highest customer  and support levels.",
    textone:
      "At our core, we are unwavering in our commitment to provide the highest levels of customer service and support, guaranteeing your satisfaction and a consistently exceptional experience with us.",
    path: "/motor-dealer",
  },
  {
    id: 6,
    imageUrl: ganja,
    title: "Motor Auctioneers",

    text: "From start to finish, our cloud platform simplifies penalty charge notice management.",
    textone:
      "From the beginning to the end, our cloud platform makes penalty charge notice management a breeze, ensuring a straightforward and efficient process for seamless operations.",
    path: "/motor-auctioneers",
  },
  {
    id: 7,
    imageUrl: salvage,
    title: "Salvage Dealers",

    text: `Making Parking Enforcement Easier with Smart Digital Solutions.`,
    textone:
      "Use smart digital tools to make parking enforcement easier. This helps issue tickets, keep an eye on things, and manage parking more efficiently, making everyone's experience better.",
    path: "/salvage-dealers",
  },

  {
    id: 8,
    imageUrl: finace,
    title: "Finance Companies",

    text: "We are committed to delivering the highest customer  and support levels.",
    textone:
      "At our core, we are unwavering in our commitment to provide the highest levels of customer service and support, guaranteeing your satisfaction and a consistently exceptional experience with us.",
    path: "/finance-companies",
  },
  {
    id: 9,
    imageUrl: fleet,
    title: "Fleet Operators",

    text: "From start to finish, our cloud platform simplifies penalty charge notice management.",
    textone:
      "From the beginning to the end, our cloud platform makes penalty charge notice management a breeze, ensuring a straightforward and efficient process for seamless operations.",
    path: "/fleet-operators",
  },
  {
    id: 10,
    imageUrl: buycar,
    title: "Car Buying Services",
    text: `Making Parking Enforcement Easier with Smart Digital Solutions.`,
    textone:
      "Use smart digital tools to make parking enforcement easier. This helps issue tickets, keep an eye on things, and manage parking more efficiently, making everyone's experience better.",
    path: "/car-buying-services",
  },

  {
    id: 11,
    imageUrl: register,
    title: "Register Charging Stations",

    text: "We are committed to delivering the highest customer  and support levels.",
    textone:
      "At our core, we are unwavering in our commitment to provide the highest levels of customer service and support, guaranteeing your satisfaction and a consistently exceptional experience with us.",
    path: "/register-charging-stations",
  },
  {
    id: 12,
    imageUrl: rent,
    title: "Rent Your Space",

    text: "From start to finish, our cloud platform simplifies penalty charge notice management.",
    textone:
      "From the beginning to the end, our cloud platform makes penalty charge notice management a breeze, ensuring a straightforward and efficient process for seamless operations.",
    path: "/register-charging-stations",
  },
];

const useStyles = makeStyles(() => ({
  cardContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "stretch",
  },
  card: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    borderRadius: "20px",
  },
}));

const CardSection = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleViewOfferCard = (path) => {
    console.log("navigate to", `/offer${path}`);
    navigate(`/offer${path}`);
  };
  return (
    <LayoutWrapper background='#EDF3F1'>
      <Typography
        variant="h1"
        sx={{
          color: "rgba(33, 176, 125, 1)",
          fontFamily: "Gilroy-Bold",
          fontSize: "40px",
          fontStyle: "normal",
          fontWeight: 600,
          textAlign: "center",
          marginTop: "105px",
          marginBottom: "105px",
        }}
      >
        Our Offerings
      </Typography>
      <Swiper
        effect={'coverflow'}
        grabCursor={true}
        centeredSlides={false}
        slidesPerView={3}
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
        }}
        pagination={true}
        modules={[EffectCoverflow,Autoplay]}
        className="mySwiper"
      >
        {cardData.map((card) => (
          <SwiperSlide key={card.id}>
            <Card
              className={classes.card}
              sx={{
                borderRadius: "20px",
                position: "relative",
                width: "100%",
                background: "none",

              }}
            >
              <img
                src={card.imageUrl}
                alt={`Card ${card.id}`}
                style={{
                  width: "100%",
                  height: "327px",
                  borderRadius: "20px",
                }}
              />
              <Button
                size="small"
                sx={{
                  position: "absolute",
                  top: "92%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  padding: "16px 32px",
                  justifyContent: "center",
                  width: "100%",
                  alignItems: "center",
                  borderRadius: "6px",
                  background: "#21B07D",
                  color: "#ffffff",
                  transition: "background-color 0.3s",
                  "&:hover": {
                    backgroundColor: "#1A8B60",
                  },
                }}
                onClick={() => handleViewOfferCard(card.path)}
              >
                <Typography
                  sx={{
                    color: "#FFF",
                    textAlign: "center",
                    fontFamily: "Gilroy-SemiBold",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "normal",
                    letterSpacing: "0.8px",
                    textTransform: "capitalize",
                    "@media (max-width: 600px)": {
                      fontSize: "14px",
                    },
                  }}
                >
                  {card.title}
                </Typography>{" "}
              </Button>
            </Card>
          </SwiperSlide>
        ))}
      </Swiper>
    </LayoutWrapper>

  );
};

export default CardSection;
