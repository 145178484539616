import {
  Container,
  Grid,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Box,
} from "@mui/material";
import React from "react";
import carparking from "../../../../assets/solutions/carparking.png";
import laptop from "../../../../assets/solutions/laptop.png";
import radio from "../../../../assets/solutions/radio.png";
import meat from "../../../../assets/solutions/meat.png";
import chit from "../../../../assets/solutions/chit.png";
import location from "../../../../assets/solutions/location.png";
import bill from "../../../../assets/solutions/bill.png";
import dashboard from "../../../../assets/solutions/dashboard.png";
import criditmach from "../../../../assets/solutions/criditmach.png";
import steel from "../../../../assets/solutions/steel.png";
import mot from "../../../../assets/solutions/mot.png";
import gdpr from "../../../../assets/solutions/gdpr.png";

const CommercialAccountCardsData = [
  {
    description:
      "Centralize parking and traffic ticket management on one platform.",
    solimg: carparking,
  },
  {
    description: "Dashboards that provide real-time monitoring​​",
    solimg: laptop,
  },
  {
    description: "Seamless integration with your existing technology​​",
    solimg: radio,
  },
  {
    description: "Add employees and vehicles to your account in moments​​",
    solimg: meat,
  },
  {
    description: "Minimize Admin and Management tasks​",
    solimg: chit,
  },
  {
    description: "Tailor-made parking solutions adapted to all businesses​​",
    solimg: location,
  },
  {
    description: "Create VAT invoices effortlessly.​",
    solimg: bill,
  },
  {
    description: "Intuitive insight and reporting tools​",
    solimg: dashboard,
  },

  {
    description: "GDPR Compliant",
    solimg: gdpr,
  },
];

const CommercialAccountCards = () => {
  return (
    <Container maxWidth="xl">
      <Grid
        container
        sx={{
          p: "60px",
          borderRadius: "20px",
          background: "#EDF3F1",
          boxShadow: "0px 34px 74px -12px rgba(0, 0, 0, 0.16)",
          position: "relative",
          zIndex: 10,
          mt: -10,
        }}
      >
        <Grid item xs={12}>
          <Typography
            sx={{
              color: "#21B070",
              fontFamily: "Gilroy-SemiBold",
              fontSize: "24px",
              fontWeight: 400,
              mb: "36px",
            }}
          >
            Corporate Account
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {/* Card Section */}
          <Grid container spacing={3}>
            {CommercialAccountCardsData.map((item, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Card
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    borderRadius: "20px",
                    background: "#fff",
                  }}
                >
                  <Box sx={{ p: "16px" }}>
                    <CardMedia
                      component="img"
                      // height="140"
                      image={item.solimg}
                      alt="Card Image"
                    />{" "}
                  </Box>
                  <CardContent>
                    <Typography
                      sx={{
                        textAlign: "center",
                        color: "#5C5F5E",
                        fontSize: "18px",
                        fontWeight: 400,
                        fontFamily: "Gilroy-SemiBold",
                      }}
                    >
                      {item.description}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default CommercialAccountCards;
