import {
  Container,
  Grid,
  Typography,
  CardContent,
  CardMedia,
  Box,
  MenuItem,
  Select,
  FormControl,
  TextField,
  InputAdornment,
  CardHeader,
  Button,
} from "@mui/material";
import * as React from "react";
import { styled } from "@mui/material/styles";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import { red } from "@mui/material/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Card from "@mui/material/Card";
import MotorDealersImage from "../../../../../assets/motordealers.png";

const MotorDealersCards = () => {
  const data = [
    {
      image: MotorDealersImage,
      content: (
        <div>
          <ul style={{ paddingTop: "8px", listStyleType: "none" }}>
            Discover the Perfect Ride with Travel Help Squad's Motor Dealers
            Feature
            <li style={{ paddingTop: "8px" }}>
              <span
                style={{
                  color: "#5C5F5E",
                  fontFamily: "Gilroy-Bold",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 400,
                }}
              >
                ​Find Your Dream Car:{" "}
              </span>{" "}
              Travel Help Squad connects you to trusted dealerships, making it
              effortless to find your ideal vehicle.
            </li>
            <li style={{ paddingTop: "8px" }}>
              <span
                style={{
                  color: "#5C5F5E",
                  fontFamily: "Gilroy-Bold",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 400,
                }}
              >
                ​Buying Process:{" "}
              </span>{" "}
              Say goodbye to hassles. We simplify the car-buying experience, so
              you can hit the road faster.
            </li>
            <li style={{ paddingTop: "8px" }}>
              <span
                style={{
                  color: "#5C5F5E",
                  fontFamily: "Gilroy-Bold",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 400,
                }}
              >
                Extensive Inventory:{" "}
              </span>
              Explore a wide range of cars, from luxury to economy. Your dream
              ride is just a few taps away.​
            </li>
            <li style={{ paddingTop: "8px" }}>
              <span
                style={{
                  color: "#5C5F5E",
                  fontFamily: "Gilroy-Bold",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 400,
                }}
              >
                ​Trusted Dealers:{" "}
              </span>{" "}
              We partner with reputable dealers, ensuring a safe and reliable
              buying experience every time.
            </li>
            Travel Help Squad's puts you in the driver's seat. Get started and
            discover your next car today!​
          </ul>{" "}
        </div>
      ),
    },
  ];

  return (
    <>
    <Container maxWidth="xl">
      <Grid
        container
        sx={{
          p: { sm: "15px", xs: "0px" },
          borderRadius: "20px",
          background: "#EDF3F1",
          boxShadow: "0px 34px 74px -12px rgba(0, 0, 0, 0.16)",
          position: "relative",
          zIndex: 10,
          mt: -10,
        }}
      >
        <Grid item xs={12} lg={12}>
          {data.map((item, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "left",
                p: { sm: 0, xs: 2 },
                width: "100%",
              }}
            >
              <img src={item.image} alt={`Image ${index}`} width="100%" />
              <Typography
                sx={{
                  fontFamily: "Gilroy-Medium",
                  fontSize: "16px",
                  fontWeight: 400,
                  lineHeight: "30px",
                  textAlign: "left",
                  color: "#5C5F5E",
                  marginTop: "10px",
                }}
              >
                {item.content}
              </Typography>
            </Box>
          ))}
        </Grid>
      </Grid>
    </Container>
  </>
  );
};

export default MotorDealersCards;
