import {
  Container,
  Grid,
  Typography,
  CardContent,
  CardMedia,
  Box,
  MenuItem,
  Select,
  FormControl,
  TextField,
  InputAdornment,
  CardHeader,
  Button,
} from "@mui/material";
import * as React from "react";
import { styled } from "@mui/material/styles";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import { red } from "@mui/material/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Card from "@mui/material/Card";
import FleetOperatorImage from "../../../../../assets/fleetoperatorcard.png";

const FleetOperationCard = () => {
  const data = [
    {
      image: FleetOperatorImage,
      content: (
        <div>
          <ul style={{ paddingTop: "8px", listStyleType: "none" }}>
            Efficiency at Your Fingertips with Travel Help Squad's Fleet
            Operations Feature{" "}
            <li style={{ paddingTop: "8px" }}>
              <span
                style={{
                  color: "#5C5F5E",
                  fontFamily: "Gilroy-Bold",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 400,
                }}
              >
                Centralised Control:{" "}
              </span>{" "}
              Manage your entire fleet from a single, intuitive platform, saving
              time and resources.​​{" "}
            </li>
            <li style={{ paddingTop: "8px" }}>
              <span
                style={{
                  color: "#5C5F5E",
                  fontFamily: "Gilroy-Bold",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 400,
                }}
              >
                Real-Time Tracking:{" "}
              </span>{" "}
              Stay in the know with live GPS tracking for each vehicle, ensuring
              optimised routes and timely deliveries.
            </li>
            <li style={{ paddingTop: "8px" }}>
              <span
                style={{
                  color: "#5C5F5E",
                  fontFamily: "Gilroy-Bold",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 400,
                }}
              >
                ​Streamlined Maintenance:{" "}
              </span>
              Schedule and monitor maintenance effortlessly, reducing downtime
              and costly repairs.​Data-Driven Insights: Access valuable insights
              and analytics to make informed decisions for your fleet's
              performance and cost-efficiency.​
            </li>
            <li style={{ paddingTop: "8px" }}>
              <span
                style={{
                  color: "#5C5F5E",
                  fontFamily: "Gilroy-Bold",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 400,
                }}
              >
                ​Data-Driven Insights:{" "}
              </span>
              Access valuable insights and analytics to make informed decisions
              for your fleet's performance and cost-efficiency.​
            </li>
            Take charge of your fleet's success. Register with Travel Help Squad
            today and experience seamless fleet management like never before!​{" "}
          </ul>{" "}
        </div>
      ),
    },
  ];

  return (
    <>
      <Container maxWidth="xl">
        <Grid
          container
          sx={{
            p: { sm: "15px", xs: "0px" },
            borderRadius: "20px",
            background: "#EDF3F1",
            boxShadow: "0px 34px 74px -12px rgba(0, 0, 0, 0.16)",
            position: "relative",
            zIndex: 10,
            mt: -10,
          }}
        >
          <Grid item xs={12} lg={12}>
            {data.map((item, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "left",
                  p: { sm: 0, xs: 2 },
                  width: "100%",
                }}
              >
                <img src={item.image} alt={`Image ${index}`} width="100%" />
                <Typography
                  sx={{
                    fontFamily: "Gilroy-Medium",
                    fontSize: "16px",
                    fontWeight: 400,
                    lineHeight: "30px",
                    textAlign: "left",
                    color: "#5C5F5E",
                    marginTop: "10px",
                  }}
                >
                  {item.content}
                </Typography>
              </Box>
            ))}
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default FleetOperationCard;
