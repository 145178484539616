import React from "react";
import { Grid, Box, Container } from "@mui/material";
import offers_hero_bg_img from "../../../assets/offers/offers-hero-img.png";
import { Typography } from "@mui/material";
import './offer-hero.css'
import OfferSearchbar from "../offer-searchbar/offer-searchbar";

const OfferHero = () => {
  return (
    <>
      <div
        style={{
          background: `url(${offers_hero_bg_img})`,
          backgroundRepeat: "no-repeat",
          minHeight: "489px",
          position: "relative",
          zIndex: 1,
          backgroundSize: "cover",
        }}
      >
        <div
          style={{
            position: "absolute",
            width: "100%",
            // minHeight: "489px",
            height:"100%",
            background:
              // "linear-gradient(262deg, rgba(0, 0, 0, 0.06) 13.81%, #000 106.26%)",
              "linear-gradient(262deg, rgba(0, 0, 0, 0.6) 13.81%, #000 106.26%)",
          }}
        ></div>

        <Container  maxWidth="xl">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "center",
                  width: '100%',
                  mt: '221px',
                  paddingLeft:'24px',
                }}
              >
                <Typography
                  sx={{
                    color: "#FFFFFF",
                    fontFamily: "Gilroy-Bold",
                    fontSize: "56px",
                    fontStyle: "normal",
                    fontWeight: 700,
                    zIndex: 1,
                  }}
                >
                  Our Offerings
                </Typography>
                <Typography
                  sx={{
                    color: "rgba(255, 255, 255, 0.70)",
                    fontFamily: "Gilroy-Medium",
                    fontSize: "20px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "32px",
                    zIndex: 1,
                    mt: 2,
                    padding: { xs: "12px 24px", sm: "12px 24px", md: "12px 24px", lg: "0px", xl: "0px"}
                  }}
                >
                  We try to get the best and most convenient options and deals <br />for you through our one single comprehensive platform
                </Typography>
              </Box>
            </Grid>
           
          </Grid>
        </Container>
      </div>
    </>
  );
};

export default OfferHero;
