import React from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Box, Typography, Container, Paper, Button } from "@mui/material";
import BlogImageCent from "../../assets/blogimagecent.png";
import DrivingImage from "../../assets/drivingblogimage.png";
import DrivingOffenceImage from "../../assets/drivingoffenceimage.png";
import PenaltyChargeImage from "../../assets/penaltychargeimage.png";
import FitingService from "../../assets/fittingservice.png";
import MobileFitting from "../../assets/mobilefitting.png";
import BuyandSell from "../../assets/buyandsell.png";
import { textAlign } from "@mui/system";
import "./blogs.css";
import Avatar from '@mui/material/Avatar';
import CarTyres from "../../assets/cartyres.png";
import CarTyresone from "../../assets/suvtyres.png";
import CarTyrestwo from "../../assets/4tyres.png";
import CarTyresthree from "../../assets/vantyres.png";
import CarTyresfour from "../../assets/motorbikestyre.png";
import CarTyresfive from "../../assets/puncturerepair.png";



const categoriesData = [
    {
      name: "Car Tyres",
      avatarSrc: CarTyres,
    },
    {
      name: "SUV Tyres",
      avatarSrc: CarTyresone,
    },
    {
      name: "4x4 tyres",
      avatarSrc: CarTyrestwo,
    },
    {
      name: "Van Tyres",
      avatarSrc: CarTyresthree,
    },
    {
      name: "Motorbike Tyres",
      avatarSrc: CarTyresfour,
    },
    {
      name: "Puncture Repairs",
      avatarSrc: CarTyresfive,
    },
  ];

const PicsCarServices = () => {
  return (
    <Container maxWidth="lg">
    <Box sx={{ paddingTop: "80px", px: { lg: "88px", xs: "0px" } }}>
      <Grid container justifyContent="center">
        <Typography
          variant="h1"
          sx={{
            color: "rgba(33, 176, 125, 1)",
            fontFamily: "Gilroy-Bold",
            fontSize: "40px",
            fontStyle: "normal",
            fontWeight: 600,
            textAlign: "center",
            marginTop: "105px",
            marginBottom: "105px",
          }}
        >
          Categories
        </Typography>
      </Grid>
      <Grid container spacing={3}>
        {categoriesData.map((category, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Box display="flex" alignItems="center" sx={{background:"#FFFFFF80, #FFFFFF00"}}>
              <Avatar alt={category.name} src={category.avatarSrc} />
              <Typography variant="h6" sx={{ marginLeft: "16px" }}>
                {category.name}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  </Container>
  );
};

export default PicsCarServices;
