import React, { useState } from "react";
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  Snackbar,
  Typography,
} from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import MuiAlert from "@mui/material/Alert";
import LayoutWrapper from "../../lay-out/layout-wrapper";
import emailIcon from "../../../assets/icons/emailIcon.svg";
import addressIcon from "../../../assets/icons/addressIcon.svg";
import { CustomTextField } from "../../../hook-form";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";


const validationSchema = Yup.object().shape({
  name: Yup.string().required("Field is required"),
  email: Yup.string().email().required("Field is required"),
  phoneNumber: Yup.string().required("Field is required"),
  query: Yup.string().required("Field is required"),
});

const contactDefaultValues = {
  name: "",
  email: "",
  phoneNumber: "",
  query: "",
};

const ContactUsForm = () => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [messageVariant, setMessageVariant] = useState(
    "error" | "info" | "success" | "warning"
  );
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: contactDefaultValues,
  });

  const { handleSubmit } = methods;

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const onSubmit = (data) => {
    console.log(data);
    postUser(data);
  };

  const postUser = async (data) => {
    try {
      const response = await fetch(
        "https://gateway-stg.airapplecart.co.uk/enquiries",
        {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            "x-api-key":
              "67d3dfa528d8949e4d746f32c824bf1c:7c5604e825d535288190e09f74de14d05b4279e889fddd25e70785355489e2b4eb32d5ed07a36b3a21a9567ce79595dca33a4f05856e6a6add9c20bc9dc745fb17270b4925575a7a0138e7da80d26438052425fb1fab0db304ee8eafccbee8a1",
          },
          body: JSON.stringify(data),
        }
      );
      if (response.ok) {
        const responseData = await response.json();
        if (responseData.statusCode === 201) {
          setMessageVariant("success");
          setSnackbarMessage(responseData.message);
          setSnackbarOpen(true);
          methods.reset();
        } else if (responseData.error) {
          setError(responseData.error);
          setSnackbarMessage(responseData.error);
          setSnackbarOpen(true);
        } else {
          setSnackbarMessage("API is not responding");
          setSnackbarOpen(true);
        }
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ width: "90%", margin:"0 auto"}}>
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <LayoutWrapper background="#EDF3F1">
          <Grid
            container
            sx={{
              p: "60px",
              borderRadius: "20px",
              background: "#EDF3F1",
              boxShadow: "0px 34px 74px -12px rgba(0, 0, 0, 0.16)",
              position: "relative",
              zIndex: 10,
              mt: -10,
            }}
            spacing={2}
          >
            <Grid item xs={12} xl={12} mb={1}>
              <CustomTextField
                name="name"
                label="Name"
                placeholder="Enter your name"
                fullWidth
                disabled={false}
                multiline={false}
              />
            </Grid>

            <Grid item xs={12} xl={6} mb={1}>
               <CustomTextField
                name="phoneNumber"
                label="Phone Number"
                placeholder="Enter your phone number"
                fullWidth
                disabled={false}
                multiline={false}
                InputProps={{
              startAdornment: (
                <InputAdornment position="start" style={{ color: "#4B5563" }}>
                  UK
                  <KeyboardArrowDownIcon/>
                </InputAdornment>
              ),
            }}
              />
            </Grid>
            <Grid item xs={12} xl={6} mb={1}>
            <CustomTextField
                name="email"
                label="Email"
                placeholder="Enter email"
                fullWidth
                disabled={false}
                multiline={false}
              />
            </Grid>

            <Grid item xs={12} xl={12} mb={1}>
            <CustomTextField
                name="query"
                label="query"
                placeholder="Enter query"
                fullWidth
                multiline={true}
                minRows={4}
                disabled={false}
              />
            </Grid>
            <Grid item xs={12} xl={12} mt={5}>
              <Button
                type="submit"
                variant="contained"
                sx={{
                  background: "#21B07D ",
                  padding: "11px 18px",
                  borderRadius: "8px",
                  textTransform: "capitalize",
                  color: "#FFF",
                  textAlign: "center",
                  fontFamily: "Gilroy-SemiBold",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "normal",
                  width: "100%",
                  height: "49px",
                  letterSpacing: "0.8px",
                  "&:hover": {
                    background: "#21B07D",
                  },
                }}
              >
                Submit
              </Button>
            </Grid>
            <Grid item xs={12} xl={12} mt={5}>
              <Typography
                display="flex"
                flexWrap="wrap"
                justifyContent="center"
                alignItems="center"
                fontWeight={400}
                fontSize="16px"
                fontFamily="Gilroy-Medium"
                sx={{ width: "100%", textAlign: "center" }}
              >
                Do you have some questions about Travel Help Squad? Visit our
                <Typography
                  variant="span"
                  sx={{ color: "#21B07D", margin: "0px 4px" }}
                >
                 
                  FAQs
                </Typography>
                to find the list of
              </Typography>
              <Typography
                display="flex"
                flexWrap="wrap"
                justifyContent="center"
                alignItems="center"
                fontWeight={400}
                fontSize="16px"
                fontFamily="Gilroy-Medium"
                sx={{ width: "100%", textAlign: "center" }}
              >
                most important questions and answers for you.
              </Typography>
            </Grid>
            <Grid item xs={12} xl={6} mt={5}>
              <Box
                display="flex"
                flexWrap="wrap"
                justifyContent="center"
                alignItems="center"
                gap={2}
              >
                <img src={addressIcon} alt="addressIcon" />
                <Box>
                  <Typography
                    fontWeight={400}
                    fontSize="16px"
                    fontFamily="Gilroy-Medium"
                    color="#21B07D"
                  >
                    Address
                  </Typography>
                  <Typography
                    fontWeight={400}
                    fontSize="13px"
                    fontFamily="Gilroy-Medium"
                    color="#3B4A45"
                  >
                    Address International House, 36-38 Cornhill, London,
                  </Typography>
                  <Typography
                    fontWeight={400}
                    fontSize="13px"
                    fontFamily="Gilroy-Medium"
                    color="#3B4A45"
                  >
                    England, EC3V 3NG.
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} xl={6} mt={5}>
              <Box
                display="flex"
                flexWrap="wrap"
                justifyContent="center"
                alignItems="center"
                gap={2}
              >
                <img src={emailIcon} alt="addressIcon" />
                <Box>
                  <Typography
                    fontWeight={400}
                    fontSize="16px"
                    fontFamily="Gilroy-Medium"
                    color="#21B07D"
                  >
                    Email
                  </Typography>
                  <Typography
                    fontWeight={400}
                    fontSize="13px"
                    fontFamily="Gilroy-Medium"
                    color="#3B4A45"
                  >
                    info@travelhelpsquad.co.uk
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={5000}
            onClose={handleSnackbarClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <MuiAlert elevation={6} variant="filled" severity={messageVariant}>
              {snackbarMessage}
            </MuiAlert>
          </Snackbar>
        </LayoutWrapper>
      </form>
    </FormProvider>
    </Box>
  );
};

export default ContactUsForm;
