import * as React from "react";
import Box from "@mui/material/Box";
import { Button, Container, Grid, Stack, Typography } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import authImg from "../../../../assets/authIcon.svg";
import { useTimer } from "react-timer-hook";
import { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
export default function Verification() {
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const location = useLocation();
  let userId = location?.pathname.split("/")[2];
  
  console.log('dd',userId);
  // const searchParams = new URLSearchParams(location.search);
  // const userId = searchParams.get("userId");

  const initialTimerSeconds = 600;

  const { isRunning, seconds, restart, minutes } = useTimer({
    expiryTimestamp: new Date(),
    onExpire: () => {
      setIsButtonDisabled(false);
    },
  });

  const formattedSeconds = String(seconds).padStart(2, "0");

  const handleResendClick = useCallback(async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_KEY}auth/resend-verificaiton-link`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ userId: userId }),
        }
      );

      const responseData = await response.json();
      restart(new Date().getTime() + initialTimerSeconds * 100);
      toast.success(responseData?.message ?? `Check Your Email.`);
    } catch (error) {
      const errMsg = error?.message;
      toast.error(errMsg);
    }
  }, [restart, userId]);

  useEffect(() => {
    if (!userId) {
      window.history.back();
    } else {
      setIsButtonDisabled(true);
      restart(new Date().getTime() + initialTimerSeconds * 100);
    }
  }, [restart, userId]);
  return (
    <Box
      sx={{
        width: "100%",
        minHeight: "100vh",
        background: "#EDF3F1",
      }}
     
    >
      
      {/* <Grid container spacing={2} sx={{ position: "relative",}}>
            <Grid
            item
            xl={3.5}
            xs={12}
            mt={5}
           
          >
          </Grid>
          <Grid
            item
            xl={8}
            xs={12}
            mt={5}
           
          >
            <Box sx={{margin:"0 auto"}}>
              <img src={authImg} aly="img" />
            </Box>
          </Grid>
          <Grid
            item
            xl={12}
            xs={11}
            mt={5}
          >
            <Box
              mb={4}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              flexDirection="column"
              flexWrap="wrap"
            >
              <CheckCircleIcon
                sx={{ width: "100px", height: "100px", color: "#21B07D" }}
              />
              <Typography
                textAlign="center"
                xs={styles.bottomText}
                mt={4}
                mb={3}
              >
                Let’s get your ID verified
              </Typography>
              <Typography textAlign="center" xs={styles.bottomText}>
                A Verification link has been sent to your email account, please
                click on that link to verify your identity
              </Typography>
              <Typography textAlign="center" xs={styles.bottomText} mb={3}>
                00:60
              </Typography>
            </Box>
          </Grid>
          <Grid item xl={12} xs={11}>
            <Typography textAlign="center">
              If you didn't get the id verification email click on
              <Link style={{ color: "#21B07D" }}>Resend link</Link>
            </Typography>
          </Grid>

         
    
      </Grid> */}

      <Box
        sx={{
          // maxHeight: { xs: "95dvh", sm: "75dvh" },
          //   backgroundImage: `url(${bgImage.src})`,
          backgroundPosition: "right center",
          backgroundSize: 1000,
          pt: {
            sm: 25,
            xs: 20,
          },
          pb: {
            md: 10,
            xs: 3,
          },
        }}
      >
        <Container
          maxWidth={false}
          sx={{
            maxWidth: {
              md: "90%",
              xs: "100%",
            },
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Stack
            alignItems="center"
            direction="column"
            spacing={0}
            textAlign="center"
          >
            {userId ? (
              <Stack
                direction="column"
                alignItems="center"
                spacing={3}
                sx={{
                  pb: {
                    md: 20,
                    sm: 15,
                    xs: 10,
                  },
                }}
              >
                 <CheckCircleIcon
                sx={{ width: "100px", height: "100px", color: "#21B07D" }}
              />
                <Typography textAlign="center" xs={styles.bottomText}
                >
                  A Final Step before your application is submitted
                </Typography>
                <Typography textAlign="center" xs={styles.bottomText}
                >
                  {`Let's Get You Verified`}
                </Typography>
                <Typography textAlign="center" xs={styles.bottomText}>
                A Verification link has been sent to your email account, please
                click on that link to verify your identity
              </Typography>
                {/* Timer component */}
                <Box
                  sx={{
                    width: "150px",
                    height: "80px",
                    backgroundColor: "common.white",
                    borderRadius: "8px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    mt: 3,
                  }}
                >
                  <Typography
                    sx={{
                      color: "#151C48",
                      textAlign: "center",
                      fontSize: "48px",
                      fontWeight: 700,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {minutes} : {formattedSeconds}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: { xs: "column", sm: "row" },
                  }}
                >
                  <Typography textAlign="center" xs={styles.bottomText}
                  >
                    {`If you didn't get the verification email click on`}
                  </Typography>
                  <Button
                    variant="text"
                    disabled={isButtonDisabled || isRunning}
                    onClick={handleResendClick}
                    sx={{
                      mx: 2,
                      textDecoration: "none",
                      fontSize: "1rem",
                      fontWeight: 500,
                      backgroundColor: "common.white",
                      color: "#151C48",
                      "&:hover": {
                        backgroundColor: "#fff",
                      },
                    }}
                  >
                    Resend Link
                  </Button>
                  <Typography textAlign="center" xs={styles.bottomText}
                  >
                    {`after timeout`}
                  </Typography>
                </Box>
              </Stack>
            ) : (
              <Typography
                variant="h5"
                maxWidth={540}
                color="common.white"
                sx={{ lineHeight: "30px" }}
              >
                Something went wrong
              </Typography>
            )}
          </Stack>
        </Container>
      </Box>


      
      <Grid item xl={12} xs={11} display = {"flex"} justifyContent={'center'}>
      <Box sx={{ position: "absolute", bottom: 10 }} >
            <Typography mt={5} textAlign={"center"} sx={styles.copyRightText}>
              Copyright © 2024 Travel Help Squad, Inc. - All Rights Reserved
            </Typography>
          </Box>
          </Grid>
    </Box>
  );
}

const styles = {
  bottomText: {
    fontSize: "16px",
    fontWeight: "700",
    color: "#344054",
    fontFamily: "Open Sans",
  },
  alreadyAccountText: {
    fontSize: "16px",
    fontWeight: "600",
    color: "#1D2939",
    fontFamily: "Open Sans",
  },
  copyRightText: {
    fontSize: "16px",
    fontWeight: "400",
    color: "#344054",
    fontFamily: "Open Sans",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};
