import setsUsApartImg from "../../../assets/about-us-sets-apart-img.png"
import featuresImg from "../../../assets/about-us-features-img.png";

export const aboutUSMockData = [
    `<strong><span style="color:#21B07D;">Charging Stations:</span></strong> Stay powered on the go with our network of charging stations. We provide reliable access to electric vehicle charging points, ensuring your car is always ready for the road.`,
    `<strong><span style="color:#21B07D;">Insurance Services:</span></strong> Compare various car insurance quotes to find the best rates and coverage options tailored to your needs. Our seamless integration with top insurance providers ensures your vehicle is well-protected.`,
    `<strong><span style="color:#21B07D;">Motor Dealer Services:</span></strong> Revolutionise your car-buying experience by connecting with trusted motor dealers. Explore a wide range of vehicles, participate in motor auctions, and find hidden treasures among salvage vehicles.`,
    `<strong><span style="color:#21B07D;">Fleet Management:</span></strong> Optimise your fleet operations with tailored solutions. Track vehicles, manage maintenance schedules, and streamline your fleet for maximum efficiency.`,
    `<strong><span style="color:#21B07D;">Legal Assistance:</span></strong> Access a network of experienced lawyers for all your vehicle-related legal matters. From traffic violations to insurance claims, our legal experts are here to protect your rights and interests.`
  ];
  
export const aboutUsBottomSectionMockData =[
    {
        id:"1",
        img:setsUsApartImg,
        content:"At Travel Help Squad, We believe your travel success is our priority. That’s why we are dedicated to providing you with the best possible support and guidance. We offer a variety of resources, including training webinars, online documentation, and one-on-one support from our team of experts. "
    },
    {
        id:"2",
        content:"We are also constantly innovating and adding new features to Travel Help Squad to keep you ahead of the curve. Our commitment is to provide you with the most advanced travel and vehicle management solutions available.",
        img:featuresImg,
    },
]