export const faqsMockData =[
    {
        id:"1",
        title:"How do I manage my PCNs with Travel Help Squad?",
        description:"Travel Help Squad provides an intuitive platform to manage your Penalty Charge Notices (PCNs). You can track payments, file appeals, and monitor deadlines all in one place. "
    },
    {
        id:"2",
        title:"Where can I find available parking spots?",
        description:"Use our parking services to discover and reserve available parking spots. Our platform offers real-time availability and reservation options for both street and lot parking. "
    },
    {
        id:"3",
        title:"How do I register my charging station? ",
        description:"To register your charging station, sign up on our platform, provide the necessary details about your station, set your rates, and once approved, your station will be listed for EV users to find and use. "
    },
    {
        id:"4",
        title:"What insurance providers do you work with? ",
        description:"We integrate with top insurance providers to offer you competitive car insurance quotes. Compare various options on our platform to find the best coverage for your needs. "
    },
    {
        id:"5",
        title:"How can I contact motor dealers through your platform? ",
        description:"Travel Help Squad connects you with trusted motor dealers. Browse our listings to explore available vehicles and connect directly with dealers for purchasing or more information. "
    },
    {
        id:"6",
        title:"What should I do if I need legal assistance for vehicle-related issues?",
        description:"Our platform offers access to a network of experienced lawyers who can assist with traffic violations, insurance claims, and other vehicle-related legal matters. "
    },
    {
        id:"7",
        title:"What are the benefits of joining the Travel Help Squad network?",
        description:"By joining Travel Help Squad, you gain access to a comprehensive range of services designed to make travel and vehicle management effortless. This includes PCN management, parking services, insurance options, and more. "
    },
    {
        id:"8",
        title:" How does the Free 30-Day Trial work?",
        description:"Our Free 30-Day Trial provides full access to all functionalities within each module, allowing up to 2 users per module. Enjoy features such as PCN management, parking services, and more without the need for a credit card to sign up. "
    },
    {
        id:"9",
        title:"What additional features are available with the Enterprise Plan?",
        description:"The Enterprise Plan includes advanced features such as priority customer support, advanced reporting and analytics, integration with third-party tools, and customisable user roles and permissions. Contact our sales team for a tailored quote. "
    },
    {
        id:"10",
        title:"How can I contact Travel Help Squad for support or more information? ",
        description:"You can reach us via email at Info@travelhelpsquad.co.uk. Our team is ready to assist you with any questions or support needs."
    },
]