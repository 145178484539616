import React from 'react'
import LawyerHero from './lawyer-hero/lawyer-hero'
import LawyerCard from './lawyer-hero/lawyer-card/lawyer-card'

const Lawyers = () => {
  return (
    <>
      <LawyerHero/>
      <LawyerCard/>
    </>
  )
}

export default Lawyers
