import React from 'react'
import DriverHero from './driver-hero/driver-hero'
import DriverCards from './driver-cards/driver-cards'

const DriverKit = () => {
  return (
    <>
   <DriverHero/>
   <DriverCards/>
   </>
  )
}

export default DriverKit