import React from "react";
import { Box, Grid, Typography, Button, InputAdornment, IconButton } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useForm, FormProvider } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { CustomTextField } from "../../../../../hook-form";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useState } from "react";


const ukPhoneNumberRegex =  /^\+44\d{10}$/;

const validationSchema = Yup.object().shape({
  firstName: Yup.string().trim().required("Field is required"),
  lastName: Yup.string().trim().required("Field is required"),
  email: Yup.string().email().trim().required("Field is required"),
  contactNumber: Yup.string().matches(ukPhoneNumberRegex, "Phone number should be in UK format").required("Field is required"),
  password: Yup.string().required("Field is required"),
});

const defaultValues = {
  firstName: "",
  lastName: "",
  email: "",
  contactNumber: "",
  password: "",
  companyShortCode:"THS",
};
const PersonalAccount = () => {
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });
  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };
  const passwordEndAdornment = {
    endAdornment: (
      <InputAdornment position="end">
        <IconButton onClick={handleShowPassword} edge="end">
          {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
        </IconButton>
      </InputAdornment>
    ),
  };
  const { handleSubmit } = methods;

  const onSubmit = async (formValues) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_KEY}auth/signup`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formValues),
        }
      );

      const responseData = await response.json();

      if (responseData?.data !== null) {
        toast.success(
          responseData?.message ?? `Sign Up Successfully!. Check Your Email.`
        );
        navigate(`/verification/${responseData?.data?.userId}`);
        methods.reset();
      } else {
        toast.error(responseData.message || responseData.errors[0]);
      }
    } catch (error) {
      const errMsg = error?.message;
      toast.error(errMsg);
    }
  };

  return (
    <Box
      sx={{
        p: { md: "30px", sm: "30px", xs: "20px" },
      }}
    >
      <Box mb={3} gap={2} mt={0}>
        <Typography textAlign="center" sx={styles.heading}>
          Personal Account
        </Typography>
      </Box>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2} mt={5}>
              <Grid item xl={12} xs={12}>
                <Typography sx={styles.formHeading} mb={2}>
                  Personal Information
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  name="firstName"
                  label="First Name"
                  placeholder="Enter your first name"
                  fullWidth
                  disabled={false}
                  multiline={false}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  name="lastName"
                  label="Last Name"
                  placeholder="Enter your last name"
                  fullWidth
                  disabled={false}
                  multiline={false}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  name="email"
                  label="Email"
                  placeholder="Enter your email"
                  fullWidth
                  disabled={false}
                  multiline={false}
                />
              </Grid>
              
              <Grid item xs={12}>
              <CustomTextField
                    name="password"
                    label="Password"
                    placeholder="Enter password"
                    type={showPassword ? "text" : "password"}
                    InputProps={passwordEndAdornment}
                    fullWidth
                    disabled={false}
                    multiline={false}
                  />
              </Grid>

              <Grid item xs={12} xl={12} mb={1}>
                <CustomTextField
                  fullWidth
                  name="contactNumber"
                  label="Phone Number"
                  placeholder="Enter your phone number"
                  disabled={false}
                  multiline={false}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        style={{ color: "#4B5563" }}
                      >
                        UK
                        <KeyboardArrowDownIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid
                mt={2}
                item
                xl={12}
                xs={12}
                display={"flex"}
                justifyContent={"center"}
              >
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    background: "#21B07D ",
                    padding: "11px 18px",
                    borderRadius: "8px",
                    textTransform: "capitalize",
                    color: "#FFF",
                    textAlign: "center",
                    fontFamily: "Gilroy-SemiBold",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "normal",
                    width: "100%",
                    height: "49px",
                    letterSpacing: "0.8px",
                    "&:hover": {
                      background: "#21B07D",
                    },
                  }}
                >
                  Sign Up
                </Button>
              </Grid>
            </Grid>
          </Box>
        </form>
      </FormProvider>
    </Box>
  );
};

export default PersonalAccount;

const styles = {
  heading: {
    fontSize: "36px",
    fontWeight: "600",
    color: "#1D2939",
    textAlign: "center",
    fontFamily: "Gilroy-Bold",
    letterSpacing: "1px",
  },
  formHeading: {
    fontSize: "22px",
    fontWeight: "500",
    color: "#1D2939",
    fontFamily: "Gilroy-Bold",
  },
};
