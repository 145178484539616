import BlogsSection from "../Mobilefitting/blog";
import PicsBlog from "../Mobilefitting/Picsblogsection";


function HomeBlogs() {
  return (
    <div>
      <BlogsSection />
      <PicsBlog/>
   
    </div>
  );
}

export default HomeBlogs;
