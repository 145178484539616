import { Box } from '@mui/material'
import React from 'react'
import FaqsHero from './faqs-hero/faqs-hero'
import FaqsContent from './faqs-content/faqs-content'

const Faqs = () => {
  return (
    <Box>
      <FaqsHero/>
      <FaqsContent/>
    </Box>
  )
}

export default Faqs