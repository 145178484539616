import {
  Container,
  Grid,
  Typography,
  CardContent,
  CardMedia,
  Box,
  MenuItem,
  Select,
  FormControl,
  TextField,
  InputAdornment,
  CardHeader,
  Button,
} from "@mui/material";
import * as React from "react";
import { styled } from "@mui/material/styles";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import { red } from "@mui/material/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Card from "@mui/material/Card";
import SalvageDealersImage from "../../../../../assets/salvagedealers.png";

const SalvageDealersCard = () => {
  const data = [
    {
      image: SalvageDealersImage,
      content: (
        <div>
          <ul style={{ paddingTop: "8px", listStyleType: "none" }}>
            Rebuild Your Ride with Travel Help Squad's Salvage Dealers Feature
            <li style={{ paddingTop: "8px" }}>
              <span
                style={{
                  color: "#5C5F5E",
                  fontFamily: "Gilroy-Bold",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 400,
                }}
              >
                Discover Salvage Gems:{" "}
              </span>{" "}
              Explore a curated selection of salvage vehicles, perfect for
              restoration projects and DIY enthusiasts.
            </li>
            <li style={{ paddingTop: "8px" }}>
              <span
                style={{
                  color: "#5C5F5E",
                  fontFamily: "Gilroy-Bold",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 400,
                }}
              >
                Affordable Options:{" "}
              </span>{" "}
              Find budget-friendly salvage cars and parts that can save you a
              fortune on vehicle repairs and customisation.​
            </li>
            <li style={{ paddingTop: "8px" }}>
              <span
                style={{
                  color: "#5C5F5E",
                  fontFamily: "Gilroy-Bold",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 400,
                }}
              >
                ​Detailed Listings:{" "}
              </span>
              Get all the essential information, from damage reports to vehicle
              history, to make informed buying decisions.
            </li>
            <li style={{ paddingTop: "8px" }}>
              <span
                style={{
                  color: "#5C5F5E",
                  fontFamily: "Gilroy-Bold",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 400,
                }}
              >
                ​Your Restoration Hub:{" "}
              </span>{" "}
              Salvage Dealers on Travel Help Squad is the go-to destination for
              reviving your dream ride.
            </li>
            Uncover hidden treasures and start your restoration journey today.
            Register now with Travel Help Squad and dive into the world of
            salvage vehicles!​​
          </ul>{" "}
        </div>
      ),
    },
  ];

  return (
    <>
    <Container maxWidth="xl">
      <Grid
        container
        sx={{
          p: { sm: "15px", xs: "0px" },
          borderRadius: "20px",
          background: "#EDF3F1",
          boxShadow: "0px 34px 74px -12px rgba(0, 0, 0, 0.16)",
          position: "relative",
          zIndex: 10,
          mt: -10,
        }}
      >
        <Grid item xs={12} lg={12}>
          {data.map((item, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                flexDirection: "column", 
                alignItems: "left", 
                p: { sm: 0, xs: 2 },
                width: "100%",
              }}
            >
              <img src={item.image} alt={`Image ${index}`} width="100%" />
              <Typography
                sx={{
                  fontFamily: "Gilroy-Medium",
                  fontSize: "16px",
                  fontWeight: 400,
                  lineHeight: "30px",
                  textAlign: "left", 
                  color: "#5C5F5E",
                  marginTop: "10px", 
                }}
              >
                {item.content}
              </Typography>
            </Box>
          ))}
        </Grid>
      </Grid>
    </Container>
  </>
  
  );
};

export default SalvageDealersCard;
