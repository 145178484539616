import React from "react";
import { Box,  Divider, Grid, Link, Stack, Typography } from "@mui/material";
import Logoone from "../../../assets/logoone.png";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router-dom";
import LayoutWrapper from "../layout-wrapper";
import LinkedIn from '../../../assets/icons/linkedinIcon.svg'
import XIcon from '../../../assets/icons//twitterIcon.svg'
import Facebook from '../../../assets/icons/facebookIcon.svg'
import Instagram from '../../../assets/icons/instaIcon.svg'


const footerStyles = {
  // marginTop: "2rem",
  backgroundColor: "EDF3F1",
  color: "#fff",
  // padding: "2rem 0",
  marginTop: "212px",
  bottom: 0,
};

// const columnStyles = {
//   // display: "flex",
//   // flexDirection: "column",
//   alignItems: "flex-start",
//   //   marginRight: '2rem',
// };

const Footer = () => {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <footer style={footerStyles}>
      <LayoutWrapper background='#EDF3F1'>
        <Grid container   spacing={3}>
          <Grid
            item
            lg={5}
            md={6}
            xs={12}
            sx={{ display: "flex", flexDirection: "column", mt: "18px" }}
          
          >
            <Box sx={{ mb: "16px" }}>
     
              <img src={Logoone} alt="Logo" />
            </Box>
            <Box sx={{ mb: "40px" }}>
              <Typography
                variant="body"
                sx={{
                  color: "#4D4D4D",
                  fontFamily: "Gilroy-Medium",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "26px",
                  whiteSpace: "pre-wrap",
                }}
              >
               A digital solution for seamless vehicle
                <br />management through one single platform
              </Typography>

              <br />

            </Box>
          </Grid>
          <Grid
            item
            lg={2.3}
            md={6}
            xs={12}
            sx={{ display: "flex", flexDirection: "column", mt: "18px" }}
          >
            <Typography
              variant="h6"
              sx={{
                color: "#5C5F5E",
                fontSize: "14px",
                fontFamily: "Gilroy-Medium",
                fontWeight: 700,
                textDecoration: "none",
                cursor: "pointer",
                mt: 2,
              }}
            >
              Travel Help Squad
            </Typography>
            <Link
              onClick={() => handleNavigation("/offer")}
              variant="h6"
              sx={{
                color: "#5C5F5E",
                fontSize: "14px",
                fontFamily: "Gilroy-Medium",
                fontWeight: 400,
                textDecoration: "none",
                cursor: "pointer",
                mt: "18px",
                position: "relative",
                "&:hover::before": {
                  content: '""',
                  position: "absolute",
                  width: "10%",
                  borderBottom: "2px solid #334C8C",
                  bottom: "-2px",
                },
              }}
            >
              Our Offering
            </Link>
            <Link
              variant="h6"
              onClick={() => handleNavigation("/pricing")}
              sx={{
                color: "#5C5F5E",
                fontSize: "14px",
                fontFamily: "Gilroy-Medium",
                fontWeight: 400,
                textDecoration: "none",
                cursor: "pointer",
                mt: "18px",
                position: "relative",
                "&:hover::before": {
                  content: '""',
                  position: "absolute",
                  width: "10%",
                  borderBottom: "2px solid #334C8C",
                  bottom: "-2px",
                },
              }}
            >
              Pricing
            </Link>
            <Link
              variant="h6"
              onClick={() => handleNavigation("/career")}
              sx={{
                color: "#5C5F5E",
                fontSize: "14px",
                fontFamily: "Gilroy-Medium",
                fontWeight: 400,
                textDecoration: "none",
                cursor: "pointer",
                mt: "18px",
                position: "relative",
                "&:hover::before": {
                  content: '""',
                  position: "absolute",
                  width: "10%",
                  borderBottom: "2px solid #334C8C",
                  bottom: "-2px",
                },
              }}
            >
             Career
            </Link>
          </Grid>

          <Grid
            item
            lg={2.3}
            md={6}
            xs={12}
            sx={{ display: "flex", flexDirection: "column", mt: "18px" }}
          >
            <Typography
              variant="h6"
              color="inherit"
              underline="none"
              sx={{
                color: "#5C5F5E",
                fontSize: "14px",
                fontFamily: "Gilroy-Medium",
                fontWeight: 700,
                textDecoration: "none",
                cursor: "pointer",
                mt: "18px",
              }}
            >
              Get In Touch
            </Typography>
            <Link
              onClick={() => handleNavigation("/get-in-touch/contact-us")}
              variant="h6"
              color="inherit"
              underline="none"
              sx={{
                color: "#5C5F5E",
                fontSize: "14px",
                fontFamily: "Gilroy-Medium",
                fontWeight: 400,
                textDecoration: "none",
                cursor: "pointer",
                mt: "18px",
                position: "relative",
                "&:hover::before": {
                  content: '""',
                  position: "absolute",
                  width: "10%",
                  borderBottom: "2px solid #334C8C",
                  bottom: "-2px",
                },
              }}
            >
             Contact Us
            </Link>
            <Link
              variant="h6"
              onClick={() => handleNavigation("/get-in-touch/FAQs")}
              color="inherit"
              underline="none"
              sx={{
                color: "#5C5F5E",
                fontSize: "14px",
                fontFamily: "Gilroy-Medium",
                fontWeight: 400,
                textDecoration: "none",
                cursor: "pointer",
                mt: "18px",
                position: "relative",
                "&:hover::before": {
                  content: '""',
                  position: "absolute",
                  width: "10%",
                  borderBottom: "2px solid #334C8C",
                  bottom: "-2px",
                },
              }}
            >
             FAQs
            </Link>
          
          </Grid>
          <Grid
            item
            lg={2.3}
            md={6}
            xs={12}
            sx={{ display: "flex", flexDirection: "column", mt: "18px" }}
          >
           
            <Link
              href="/terms-and-condition"
              variant="h6"
              color="inherit"
              underline="none"
              sx={{
                color: "#5C5F5E",
                fontSize: "14px",
                fontFamily: "Gilroy-Medium",
                fontWeight: 400,
                textDecoration: "none",
                cursor: "pointer",
                mt: "18px",
                position: "relative",
                "&:hover::before": {
                  content: '""',
                  position: "absolute",
                  width: "10%",
                  borderBottom: "2px solid #334C8C",
                  bottom: "-2px",
                },
              }}
            >
           Terms & Condition
            </Link>{" "}
            <Link
              onClick={() => handleNavigation("/privacy-policy")}
              variant="h6"
              color="inherit"
              underline="none"
              sx={{
                color: "#5C5F5E",
                fontSize: "14px",
                fontFamily: "Gilroy-Medium",
                fontWeight: 400,
                textDecoration: "none",
                cursor: "pointer",
                mt: "18px",
                position: "relative",
                "&:hover::before": {
                  content: '""',
                  position: "absolute",
                  width: "10%",
                  borderBottom: "2px solid #334C8C",
                  bottom: "-2px",
                },
              }}
            >
             Privacy Policy
            </Link>
            <Link
              onClick={() => handleNavigation("/cookies-policy")}
              variant="h6"
              color="inherit"
              underline="none"
              sx={{
                color: "#5C5F5E",
                fontSize: "14px",
                fontFamily: "Gilroy-Medium",
                fontWeight: 400,
                textDecoration: "none",
                cursor: "pointer",
                mt: "18px",
                position: "relative",
                "&:hover::before": {
                  content: '""',
                  position: "absolute",
                  width: "10%",
                  borderBottom: "2px solid #334C8C",
                  bottom: "-2px",
                },
              }}
            >
            Cookies
            </Link>
          </Grid>
        </Grid>

        <Divider sx={{ mt: "30px", mb: "30px" }} />
        <Grid container  display='flex' alignItems="center" justifyContent='space-between' >
          <Grid item xs={12} xl={6} mb={3}>
            <Link
              variant="body"
              sx={{
                color: "#5C5F5E",
                fontFamily: "Gilroy-Medium",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "20px",
                display: "flex",
                flexDirection: "column",
                alignItems: { lg: "flex-start", xs: "center" },
                textDecoration: "none",
                // gap: "30px",
                // marginLeft: "11%",
              }}
            >
              {new Date().getFullYear()}, Travel Help Squad - Powered by Pay io services
            </Link>
          </Grid>
          <Grid
            item
            xs={12}
            xl={6}
            sx={{ display: "flex", justifyContent: "flex-end" }}
            mb={3}
          >
            <Stack spacing={3} direction="row">
            <Link  to="/https://www.linkedin.com/company/travel-help-squad">   
            <IconButton sx={{width:"30px" ,height:"30px", borderRadius:"50% !important"}}>
              <img src={LinkedIn} alt="LinkedIn" />
            </IconButton>
            </Link>
            <Link  to="/https://twitter.com/Travelhelpsquad">   
            <IconButton sx={{width:"30px" ,height:"30px", borderRadius:"50%"}}>
            <img src={XIcon} alt="XIcon" />
            </IconButton>
            </Link>
            <Link  to="/https://www.facebook.com/travelhelpsquadofficial/">   
            <IconButton sx={{width:"30px" ,height:"30px", borderRadius:"50%"}}>
            <img src={Facebook} alt="Facebook" />
            </IconButton>
            </Link>
            <Link  to="/https://www.instagram.com/travel_help_squad/">   
            <IconButton sx={{ width:"30px" ,height:"30px", borderRadius:"50%"}}>
            <img src={Instagram} alt="Instagram" />
            </IconButton>
            </Link>
            </Stack>
          </Grid>
        </Grid>
      </LayoutWrapper>
    </footer>
  );
};

export default Footer;
